import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './AllReservations.css';
import ProductModal from '../../../components/Common/ProductModal/ProductModal';
import Select from 'react-select';
import { fetchShow } from '../../../actions/appActions';
import { getReservations } from '../../../services/reservations/reservationsService';
import { CircleSpinner } from 'react-spinners-kit';
import Button from '../../../components/Common/Button/Button';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../../components/icons/SearchIcon';
import FilterIcon from '../../../components/icons/FilterIcon';
import { format } from 'date-fns';
import { DateRange } from 'react-date-range';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';

import CalendarFilterIcon from '../../../components/icons/CalendarFilterIcon';
import ActivityReservationItem from '../../../components/Activities/ActivityReservationItem.js/ActivityReservationItem';
import { calendarDate, getDateFromOffset, getLocale } from '../../../helpers/dates';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange, setOrderOption } from '../../../actions/reservationActions';
import { WelletContext } from '../../../context/WelletContext';

const AllReservations = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [cities, setCities] = useState(null);
  const [restaurants, setRestaurants] = useState(null);
  const [reservations, setReservations] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(15);
  const [searchValue, setSearchValue] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [amountFrom, setAmountFrom] = useState(null);
  const [amountTo, setAmountTo] = useState(null);
  const user = useSelector((state) => state.associates.user);
  const app = useSelector((state) => state.app);
  const selectedOrderOption = useSelector((state) => state.reservation.orderOption);
  const savedDates = useSelector((state) => state.reservation.dateRange[0]);
  const locale = getLocale(app.language);
  const dispatch = useDispatch();
  const lastIndex = reservations ? reservations.length - 1 : -1;
  const savedFilter = localStorage.getItem('filterDate');

  const requestBody = (more = false) => {
    const sortBy = selectedFilters?.orderOption?.value
      ? selectedFilters?.orderOption?.value
      : selectedOrderOption?.value
      ? selectedOrderOption?.value
      : savedFilter === 'RECENT_COMMISSIONS'
      ? 'RESERVATION_DATE_DESC'
      : savedFilter === 'CREATED_DATE'
      ? 'CREATED_DATE_DESC'
      : `${savedFilter}_ASC`;

    const fromDate = savedDates.startDate
      ? format(savedDates.startDate, 'yyyy-MM-dd')
      : format(initialDates[0].startDate, 'yyyy-MM-dd');
    const toDate = savedDates.endDate
      ? format(savedDates.endDate, 'yyyy-MM-dd')
      : format(initialDates[0].endDate, 'yyyy-MM-dd');

    return {
      cityId: selectedFilters?.ciudad?.value ? [selectedFilters?.ciudad?.value] : null,
      CampaignType: null,
      sortBy,
      FilterDate: 'ORDER_DATE',
      status: getStatusFromCheckboxes(selectedCheckboxes),
      amountFrom: amountFrom,
      amountTo: amountTo,
      CampaignId: null,
      MktEntityId: selectedFilters?.restaurante?.value
        ? [selectedFilters?.restaurante?.value]
        : null,
      from: fromDate,
      to: toDate,
      limit: 15,
      skip: more ? skip : 0,
      filterField: searchValue,
    };
  };

  const getInitialDates = () => {
    const today = new Date(getDateFromOffset(user.companyUtcMinutesOffset));
    today.setHours(0, 0, 0, 0);
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    return [
      {
        startDate: savedFilter === 'ORDER_DATE' ? today : startDate,
        endDate: today,
        key: 'selection',
      },
    ];
  };

  const [initialDates, setInitialDates] = useState(getInitialDates());

  useEffect(() => {
    if (!savedDates.startDate) dispatch(setDateRange(initialDates));
  }, []);

  const getCities = async () => {
    try {
      const response = await welletContext.apis.tickets.get('/mktcampaign/cities');
      setCities(response.data);
    } catch (err) {
      console.error(err);
      setError(t('callApiError'));
    }
  };

  const getRestaurants = async (selectedCity, page = 1, pageSize = 100) => {
    try {
      const result = await fetchShow('es', selectedCity, page, pageSize, welletContext);

      setRestaurants(
        result.shows.map((restaurant) => ({
          value: restaurant.id,
          label: restaurant.name,
        })),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fetchReservations = async (more = false) => {
    if (!hasMore && more) {
      return;
    }
    if (more) setLoadingMore(true);
    else setLoading(true);

    const body = requestBody(more);

    try {
      const reservationsData = await getReservations(welletContext, body);
      if (more) {
        if (
          reservationsData.length === 0 ||
          JSON.stringify(reservations.slice(-15)) === JSON.stringify(reservationsData)
        ) {
          setHasMore(false);
        } else {
          setReservations((prevReservations) => [...prevReservations, ...reservationsData]);
          setSkip((prevSkip) => prevSkip + reservationsData.length);
          setHasMore(reservationsData.length === 15);
        }
      } else {
        setReservations(reservationsData);
      }
    } catch (error) {
      console.error(error);
      setError(t('callApiError'));
    } finally {
      if (more) setLoadingMore(false);
      else setLoading(false);
    }
  };

  const fetchMoreReservations = async () => {
    if (!hasMore || loadingMore) return;
    setLoadingMore(true);

    const body = requestBody(true);

    try {
      const reservationsData = await getReservations(welletContext, body);
      if (reservationsData.length === 0) {
        setHasMore(false);
      } else {
        setReservations((prevReservations) => [...prevReservations, ...reservationsData]);
        setSkip((prevSkip) => prevSkip + reservationsData.length);
      }
    } catch (error) {
      console.error(error);
      setError(t('callApiError'));
    } finally {
      setLoadingMore(false);
    }
  };

  const getStatusFromCheckboxes = (checkboxes) => {
    const statusFilters = [];
    const checkboxesArray = Array.isArray(checkboxes) ? checkboxes : Object.values(checkboxes);
    checkboxesArray.forEach((isChecked, index) => {
      if (isChecked) {
        switch (index) {
          case 0:
            statusFilters.push('APPROVED');
            break;
          case 1:
            statusFilters.push('RESERVED');
            break;
          case 2:
            statusFilters.push('CANCELLED');
            break;
          case 3:
            statusFilters.push('PENDING');
            break;
          case 4:
            statusFilters.push('PENDING_EXTERNAL');
            break;
          case 5:
            statusFilters.push('DENIED');
            break;
          case 6:
            statusFilters.push('EXPIRED');
            break;
          case 7:
            statusFilters.push('ERROR');
            break;
          case 8:
            statusFilters.push('REFUNDED');
            break;
          case 9:
            statusFilters.push('CONFIRMED');
            break;
          case 10:
            statusFilters.push('NO_SHOW');
            break;
          case 11:
            statusFilters.push('WAITING_DEPOSIT');
            break;
          case 12:
            statusFilters.push('PENDING_REVIEW');
            break;
          case 13:
            statusFilters.push('PAYMENT_PENDING');
            break;
          case 14:
            statusFilters.push('COMMISSION_REJECTED');
            break;
          case 15:
            statusFilters.push('PAYMENT_ACCREDITED');
            break;
          default:
            break;
        }
      }
    });
    return statusFilters;
  };

  const checkboxOptions = [
    {
      label: t('options.finished'),
      checked: true,
      value: 'CLOSED_WITHOUT_COMMISSION',
    },
    {
      label: t('options.approved'),
      checked: true,
      value: 'APPROVED',
    },
    {
      label: t('options.pendingApprove'),
      checked: true,
      value: 'PENDING_REVIEW',
    },
    {
      label: t('options.toPay'),
      checked: true,
      value: 'PAYMENT_PENDING',
    },
    {
      label: t('options.paids'),
      checked: true,
      value: 'PAYMENT_ACCREDITED',
    },
    {
      label: t('options.anulled'),
      checked: true,
      value: 'COMMISSION_REJECTED',
    },
  ];

  const orderOptions = [
    { label: t('orderTypes.CREATED_DATE_ASC'), value: 'CREATED_DATE_ASC' },
    { label: t('orderTypes.CREATED_DATE_DESC'), value: 'CREATED_DATE_DESC' },
  ];

  const getInitialCheckboxState = () => {
    if (savedFilter === 'RECENT_COMMISSIONS') {
      return checkboxOptions.map((_, index) => index >= 6);
    }

    return checkboxOptions.map((checkbox) => checkbox.checked);
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(getInitialCheckboxState());

  const debounce = function (func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const delayedSearch = useRef(
    debounce((query) => {
      setSearchValue(query);
    }, 500),
  ).current;

  useEffect(() => {
    if (!cities) {
      getCities();
    }
  }, []);

  useEffect(() => {
    fetchReservations();
  }, [searchValue]);

  useEffect(() => {
    getRestaurants(selectedFilters?.ciudad?.value);
  }, [selectedFilters?.ciudad?.value]);

  const handleCityChange = (selectedOption) => {
    setSelectedFilters({
      ...selectedFilters,
      ciudad: selectedOption,
      restaurante: { value: '', label: t('all') },
    });

    if (selectedOption) {
      getRestaurants(selectedOption.value);
    } else {
      setRestaurants(null);
    }
  };

  const handleRestaurantChange = (selectedOption) => {
    setSelectedFilters({ ...selectedFilters, restaurante: selectedOption });
  };

  const handleOrderSelection = (selectedOption) => {
    setSelectedFilters({ ...selectedFilters, orderOption: selectedOption });
    dispatch(setOrderOption(selectedOption));
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...selectedCheckboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setSelectedCheckboxes(updatedCheckboxes);
  };

  const handleAmountToChange = (e) => {
    const valueset = Math.max(0, e.target.value);
    setAmountTo(valueset);
  };
  const handleAmountFromChange = (e) => {
    const valueset = Math.max(0, e.target.value);
    setAmountFrom(valueset);
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    if (value.length >= 3) {
      delayedSearch(value);
    } else {
      delayedSearch('');
    }
  };

  const handleResetFilters = () => {
    setSelectedFilters(null);
    setAmountFrom(null);
    setAmountTo(null);
    setSelectedCheckboxes(checkboxOptions.map((checkbox) => checkbox.checked));
  };

  const handleOkButtonClick = () => {
    setShowModal(false);
    setHasMore(true);
    fetchReservations();
  };

  const handleApplyDateRange = async () => {
    dispatch(setDateRange(initialDates));
    setSkip(0);
    setHasMore(true);
    setShowCalendar(false);
    setLoading(true);

    const body = requestBody(false);

    try {
      const reservationsData = await getReservations(welletContext, body);
      setReservations(reservationsData);
      setHasMore(reservationsData.length === 15);
      setSkip(reservationsData.length);
    } catch (error) {
      console.error(error);
      setError(t('callApiError'));
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      const reachedBottom =
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.offsetHeight;
      if (reachedBottom && hasMore && !loading && !loadingMore) {
        fetchMoreReservations();
      }
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loading, loadingMore, hasMore, skip]);

  const calendarButton = () => {
    const startDate = savedDates?.startDate;
    const endDate = savedDates?.endDate;

    if (startDate && endDate) {
      const formattedStartDate = calendarDate(savedDates.startDate, locale);
      const formattedEndDate = calendarDate(endDate, locale);

      return (
        <div className='position-relative text-secondary row align-items-center flex-nowrap font-small calendar-button '>
          <div className='col-auto pr-0'>
            <CalendarFilterIcon size={20} />
          </div>
          <div className='col pl-2 text-truncate fw-semibold'>
            {requestBody().sortBy.includes('RESERVATION')
              ? t('reservationsDate')
              : t('createdDate')}{' '}
            - {formattedStartDate} - {formattedEndDate}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const searchButton = () => {
    return (
      <div className='card flex align-items-center flex-row p-2 filter-buttons'>
        <div className='mr-2'>
          <SearchIcon size='20' fillColor='#777777' />
        </div>
        <input
          type='text'
          placeholder={t('search')}
          className='input-search fw-semibold'
          onChange={(e) => handleSearchInputChange(e)}
        />
      </div>
    );
  };

  const filterButton = () => {
    return (
      <div
        onClick={() => setShowModal(true)}
        className='card flex align-items-center justify-content-center flex-row p-2 filter-buttons '
      >
        <div style={{ marginRight: '8px' }}>
          <FilterIcon size='20' />
        </div>
        <div className='text-secondary font-small fw-semibold'>{t('filter')}</div>
      </div>
    );
  };

  return (
    <>
      <ProductModal
        open={showModal}
        fullScreen
        hasBackground
        showCloseButton={false}
        animationClass='slide-left-right'
      >
        <div style={{ backgroundColor: '#000' }}>
          <Header title={t('filters')} onClick={() => setShowModal(false)} />
          <div
            className='text-white container'
            style={{
              paddingBottom: 'calc(83px + env(safe-area-inset-bottom))',
            }}
          >
            <div className='my-3 bold'>{t('city')}</div>
            <Select
              className='filter-select hide-indicator-separator'
              classNamePrefix='filter-select'
              options={[
                { value: '', label: t('all') },
                ...(cities && Array.isArray(cities)
                  ? cities.map((city) => ({
                      value: city.id,
                      label: city.name,
                    }))
                  : []),
              ]}
              onChange={handleCityChange}
              value={selectedFilters?.ciudad || { value: '', label: t('all') }}
              placeholder={t('all')}
              isSearchable={false}
            />

            <div className='my-3 bold'>{t('accounts')}</div>
            <Select
              className='filter-select hide-indicator-separator'
              classNamePrefix='filter-select'
              options={[
                { value: '', label: t('all') },
                ...(restaurants && Array.isArray(restaurants) ? restaurants : []),
              ]}
              onChange={handleRestaurantChange}
              value={selectedFilters?.restaurante || { value: '', label: t('all') }}
              placeholder={t('all')}
              isSearchable={false}
            />

            <div className='my-4 bold'>{t('options.orders')}</div>
            {checkboxOptions.slice(0, 2).map((checkbox, index) => (
              <div className='row mt-3' key={index + 3}>
                <label className='filter-checkbox-container ml-3'>
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px' }}
                    checked={selectedCheckboxes[index + 3]}
                    onChange={() => handleCheckboxChange(index + 3)}
                  />
                  <span className='filter-checkmark' style={{ marginLeft: '' }}></span>
                </label>
                <div className='col ml-3' onClick={() => handleCheckboxChange(index + 3)}>
                  {checkbox.label}
                </div>
              </div>
            ))}
            <div className='my-4 bold'>{t('options.commissions')}</div>

            {checkboxOptions.slice(2, 6).map((checkbox, index) => (
              <div className='row mt-3' key={index + 8}>
                <label className='filter-checkbox-container ml-3'>
                  <input
                    type='checkbox'
                    style={{ width: '20px', height: '20px' }}
                    checked={selectedCheckboxes[index + 8]}
                    onChange={() => handleCheckboxChange(index + 8)}
                  />
                  <span className='filter-checkmark' style={{ marginLeft: '' }}></span>
                </label>
                <div className='col ml-3' onClick={() => handleCheckboxChange(index + 8)}>
                  {checkbox.label}
                </div>
              </div>
            ))}
            <div className='bold mt-5'>{t('commissionAmount')}</div>
            <div className='row mt-3 input-from-to'>
              <div className='col '>
                {t('from')}
                <div className='row position-relative align-items-center px-3'>
                  <span>$</span>
                  <input
                    id='amountFrom'
                    type='number'
                    value={amountFrom || ''}
                    onChange={handleAmountFromChange}
                    style={{
                      paddingLeft: '24px',
                      textAlign: 'right',
                      width: '100%',
                    }}
                  />
                </div>
              </div>
              <div className='col'>
                {t('to')}
                <div className='row position-relative align-items-center px-3'>
                  <span>$</span>
                  <input
                    id='amountTo'
                    type='number'
                    value={amountTo || ''}
                    onChange={handleAmountToChange}
                    style={{
                      paddingLeft: '24px',
                      textAlign: 'right',
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='bold mt-5'>{t('orderBy')}</div>
            <Select
              className='filter-select hide-indicator-separator mt-3'
              classNamePrefix='filter-select'
              options={orderOptions}
              placeholder={t(`orderTypes.${requestBody().sortBy}`)}
              onChange={handleOrderSelection}
              isSearchable={false}
            />
            <div className='d-flex mt-5 justify-content-between align-items-center mb-2 all-reservations-buttons'>
              <u className='text-secondary font-small pl-0' onClick={handleResetFilters}>
                {t('resetAllFilters')}
              </u>
              <Button onClick={handleOkButtonClick} text='OK' size='small' />
            </div>
          </div>
        </div>
      </ProductModal>
      <Header pathName='/' title={t('reservationRequests')} hasBackground={true} showBack={true} />

      <ContentApp>
        <div
          className='text-truncate mb-2'
          style={{ borderRadius: '.5rem' }}
          onClick={() => setShowCalendar(true)}
        >
          {calendarButton()}
        </div>
        <div className='row'>
          <ProductModal
            open={showCalendar}
            animationClass='fade-up-horizontal'
            showCloseButton={false}
            customStyle={{ position: 'absolute', top: '15%' }}
          >
            <DateRange
              onChange={(item) => setInitialDates([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={initialDates}
              locale={locale}
              months={1}
              direction='horizontal'
              preventSnapRefocus={true}
              showSelectionPreview={true}
              showDateDisplay={false}
              formattedDate={`${initialDates[0]?.startDate.toLocaleDateString()} - ${initialDates[0].endDate.toLocaleDateString()}`}
            />
            <div
              className='d-flex justify-content-end mb-2 mr-3'
              style={{ backgroundColor: '#fff' }}
            >
              <Button
                size={'small'}
                backgroundColor='var(--color-light-gray)'
                color='#000'
                text={<div className='light text-white'>{t('cancel')}</div>}
                className='mr-3'
                onClick={() => setShowCalendar(false)}
              />
              <Button
                onClick={handleApplyDateRange}
                size={'small'}
                className='btn-secondary light text-white'
                text={t('apply')}
              />
            </div>
          </ProductModal>

          <div className='col-9 pr-2'>{searchButton()}</div>
          <div className='col-3 pl-0'>{filterButton()}</div>
        </div>
        {/* {error ? (
          <div className='mt-2'>
            <EmptyActivity subtitle={error} />
          </div>
        ) : ( */}
        <>
          {loading ? (
            <div className='loaderDiv'>
              <CircleSpinner
                className='spinner-app mx-auto'
                style={{ margin: 'auto' }}
                size={40}
                color='var(--color-font-primary)'
              />
            </div>
          ) : (
            <>
              {reservations?.length === 0 ? (
                <div className='mt-2'>
                  <EmptyActivity title={t('noResults')} />
                </div>
              ) : (
                <div className='mt-2'>
                  {reservations?.map((r, index) => (
                    <ActivityReservationItem
                      activity={r}
                      key={index}
                      date={r.transactionDate}
                      index={index}
                      lastIndex={lastIndex}
                      handleClick={() => history.push(`operation-details?id=${r.id}`)}
                      rightText
                    />
                  ))}
                  {loadingMore && hasMore && (
                    <div className='p-3 d-flex justify-content-center'>
                      <CircleSpinner
                        className='spinner-app mx-auto'
                        style={{ margin: 'auto' }}
                        size={40}
                        color='var(--color-font-primary)'
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
        {/* )} */}
      </ContentApp>
    </>
  );
};
export default AllReservations;
