// VoucherTicket.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/Common/Button/Button';
import styles from './VoucherList.module.css'; // Asegúrate de tener este archivo de estilos
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import StarbucksIcon from '../../../components/icons/ConsumerIcons/StarbucksIcons';

const VoucherTicket = () => {
  const app = useSelector((state) => state.app);
  const history = useHistory();
  const DetailsVoucher = () => {
    history.push('/detailsVoucher'); 
  }


  return (
    <>
    
        <div className={styles.ticketContainer}>
          <div className={`${styles.card} ${styles.cardLeft}`}>
            {/* <img src={app.logoType} alt='logo' className={styles.img} /> */}
            <div style={{marginRight:'15px', marginBottom:'40px'}}> 
        <StarbucksIcon size={36}></StarbucksIcon>

        </div>
      
            <div className={styles.content}>
              <div className={styles.title} style={{ color: '#000' }}>
                Caramel Frappuccino Large
              </div>
              <div className={styles.dates}>Ends on 31 Dec 2022</div>
              <div style={{ fontSize: '14px', marginTop: '10px', marginBottom: '10px' }}>
                <Button
                  size='Voucher'
                  text='See detail'
                  color='#FFF'
                  backgroundColor='var(--color-primary)'
                  onClick={DetailsVoucher}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.card} ${styles.cardRight}`}></div>
        </div>
      
    </>
  );
};

export default VoucherTicket;
