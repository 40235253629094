import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/WelletContext';
import SolidChevron from '../../icons/SolidChevron';
import styles from '../../../screens/SignUp/SignUp.module.css';
const IndustryRoleSelector = ({
  handleChange,
  selectedIndustry,
  setSelectedIndustry,
  selectedRp,
  setSelectedRp,
  selectedCity,
  setSelectedCity,
  errors,
  values,
}) => {
  const { t } = useTranslation();
  const [rps, setRps] = useState([]);
  const welletContext = useContext(WelletContext);
  const withoutRP = { value: '0', label: t('signUp.withOutRP') };
  const hasNoRp = selectedRp.value === '0';

  const handleChangeIndustry = (selectedOption) => {
    handleChange({ target: { name: 'industry', value: selectedOption.label }, persist: () => {} });
    setSelectedIndustry(selectedOption.label);
  };

  const handleChangeRP = (selectedOption) => {
    handleChange({ target: { name: 'rp', value: selectedOption.value }, persist: () => {} });
    setSelectedRp(selectedOption);
  };

  const handleChangeCity = (selectedOption) => {
    handleChange({ target: { name: 'city', value: selectedOption.value }, persist: () => {} });
    setSelectedCity(selectedOption);
  };

  const getRP = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`associates/GetRPs`);
      setRps(result.data.map((item) => ({ value: item.id, label: item.name })));
      setRps((rps) => [...rps, withoutRP]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRP();
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='mr-3 p-0'>
        <SolidChevron size={18} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const industryOptions = [
    { value: 'hotel', label: t('industry.hotel') },
    { value: 'agency', label: t('industry.agency') },
    { value: 'dmc', label: t('industry.dmc') },
    { value: 'mp', label: t('industry.meetingPlanner') },
    { value: 'wp', label: t('industry.weddingPlanner') },
    { value: 'conveyor', label: t('industry.transportation') },
    { value: 'bank', label: t('industry.bank') },
    { value: 'airbnb', label: t('industry.airbnb') },
    { value: 'yates', label: t('industry.yachts') },
    { value: 'other', label: t('industry.other') },
  ];

  const cityOptions = [
    {
      value: 935,
      label: 'Cancún',
    },
    {
      value: 2265,
      label: 'Ciudad de México',
    },
    {
      value: 7925,
      label: 'Isla Mujeres',
    },
    {
      value: 182189,
      label: 'Tulum',
    },
    {
      value: 9631,
      label: 'Los Cabos',
    },
  ];

  const selectorStylesRole = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '.4rem',
      boxShadow: 'none',
      backgroundColor: '#000',
      border: '1px solid var(--color-primary) !important',
      borderRadius: '10px',
      height: '69px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold',
    }),
    menu: (provided, state) => ({
      ...provided,
      bottom: 'auto',
      top: 65,
      color: '#000',
    }),
  };

  const selectorStylesRp = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '.4rem',
      boxShadow: 'none',
      backgroundColor: '#000',
      border: '1px solid var(--color-primary) !important',
      borderRadius: '10px',
      height: '69px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold',
    }),
    menu: (provided, state) => ({
      ...provided,
      top: 'auto',
      bottom: 65,
      color: '#000',
    }),
  };

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <span className='text-secondary bold'>{t('signUp.sector')}</span>
      <Select
        className={`w-100 mt-2 hide-indicator-separator font-normal`}
        components={{ DropdownIndicator }}
        isSearchable={false}
        styles={selectorStylesRole}
        options={industryOptions}
        formatOptionLabel={(o) => (
          <div className='option-inner d-flex py-3'>
            <span>{o.label}</span>
          </div>
        )}
        onChange={handleChangeIndustry}
        placeholder={selectedIndustry}
        value={values.industry}
      />
      {errors.industry && errors.industry > 0 && (
        <div className={`${styles.errorLabel}`}>
          <span></span> {errors.industry}
        </div>
      )}
      <div className='mt-3'>
        <span className='text-secondary bold'>{t('signUp.company')}</span>
        <input
          type='text'
          className={`w-100 mt-2 font-normal ${styles.roleInput}`}
          value={values.company}
          name='company'
          onChange={handleChange}
        />
        {errors.company && errors.company.length > 0 && (
          <div className={`${styles.errorLabel}`}>
            <span></span> {errors.company}
          </div>
        )}
      </div>
      <div className='mt-3'>
        <span className='text-secondary bold'>{t('signUp.role')}</span>
        <input
          type='text'
          className={`w-100 mt-2 font-normal ${styles.roleInput}`}
          value={values.role}
          name='role'
          onChange={handleChange}
        />
        {errors.role && errors.role.length > 0 && (
          <div className={`${styles.errorLabel}`}>
            <span></span> {errors.role}
          </div>
        )}
      </div>
      <div className='text-secondary bold mt-3'>{t('signUp.digitalRP')}</div>
      <Select
        className={`w-100 mt-2 hide-indicator-separator font-normal`}
        components={{ DropdownIndicator }}
        isSearchable={false}
        styles={selectorStylesRp}
        options={rps}
        onChange={handleChangeRP}
        placeholder={selectedRp.label}
        value={values.rp}
        formatOptionLabel={(o) => (
          <div className='option-inner d-flex py-3'>
            <span>{o.label}</span>
          </div>
        )}
      />
      {hasNoRp && (
        <div className='fade-up'>
          <div className='text-secondary bold mt-3'>{t('signUp.cityOfOperation')}</div>
          <Select
            className={`w-100 mt-2 hide-indicator-separator font-normal`}
            components={{ DropdownIndicator }}
            isSearchable={false}
            onTouchStart={handleTouchStart}
            styles={selectorStylesRp}
            options={cityOptions}
            onChange={handleChangeCity}
            placeholder={selectedCity.label}
            value={values.city}
            formatOptionLabel={(o) => (
              <div className='option-inner d-flex py-3'>
                <span>{o.label}</span>
              </div>
            )}
          />
        </div>
      )}
      {errors.rps && errors.rps > 0 && (
        <div className={`${styles.errorLabel}`}>
          <span></span> {errors.rps}
        </div>
      )}
    </>
  );
};

export default IndustryRoleSelector;
