import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { exchangeToken, authChecked } from '../actions/associatesActions';
import { setTenantId as setAppTenant } from '../actions/appActions';
import axios from 'axios';

const Auth = () => {
  const dispatch = useDispatch();
  const [tenantId, setTenantId] = useState(null);

  const fetchTenant = async () => {
    let tenant = null;
    try {
      const welletApi = process.env.REACT_APP_SALES_API_URL;
      const response = await axios.get(`${welletApi}/auth/tenant`);
      tenant = response.data;
    } catch (error) {
      console.error(error);
    }

    setTenantId(tenant);
    dispatch(setAppTenant(tenant));

    return tenant;
  };

  useEffect(() => {
    if (!tenantId) {
      fetchTenant();
    }
  }, [tenantId]);

  return tenantId ? <AuthWithTenant tenantId={tenantId} /> : null;
};

const AuthWithTenant = ({ tenantId }) => {
  const dispatch = useDispatch();
  const auth = getAuth();
  auth.tenantId = tenantId;

  const [user] = useAuthState(auth);

  useEffect(() => {
    if (auth._isInitialized && !user) {
      dispatch(authChecked());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth._isInitialized]);

  useEffect(() => {
    if (user) {
      exchangeToken(dispatch, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return null;
};

export default Auth;
