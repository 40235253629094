import React, { useContext } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { useActivities } from '../../../hooks/useActivities';
import { getLocale, validateDate } from '../../../helpers/dates';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import Activity from '../../../components/Activities/Activity/Activity';
import { useSelector } from 'react-redux';
import styles from '../Points/pointsScreen.module.css';
import { Spinner } from '../../../components/Common/Spinner/Spinner';
import { useTranslation } from 'react-i18next';

const PointsActivityScreen = () => {
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const { t } = useTranslation();
  const pointsInfoData = useActivities({
    pageSize: 10,
    endpoint: '/associates/getpoints',
    api: welletContext.apis.tickets,
  });

  return (
    <>
      <Header hasBackground={true} showBack={true} title={'Mis puntos'} />
      <ContentApp>
        <div className='card'>
          {pointsInfoData?.activities?.map((p, index) => (
            <Activity
              key={index}
              loading={pointsInfoData.loading}
              hasMore={pointsInfoData.hasMore}
              loadMore={pointsInfoData.loadMore}
              title={
                <div className='text-secondary'>{t('pointsIncome')}{`${p.referenceCode}`}</div>
              }
              subtitle={<div>{p.showName}</div>}
              item2={
                <div className='bold'>{`${
                  p.points > 0 ? `+ ${p.points}` : `- ${Math.abs(p.points)}`
                }`}</div>
              }
              item3={
                <div className='text-secondary text-right'>
                  {validateDate(p.operationDate, locale, 'HH:mm', 'dd MMM')}
                </div>
              }
              className={`${
                index === pointsInfoData.activities.length - 1 ? '' : 'feed-item-border'
              } ${styles.activity}`}
            />
          ))}
        </div>
        {pointsInfoData?.loading && (
          <div className='d-flex justify-content-center mt-3'>
            <Spinner size={25} color='#fff' />
          </div>
        )}
      </ContentApp>
    </>
  );
};

export default PointsActivityScreen;
