import React from 'react';

const QrDigitalIcon = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 52 52'
    fill='none'
  >
    <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
    <g clipPath='url(#clip0_424_2326)'>
      <path
        d='M18.3755 17.3149V24.0702H25.1308V17.3149H18.3755ZM23.4171 22.369H20.0767V19.0286H23.4171V22.369Z'
        fill='white'
      />
      <path
        d='M18.3755 25.6475V32.4028H25.1308V25.6475H18.3755ZM23.4171 30.6891H20.0767V27.3487H23.4171V30.6891Z'
        fill='white'
      />
      <path
        d='M26.7823 17.3149V24.0702H33.5376V17.3149H26.7823ZM31.8364 22.369H28.496V19.0286H31.8364V22.369Z'
        fill='white'
      />
      <path d='M28.496 25.6475H26.7823V27.3611H28.496V25.6475Z' fill='white' />
      <path d='M30.2096 27.3608H28.496V29.0745H30.2096V27.3608Z' fill='white' />
      <path d='M33.637 27.3608H31.9233V29.0745H33.637V27.3608Z' fill='white' />
      <path d='M28.496 29.062H26.7823V30.7757H28.496V29.062Z' fill='white' />
      <path d='M31.9234 29.062H30.2097V30.7757H31.9234V29.062Z' fill='white' />
      <path d='M30.2096 30.7759H28.496V32.4895H30.2096V30.7759Z' fill='white' />
      <path d='M33.637 30.7759H31.9233V32.4895H33.637V30.7759Z' fill='white' />
      <path d='M31.9234 25.6475H30.2097V27.3611H31.9234V25.6475Z' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9613 14.2662C12.0251 14.2662 11.2662 15.0251 11.2662 15.9613V33.8429C11.2662 34.7791 12.0251 35.538 12.9613 35.538H39.0387C39.9748 35.538 40.7337 34.7791 40.7337 33.8429V15.9613C40.7337 15.0251 39.9748 14.2662 39.0387 14.2662H12.9613ZM9.21729 15.9613C9.21729 13.8935 10.8935 12.2173 12.9613 12.2173H39.0387C41.1064 12.2173 42.7827 13.8935 42.7827 15.9613V33.8429C42.7827 35.9107 41.1064 37.5869 39.0387 37.5869H12.9613C10.8935 37.5869 9.21729 35.9107 9.21729 33.8429V15.9613Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.7294 41.4922C18.7294 40.9264 19.188 40.4678 19.7538 40.4678H32.2462C32.812 40.4678 33.2706 40.9264 33.2706 41.4922C33.2706 42.058 32.812 42.5167 32.2462 42.5167H19.7538C19.188 42.5167 18.7294 42.058 18.7294 41.4922Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.0001 35.5381C26.5659 35.5381 27.0245 35.9968 27.0245 36.5626V41.4924C27.0245 42.0582 26.5659 42.5169 26.0001 42.5169C25.4343 42.5169 24.9756 42.0582 24.9756 41.4924V36.5626C24.9756 35.9968 25.4343 35.5381 26.0001 35.5381Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_424_2326'>
        <rect width='34' height='30.7341' fill='white' transform='translate(9 12)' />
      </clipPath>
    </defs>
  </svg>
);

export default QrDigitalIcon;
