import { useSelector } from "react-redux";
import CustomerNavConsumer from "../CustomerNav/CustomNavConsumer/CustomerNavConsumer";
import CustomerNav from "../CustomerNav/CustomerNav";

const NavSelector = ({history}) => {
    const app = useSelector((state) => state.app);
    if (app.appType === 'CONSUMER') {
        return <CustomerNavConsumer history={history} />;
    } else if (app.appType === 'AFFILIATE') {
        return <CustomerNav  history={history}  />;
    }
}

export default NavSelector;
