import { getQueryString } from '../../helpers/url';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import { useTranslation } from 'react-i18next';

const Descargar = () => {
  const { t } = useTranslation();
  const url = getQueryString('url');
  const fileName = getQueryString('filename');

  const download = async () => {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <Header showBack={false} title={<div className='text-capitalize'>Descargar video</div>} />
      <ContentApp classes='mt-4'>
        <div className='text-center'>
          <video controls='controls' preload='metadata' height={'320px'}>
            <source src={url + '#t=0.1'} type='video/mp4' />
          </video>
          <div className='mt-3'>
            <Button onClick={download} size='large' text={t('download')} />
          </div>
        </div>
      </ContentApp>
    </>
  );
};

export default Descargar;
