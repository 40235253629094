import { useTranslation } from 'react-i18next';
import Button from '../../../components/Common/Button/Button';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import styles from '../reservation.module.css';
import ReservationSent from '../../../components/icons/ReservationSent';
import FixedButton from '../../../components/Common/FixedButton/FixedButton';
import { useSelector } from 'react-redux';
import '../../../css/reservation.css';

const ReservationSuccess = ({ history }) => {
  const { t } = useTranslation();
  const showName = useSelector((state) => state.reservation.showName);

  return (
    <>
      <Header showBack={false} title={showName} />
      <ContentApp classes={'pt-0'}>
        <div className={`d-flex align-items-center justify-content-center ${styles.iconContainer}`}>
          <ReservationSent color={'var(--color-primary)'} size='135px' />
        </div>
        <div className='mt-5 pt-3 px-3'>
          <div className={`bold text-left font-large ${styles.title}`}>{t('requestSent')}</div>
          <div className='text-secondary semi-bold font-normal text-left mt-3'>
            {t('processingRequest')}
          </div>
        </div>
        <FixedButton>
          <Button
            text={t('returnHome')}
            size='large'
            className={`mt-2 ${styles.btn}`}
            onClick={() => history.push('/')}
            icon={true}
            typeIcon='arrow'
            iconColor='#000'
          />
        </FixedButton>
      </ContentApp>
    </>
  );
};

export default ReservationSuccess;
