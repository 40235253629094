export async function sendPaymentLinkByMail(welletContext, token, orderId, mail) {
  await welletContext.apis.tickets.get(
    `/orders/CompletePurchase?orderId=${orderId}&token=${token}&newCustomerEmail=${mail}`,
  );
}

export function getPaymentLink(order, app) {
  const subdomain = app.scopeOrganization.organizationNameId;
  const urlWithSubdomain = app.webURL.replace('www', subdomain);
  return `${urlWithSubdomain}/${
    order.customer.language ? order.customer.language : order.settings.language
  }/payment/${order.tokenCode}`;
}

export const getStripeOnboardingRefreshLink = async (welletContext, onboardingToken) => {
  try {
    const response = await welletContext.apis.tickets.post(
      `/withdrawal/stripe/RefreshOnboardingLink`,
      {
        onboardingToken,
      }
    );

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

export const confirmStripeOnboarding = async (welletContext, onboardingToken) => {
  const response = await welletContext.apis.tickets.post(
    `/withdrawal/stripe/ConfirmOnboardingToken`,
    {
      onboardingToken,
    }
  );

  return response.data;
};