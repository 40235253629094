import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import { getQueryString } from '../../../helpers/url';
import ContentApp from '../../../components/ContentApp';
import ReservationDetail from '../../../components/ReservationDetail/ReservationDetail';
import Header from '../../../components/Common/Header/Header';

const ReservationDetailScreen = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const id = getQueryString('id');
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState(null);

  const getReservation = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get(`/order/${id}`);

      setReservation(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReservation();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Header
            showBack={true}
            title={t('transactionDetail') + ' ' + reservation.referenceCode}
          />
          <ContentApp>
            <ReservationDetail history={history} reservation={reservation} />
          </ContentApp>
        </>
      ) : null}
    </>
  );
};

export default ReservationDetailScreen;
