import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotificationsActiveIcon from '../icons/Menu/NotificationsActiveIcon';
import NotificationsInactiveIcon from '../icons/Menu/NotificationsInactiveIcon';
import PaymentsActiveIcon from '../icons/Menu/PaymentsActiveIcon';
import PaymentsInactiveIcon from '../icons/Menu/PaymentsInactiveIcons';

import MembershipActiveIcon from '../icons/Menu/MembershipActiveIcon';
import MembershipInactiveIcon from '../icons/Menu/MembershipInactiveIcon';
import HomeActiveIcon from '../icons/Menu/HomeActiveIcon';
import HomeInactiveIcon from '../icons/Menu/HomeInactiveIcon';
import styles from './CustomerNav.module.css';
import LinkSocialIcon from '../icons/LinkSocialIcon';

const CustomerNav = ({ history }) => {
  const location = useLocation();
  const hash = location.pathname;
  const user = useSelector((state) => state.associates.user);
  const notificationsToRead = user?.notificationsToRead;

  const pathname = {
    home: '/',
    points: '/points',
    campaigns: '/campaigns-feed',
    shareCampaigns: '/share-campaign',
    money: '/my-money',
    notifications: '/notifications',
  };

  return (
    <div className={`${styles.menuNav} position-fixed w-100`}>
      <div className='container'>
        <div className='row justify-content-center '>
          <div className={`col-12 col-md-7 col-lg-6 ${styles.customerNav}`}>
            <div className='row px-2'>
              <div className='col' onClick={() => history.push('/')}>
                <div className={`${styles.link} ${hash === pathname.home ? styles.active : ''}`}>
                  {hash === pathname.home ? (
                    <HomeActiveIcon size='22px' />
                  ) : (
                    <HomeInactiveIcon size='22px' />
                  )}
                </div>
              </div>
              <div className='col' onClick={() => history.push('/points')}>
                <div className={`${styles.link} ${hash === pathname.points ? styles.active : ''}`}>
                  {hash === pathname.points ? (
                    <MembershipActiveIcon size='22px' />
                  ) : (
                    <MembershipInactiveIcon size='22px' />
                  )}
                </div>
              </div>
              <div className='col' onClick={() => history.push('/campaigns-feed')}>
                <div
                  className={`${styles.link} ${hash === pathname.campaigns || hash === pathname.shareCampaigns ? styles.active : ''}`}
                >
                  {hash === pathname.campaigns || hash === pathname.shareCampaigns ? (
                    <LinkSocialIcon size='22px' color='#1d9bf0' />
                  ) : (
                    <LinkSocialIcon color='#ffffff' size='22px' />
                  )}
                </div>
              </div>
              <div className='col' onClick={() => history.push('/my-money')}>
                <div className={`${styles.link} ${hash === pathname.money ? styles.active : ''}`}>
                  {hash === pathname.money ? (
                    <PaymentsActiveIcon size='22px' />
                  ) : (
                    <PaymentsInactiveIcon size='22px' />
                  )}
                </div>
              </div>
              <div className='col' onClick={() => history.push('/notifications')}>
                <div
                  className={`${styles.link} ${
                    hash === pathname.notifications ? styles.active : ''
                  }`}
                >
                  <div className={styles.notificationIcon}>
                    {notificationsToRead ? (
                      <div
                        className={`d-flex justify-content-center align-items-center ${styles.alertQuantity}`}
                      >
                        {notificationsToRead}
                      </div>
                    ) : null}
                  </div>

                  {hash === pathname.notifications ? (
                    <NotificationsActiveIcon size='20px' />
                  ) : (
                    <NotificationsInactiveIcon size='20px' />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerNav;
