import React from 'react';

const ScanQr = ({ fillColor = '#FFF', size = '32px', onClick }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 7.5H7.5V12.5H12.5V7.5ZM5 5V15H15V5H5Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.5 7.5H19.5V12.5H24.5V7.5ZM17 5V15H27V5H17Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 19.5H7.5V24.5H12.5V19.5ZM5 17V27H15V17H5Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 19.25H22.5V17H27.25V22H25V19.25ZM16.75 17H19.25V19.75H22.25V24.5H19.75V22.25H16.75V17ZM19.25 27.25H16.75V24.75H19.25V27.25ZM27.25 24.75V27.25H22.5V24.75H27.25Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 2.5H25.25V0H32.5V7.25H30V2.5Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 2.5L2.5 7.25L0 7.25L0 0L7.25 0L7.25 2.5L2.5 2.5Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 30L7.25 30L7.25 32.5L0 32.5L0 25.25L2.5 25.25L2.5 30Z"
            fill={fillColor}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 30L30 25.25L32.5 25.25L32.5 32.5L25.25 32.5L25.25 30L30 30Z"
            fill={fillColor}
        />
    </svg>
);

export default ScanQr;
