import React, { useMemo } from 'react';
import axios from 'axios';
import { WelletContext } from './WelletContext';

function redirectUnauthorizedNetworkCalls(api, redirectPath) {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location.href = redirectPath;
      }
      return Promise.reject(error);
    },
  );
}

function welletApis(apiUrls, user, app, redirectPathWhenUnauthorized) {
  const headers = {};
  if (user) {
    if (user.jwt) {
      headers['Authorization'] = `Bearer ${user.jwt}`;
    }
    if (user.apiKey) {
      headers['x-api-key'] = user.apiKey;
    }
  }

  if (app.scopeOrganization && app.scopeOrganization > 0) {
    headers['x-wellet-scope'] = app.scopeOrganization;
  }

  const tickets = axios.create({
    baseURL: apiUrls.ticketsUrl,
    headers,
  });

  if (redirectPathWhenUnauthorized) {
    redirectUnauthorizedNetworkCalls(tickets, redirectPathWhenUnauthorized);
  }

  return {
    tickets,
  };
}

export function WelletProvider({
  apiUrls,
  user,
  app,
  children,
  redirectPathWhenUnauthorized = '',
}) {
  const value = useMemo(() => {
    const apis = welletApis(apiUrls, user, app, redirectPathWhenUnauthorized);

    return {
      apis,
      user,
      app,
    };
  }, [user, apiUrls, app]);

  return <WelletContext.Provider value={value}>{children}</WelletContext.Provider>;
}
