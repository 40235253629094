import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { getDateFromOffset } from '../../../helpers/dates';
import { WelletContext } from '../../../context/WelletContext';
import { skeletonLoading } from '../../../components/Skeletons/SkeletonAccount';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import Balance from '../../../components/Balance/Balance';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import Button from '../../../components/Common/Button/Button';
import WarningBox from '../../../components/Common/WarningBox/WarningBox';
import ActivityReservationItem from '../../../components/Activities/ActivityReservationItem.js/ActivityReservationItem';
import useRealtimeOrders from '../../../hooks/useRealtimeOrders';
import { CircleSpinner } from 'react-spinners-kit';
import AccountFilterIcon from '../../../components/icons/AccountFilterIcon';
import CustomButton from '../../../components/Common/CustomButtom/CustomButton';
import ArrowIcon from '../../../components/icons/ArrowIcon';
import InstagramIconHome from '../../../components/icons/InstagramIconHome';
import QrIconHome from '../../../components/icons/QrIconHome';
import ReservationIcon from '../../../components/icons/ReservationIcon';

const Account = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const jwt = useSelector((state) => state.associates.jwt);
  const user = useSelector((state) => state.associates.user);
  const userWithdrawalMethod = user?.userWithdrawalMethod;
  const today = getDateFromOffset(user.companyUtcMinutesOffset);
  const todayFilter = format(today, 'yyyy-MM-dd');
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [filterDate, setFilterDate] = useState(localStorage.getItem('filterDate') ?? 'ORDER_DATE');
  const [openModal, setOpenModal] = useState(false);

  const [myEarningsToday, setMyEarningsToday] = useState(null);
  const [monthEarning, setMonthEarning] = useState(0);
  const kycStatus = useSelector((state) => state.associates.user.kycStatus);
  const advantageNetworkStatus = useSelector(
    (state) => state?.associates?.user?.advantageNetworkStatus,
  );
  const [selectedOption, setSelectedOption] = useState(filterDate);
  const app = useSelector((state) => state.app);

  const wireMethod =
    user.withdrawalMethod === 'WIRE_INTERNATIONAL' || user.withdrawalMethod === 'WIRE_MEXICO';
  const cashOrCertificate =
    user.withdrawalMethod === 'CASH' || user.withdrawalMethod === 'CERTIFICATE';

  const getMyEarningsToday = async () => {
    setLoadingBalance(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${todayFilter}&to=${todayFilter}`,
      );
      setMyEarningsToday(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBalance(false);
    }
  };

  const getEarnings = async (fromDate, toDate) => {
    setLoadingBalance(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${fromDate}&to=${toDate}`,
      );
      setMonthEarning(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBalance(false);
    }
  };

  const handleValidReservationUpdate = useCallback(() => {
    getMyEarningsToday();

    const date = getDateFromOffset(user.companyUtcMinutesOffset);
    const firstMonthDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const lastMonthDate = format(endOfMonth(date), 'yyyy-MM-dd');

    getEarnings(firstMonthDate, lastMonthDate);
  }, [getMyEarningsToday, getEarnings]);

  const { orders, loading, hasMore, loadingMore, fetchMoreOrders } = useRealtimeOrders(
    today,
    filterDate,
    handleValidReservationUpdate,
  );

  useEffect(() => {
    getMyEarningsToday();
    const date = getDateFromOffset(user.companyUtcMinutesOffset);
    const firstMonthDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const lastMonthDate = format(endOfMonth(date), 'yyyy-MM-dd');

    getEarnings(firstMonthDate, lastMonthDate);
  }, [jwt]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedFilterDate = localStorage.getItem('filterDate');
    if (storedFilterDate) {
      setFilterDate(storedFilterDate);
    }
  }, []);

  const isEmptyActivity = orders?.length === 0 || undefined;

  useEffect(() => {
    if (!localStorage.getItem('filterDate')) localStorage.setItem('filterDate', 'ORDER_DATE');
  }, []);

  const handleSelectChange = (selectedOption) => {
    localStorage.setItem('filterDate', selectedOption);
    setFilterDate(selectedOption);
    setSelectedOption(selectedOption);
  };

  const renderWithdrawalMethodWarningBox = () => {
    if (
      (kycStatus !== 'APPROVED' || advantageNetworkStatus !== 'APPROVED') &&
      !userWithdrawalMethod
    ) {
      return null;
    }

    if (userWithdrawalMethod) {
      return null;
    }

    let title = '';
    let subtitle = '';
    let nextUrl = '';

    if (user.withdrawalMethod === 'STRIPE') {
      title = t('noAccountAssociated.STRIPE.title');
      subtitle = t('noAccountAssociated.STRIPE.subtitle');
      nextUrl = '/stripeaccount';
    } else if (user.withdrawalMethod === 'MERCADOPAGO') {
      title = t('noAccountAssociated.mercadoPago.title');
      subtitle = t('noAccountAssociated.mercadoPago.subtitle');
      nextUrl = '/mercadopagoaccount';
    } else if (wireMethod) {
      title = t('noAccountAssociated.WIRE.title');
      subtitle = t('noAccountAssociated.WIRE.subtitle');
      if (user.withdrawalMethod === 'WIRE_INTERNATIONAL') {
        nextUrl = '/international-bank';
      } else if (user.withdrawalMethod === 'WIRE_MEXICO') {
        nextUrl = '/national-bank';
      }
    }

    return (
      <WarningBox
        title={title}
        subtitle={
          <div>
            {subtitle}
            <span style={{ color: 'var(--color-primary)', textDecoration: 'underline' }}>
              {t('here')}
            </span>
            .
          </div>
        }
        onClick={() => history.push(nextUrl)}
      />
    );
  };

  const checkNickNameAndRedirect = () => {
    if (user.nickName === null) {
      setOpenModal(true);
    } else {
      history.push('/campaigns-feed');
    }
  };
  return (
    <div>
      <AccountHeader title={app.name} icon={'config'} pathName='/configuration' />
      <ContentApp menu header>
        <div className='mb-2'>
          {advantageNetworkStatus !== 'APPROVED' ? (
            <WarningBox
              title={t('advantageNetworkStatus.title')}
              subtitle={t('advantageNetworkStatus.subtitle')}
            />
          ) : (
            (kycStatus === 'PENDING' || kycStatus === 'REJECTED') && (
              <WarningBox
                title={t('identityNotValidated.title')}
                subtitle={t('identityNotValidated.subtitle')}
                textLink={t('identityNotValidated.textLink')}
                onClickText={() => history.push('/kyc-start')}
              />
            )
          )}
        </div>

        <div>
          {renderWithdrawalMethodWarningBox()}
          <div className='mt-2'>
            <Balance
              title={t('earnings')}
              todayBalance={myEarningsToday?.amount}
              last7DaysBalance={monthEarning?.amount}
              showText={true}
              fontSize='1.8rem'
              showAllBalances={true}
              loading={loadingBalance}
              mpVerified={true}
              showArrow={true}
            />
          </div>

          {/* <div className='d-flex'>
            <Button
              size='large'
              className='col mr-2 btn-primary mt-3 btn-account'
              text={t('myLink')}
              iconColor='#000'
              icon={true}
              typeIcon='link'
              onClick={() => history.push('/qr-campaign')}
            />
            <Button
              size='large'
              className='col btn-primary mt-3 btn-account'
              text={t('Instagram')}
              icon={true}
              typeIcon='arrow'
              iconColor='#000'
              onClick={() => history.push('/campaigns-feed')}
            />
          </div> */}

          <div className='d-flex mt-2'>
            <div className='col pl-0 pr-1'>
              <CustomButton
                text={t('campaigns')}
                leftIcon={<InstagramIconHome size={20} fillColor='#000' />}
                rightIcon={<ArrowIcon size={15} />}
                size='instagram'
                onClick={checkNickNameAndRedirect}
                className='btn-primary mb-2'
              />
            </div>
            <div className='col pr-0 pl-1'>
              <CustomButton
                size='instagram'
                rightIcon={<ArrowIcon size={15} />}
                leftIcon={<QrIconHome size={20} fillColor='#000' />}
                text={t(' QR')}
                onClick={() => history.push('/qr-campaign')}
                className='btn-primary btn-account'
              />
            </div>
          </div>

          <div className='bold font-normal text-lighter-gray mt-2'>{t('myIncome')}</div>
          <div className='row mt-3 mb-4'>
            <div className='col-auto pr-0'>
              <div className='account-button' onClick={() => history.push('/all-reservations')}>
                <AccountFilterIcon color='#7E7E7E' size='16px' styles={{ marginTop: '-2px' }} />
              </div>
            </div>
            <div className='col px-1'>
              <div
                className={`account-button ${selectedOption === 'ORDER_DATE' ? 'selected' : ''}`}
                onClick={() => handleSelectChange('ORDER_DATE')}
              >
                {t('forToday')}
              </div>
            </div>
            <div className='col col-md pl-0'>
              <div
                className={`account-button ${selectedOption === 'CREATED_DATE' ? 'selected' : ''}`}
                onClick={() => handleSelectChange('CREATED_DATE')}
              >
                {t('mostRecent')}
              </div>
            </div>
          </div>

          {loading ? (
            skeletonLoading()
          ) : (
            <>
              {isEmptyActivity ? (
                <EmptyActivity subtitle={<div className='bold'>{t('noBookings')}</div>} />
              ) : (
                <>
                  {orders?.map((r, index) => (
                    <div key={index}>
                      <ActivityReservationItem
                        activity={r}
                        date={r?.transactionDate}
                        rightText={r.amount}
                        index={index}
                        lastIndex={orders.length - 1}
                        handleClick={() => history.push(`/operation-details?id=${r.id}`)}
                      />
                    </div>
                  ))}
                  {loadingMore ? (
                    <div className='p-3 d-flex justify-content-center'>
                      <CircleSpinner
                        className='spinner-app mx-auto'
                        style={{ margin: 'auto' }}
                        size={40}
                        color='var(--color-font-primary)'
                      />
                    </div>
                  ) : (
                    hasMore && (
                      <div
                        className='text-secondary font-small fw-semibold text-center p-2 mt-2'
                        style={{ border: '1px solid #3D3D3D', borderRadius: '5px' }}
                        onClick={fetchMoreOrders}
                      >
                        {t('showMore')}
                      </div>
                    )
                  )}
                </>
              )}
            </>
          )}
        </div>
        <CustomerNav history={history} />
      </ContentApp>
    </div>
  );
};

export default Account;
