import React from 'react';

const DownloadIcon = ({ size, color, secondColor = '#fff', styles }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 92.000000 92.000000'
      preserveAspectRatio='xMidYMid meet'
      style={styles}
    >
      <g
        transform='translate(0.000000,92.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke={secondColor}
      >
        <path
          d='M330 901 c-336 -107 -440 -518 -194 -765 182 -181 466 -181 648 0
181 182 181 466 0 648 -120 119 -302 166 -454 117z m151 -111 c18 -10 19 -23
19 -187 l0 -176 50 47 c27 25 54 46 59 46 13 0 41 -29 41 -43 0 -17 -172 -187
-190 -187 -18 0 -190 170 -190 187 0 14 28 43 41 43 5 0 32 -21 59 -46 l50
-47 0 176 c0 157 2 177 18 186 21 13 20 13 43 1z m159 -565 c6 -8 9 -23 5 -35
-6 -19 -14 -20 -185 -20 -171 0 -179 1 -185 20 -14 45 6 50 185 50 134 0 170
-3 180 -15z'
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;
