import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../../context/WelletContext';
import { getQueryString } from '../../../helpers/url';
import { CircleSpinner } from 'react-spinners-kit';

// import screens for each campaign type
import Reservation from '../../Reservation/Reservation';
import ReservationArea from '../../Reservation/ReservationArea/ReservationArea';

const campaignTypeScreen = {
  RESERVATION: Reservation,
  AREA: ReservationArea,
  //   RESERVATIONGROUP: ReservationArea,
};
// end screens for each campaign type

const MktCampaignScreen = (props) => {
  const [mktCampaign, setMktCampaign] = useState(null);
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const campaignId = getQueryString('campaignId');

  const getCampaign = async () => {
    const result = await welletContext.apis.tickets.get(
      `/mktcampaign/${campaignId}?lang=${language}`,
    );
    setMktCampaign(result.data);
  };

  useEffect(() => {
    getCampaign();
  }, []);

  if (!mktCampaign) {
    // loading...
    return (
      <div className='loaderDiv'>
        <CircleSpinner
          className='spinner-app mx-auto'
          style={{ margin: 'auto' }}
          size={40}
          color='var(--color-font-primary)'
        />
      </div>
    );
  }

  const CampaignScreen = campaignTypeScreen[mktCampaign.mktCampaignType];

  return <CampaignScreen {...props} mktCampaign={mktCampaign} />;
};

export default MktCampaignScreen;
