import React from 'react';

const QrPrintedIcon = ({ size, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 52 52'
    fill='none'
  >
    <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
    <path
      d='M19.5195 21.5586V27.6992H25.6602V21.5586H19.5195ZM24.1016 26.1406H21.0781V23.1172H24.1016V26.1406Z'
      fill='white'
    />
    <path
      d='M19.5195 29.1289V35.2695H25.6602V29.1289H19.5195ZM24.1016 33.7109H21.0781V30.6875H24.1016V33.7109Z'
      fill='white'
    />
    <path
      d='M27.1602 21.5586V27.6992H33.3008V21.5586H27.1602ZM31.7539 26.1406H28.7305V23.1172H31.7539V26.1406Z'
      fill='white'
    />
    <path d='M28.7188 29.1289H27.1602V30.6875H28.7188V29.1289Z' fill='white' />
    <path d='M30.2773 30.6758H28.7188V32.2344H30.2773V30.6758Z' fill='white' />
    <path d='M33.3828 30.6758H31.8242V32.2344H33.3828V30.6758Z' fill='white' />
    <path d='M28.7188 32.2344H27.1602V33.793H28.7188V32.2344Z' fill='white' />
    <path d='M31.8359 32.2344H30.2773V33.793H31.8359V32.2344Z' fill='white' />
    <path d='M30.2773 33.7812H28.7188V35.3398H30.2773V33.7812Z' fill='white' />
    <path d='M33.3828 33.7812H31.8242V35.3398H33.3828V33.7812Z' fill='white' />
    <path d='M31.8359 29.1289H30.2773V30.6875H31.8359V29.1289Z' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.2051 12.1719C14.2051 11.6379 14.6379 11.2051 15.1719 11.2051H30.3945C30.6435 11.2051 30.8828 11.3011 31.0627 11.4731L37.4963 17.6255C37.687 17.8079 37.7949 18.0603 37.7949 18.3242V38.832C37.7949 39.366 37.3621 39.7988 36.8281 39.7988H15.1719C14.6379 39.7988 14.2051 39.366 14.2051 38.832V12.1719ZM16.1387 13.1387V37.8652H35.8613V18.7374L30.0067 13.1387H16.1387Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.3945 11.2051C30.9285 11.2051 31.3613 11.6379 31.3613 12.1719V17.3574H36.8281C37.3621 17.3574 37.7949 17.7903 37.7949 18.3242C37.7949 18.8582 37.3621 19.291 36.8281 19.291H30.3945C29.8606 19.291 29.4277 18.8582 29.4277 18.3242V12.1719C29.4277 11.6379 29.8606 11.2051 30.3945 11.2051Z'
      fill='white'
    />
  </svg>
);

export default QrPrintedIcon;
