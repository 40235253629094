import React, { useState } from 'react';
import styles from './Balance.module.css';
import ChevronIcon from '../../icons/chevronIcon';
import { useTranslation } from 'react-i18next';
const BalanceConsumer = ({ points }) => {
  const [activeButton, setActiveButton] = useState(null);
  const { t } = useTranslation();
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div className='card p-3'>
      <div className='row px-3'>
        <div>
          <div style={{ color: '#D9D9D9' }} className='fw-bold'>
            Balance
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className={`flex-grow-1 ${styles.textSecondary} semi-bold`}>{points}</div>
            <div style={{ marginTop: '20px' }}>
              <ChevronIcon size={23} />
            </div>
          </div>
          <div className={styles.lineaGris}></div>

          <div className={styles.rectanglesContainer}>
            <button
              className={`${styles.rectangle} ${activeButton === 'buy' ? styles.activeButton : ''}`}
              onClick={() => handleButtonClick('buy')}
            >
              {t('consumer.buyPoints')}
            </button>
            <button
              className={`${styles.rectangle} ${
                activeButton === 'redeem' ? styles.activeButton : ''
              }`}
              onClick={() => handleButtonClick('redeem')}
            >
              {t('consumer.redeemPoints')}
            </button>
            <button
              className={`${styles.rectangle} ${
                activeButton === 'transfer' ? styles.activeButton : ''
              }`}
              onClick={() => handleButtonClick('transfer')}
            >
              {t('consumer.transferPoints')}
            </button>
            <button
              className={`${styles.rectangle} ${
                activeButton === 'other' ? styles.activeButton : ''
              }`}
              onClick={() => handleButtonClick('other')}
            >
              {t('consumer.otherOptions')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceConsumer;
