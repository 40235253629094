import React from 'react';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import { QrReader } from 'react-qr-reader';
import styles from './ScanPaymentScrenn.module.css';
import { useTranslation } from 'react-i18next';

const ScanPaymentScreen = ({ history }) => {
  const { t } = useTranslation();
  const handleScan = (data) => {
    if (data) {
      const url = data;
      history.push(`/pay/${url}`);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <>
      <div className={styles.scanPaymentContainer}>
 
      
 <div className={styles.qrScannerWrapper}>

 <Header />
   <QrReader
     onResult={(result, error) => {
       if (!!result) {
         handleScan(result?.text);
       }
       if (!!error) {
         handleError(error);
       }
     }}
     constraints={{
       facingMode: 'environment',
     }}
     className={styles.qr}
   />
   <div className={styles.qrScannerOverlay}>
     <div className={styles.cornerTopLeft}></div>
     <div className={styles.cornerTopRight}></div>
     <div className={styles.cornerBottomLeft}></div>
     <div className={styles.cornerBottomRight}></div>
   </div>
 </div>

 <div className={styles.scanInstructions}>
 <div className={styles.customLine}></div>
 {t('Escanea el código')}
</div>

</div>

  
    </>
  
  );
};

export default ScanPaymentScreen;
