import React from 'react';
import styles from './Tickets.module.css';
import { useSelector } from 'react-redux';
import StarbucksIcon from '../../icons/ConsumerIcons/StarbucksIcons';

const Ticket = () => {
  const app = useSelector((state) => state.app);

  return (
    <div className={styles.ticketContainer}>
      <div className={`${styles.card} ${styles.cardLeft}`}>
        {/* <img src={app.logoType} alt='logo' className={styles.img} /> */}
        <div style={{marginRight:'15px', marginBottom:'20px'}}> 
        <StarbucksIcon size={32}></StarbucksIcon>

        </div>
      

        <div className={styles.content}>
          <div className={styles.title} style={{ color: '#000' }}>
            Caramel Frappucino Large
          </div>
          <div className={styles.dates}>Ends on 31 Dec 2022</div>
          <div className={styles.description}>Claim Voucher</div>
        </div>
      </div>
      <div className={`${styles.card} ${styles.cardRight}`}></div>
    </div>
  );
};

export default Ticket;
