import React from 'react';

const StarIcon = ({ size, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      style={styles}
    >
      <path
        d='M8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16ZM8.252 3.068C8.19536 3.03232 8.13184 3.00898 8.06557 2.99952C7.9993 2.99006 7.93178 2.99468 7.86742 3.01308C7.80306 3.03148 7.7433 3.06325 7.69205 3.10632C7.6408 3.14938 7.59922 3.20277 7.57 3.263L6.37 5.695L3.686 6.085C3.59729 6.0972 3.51381 6.13413 3.44511 6.19155C3.37641 6.24898 3.32525 6.32458 3.2975 6.40971C3.26975 6.49484 3.26652 6.58607 3.28818 6.67295C3.30985 6.75983 3.35553 6.83886 3.42 6.901L5.364 8.793L4.904 11.467C4.88924 11.5549 4.89928 11.6451 4.93301 11.7276C4.96674 11.8101 5.02281 11.8816 5.09491 11.9339C5.16702 11.9863 5.25231 12.0175 5.34118 12.0241C5.43006 12.0306 5.519 12.0122 5.598 11.971L8 10.709L10.4 11.97C10.479 12.0116 10.568 12.0303 10.6571 12.024C10.7461 12.0176 10.8316 11.9864 10.9038 11.9339C10.9761 11.8815 11.0322 11.8098 11.0658 11.7271C11.0994 11.6444 11.1092 11.554 11.094 11.466L10.636 8.793L12.578 6.9C12.6414 6.83757 12.6862 6.75871 12.7073 6.67226C12.7284 6.58582 12.725 6.4952 12.6974 6.41058C12.6699 6.32596 12.6194 6.25067 12.5515 6.19317C12.4836 6.13566 12.401 6.09821 12.313 6.085L9.628 5.695L8.428 3.263C8.38906 3.18259 8.32801 3.11495 8.252 3.068Z'
        fill='#1D9BF0'
      />
    </svg>
  );
};

export default StarIcon;
