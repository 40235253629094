import React, { useContext, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../../context/WelletContext';
import { Toast, ToastType } from '../../../components/Common/Toast/Toast';
import { SkeletonProfile } from '../../../components/Skeletons/SkeletonProfile';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../../components/icons/userIcon';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ItemProfile from './ItemProfile';
import ItemProfileEditable from './ItemProfileEditable';
import styles from './userProfile.module.css';
import ProductModal from '../../../components/Common/ProductModal/ProductModal';
import Button from '../../../components/Common/Button/Button';

const UserProfile = ({ history }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.associates.user);
  const welletContext = useContext(WelletContext);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(userInfo?.address || '');
  const [isAddressEditing, setIsAddressEditing] = useState(false);
  const [rfc, setRfc] = useState(userInfo?.taxIdentification || '');
  const [isRfcEditing, setIsRfcEditing] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showImageOptions, setShowImageOptions] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const phoneNumber = userInfo?.phoneNumber;
  const rfcInputRef = useRef(null);
  const addressInputRef = useRef(null);
  const fileInputRef = useRef(null);


  const updateAddress = async (address) => {
    if (address.trim() === '') {
      setErrorMessage(t('profileError.EMPTY_ADDRESS'));
      setShowToastError(true);
      return;
    }

    const minLength = 5;
    const maxLength = 50;

    const validCharactersRegex = /^[a-zA-Z0-9\s,.'-]+$/;

    if (address.length < minLength) {
      setErrorMessage(t('profileError.SHORT_ADDRESS'));
      setShowToastError(true);
      return;
    }

    if (address.length > maxLength) {
      setErrorMessage(t('profileError.LONG_ADDRESS'));
      setShowToastError(true);
      return;
    }

    if (!validCharactersRegex.test(address)) {
      setErrorMessage(t('profileError.INVALID_ADDRESS'));
      setShowToastError(true);
      return;
    }

    try {
      const result = await welletContext.apis.tickets.put(`/users/updateAddress`, {
        address: address,
      });
      if (result.status === 200) {
        setShowToast(true);
        setIsAddressEditing(false);
      }
    } catch (error) {
      setIsAddressEditing(false);
      console.error(error);
    }
  };

  const updateRfc = async (rfc) => {
    if (rfc.trim() === '') {
      setErrorMessage(t('profileError.EMPTY_RFC'));
      setShowToastError(true);
      return;
    }
    const validLengths = [11, 12, 13];

    if (!validLengths.includes(rfc.length)) {
      setErrorMessage(t('profileError.INVALID_RFC'));
      setShowToastError(true);
      return;
    }

    try {
      const result = await welletContext.apis.tickets.put(`/users/updateTaxIdentification`, {
        TaxIdentification: rfc,
        TaxIdentificationType: 'RFC',
      });
      if (result.status === 200) {
        setShowToast(true);
        setIsRfcEditing(false);
      }
    } catch (error) {
      setIsRfcEditing(false);
      console.error(error);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await welletContext.apis.tickets.get(`/users/getprofile`);
      if (response.status === 200) {
        setUserInfo(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('profileImage', file);

      try {
        const response = await welletContext.apis.tickets.put(
          `/users/updateProfileImage`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        if (response.status === 200) {
          setUserInfo((prevUserInfo) => ({
            ...prevUserInfo,
            profileImage: URL.createObjectURL(file),
          }));
          setShowToast(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (errorMessage) {
    setTimeout(() => {
      setShowToastError(false);
      setErrorMessage(null);
    }, 4000);
  }

  useEffect(() => {
    if (userInfo == null) {
      fetchUser();
    }
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  }, [userInfo, showToast, setShowToast]);

  useEffect(() => {
    if (userInfo != null) {
      setAddress(userInfo.address || '');
      setRfc(userInfo.taxIdentification || '');
      setLoading(false);
    }
  }, [userInfo]);

  const postNewImage = async () => {
    fileInputRef.current.click();
  };

  const getProfileImage = async (image) => {
    try {
      const response = await welletContext.apis.tickets.get(image, {
        responseType: 'blob',
      });
      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(response.data);
      fileReaderInstance.onload = () => {
        setProfileImage(fileReaderInstance.result);
      };
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProfileImage(user.profilePic);
  }, []);

  return (
    <>
      {showToast && (
        <Toast top={false} zIndex={99} type={ToastType.Success} message={t('updated')} delay={2} />
      )}
      {showToastError && (
        <Toast top={false} zIndex={99} type={ToastType.Error} message={errorMessage} delay={2} />
      )}
      <>
        <Header
          title={<div className='bold'>{t('profile.title')}</div>}
          history={history}
          hasBackground={true}
          pathName={'/'}
        />
        <ContentApp>
          {!loading ? (
            <div className='w-100 container fade-up'>
              <div className='user-profile card'>
                <div className='associate-user-photo-profile mt-3 row'>
                  <input
                    type='file'
                    accept='image/*'
                    capture='user'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  {user.appProfilePicUrl ? (
                    <img

                      src={user.profileImage}

                      alt={user.displayName}
                      className={styles.associateUserImage}
                      onClick={() => setShowImageOptions(!showImageOptions)}
                    />
                  ) : (
                    <UserIcon
                      color='var(--color-light-gray)'
                      size='40px'
                      onClick={() => setShowImageOptions(!showImageOptions)}
                    />
                  )}
                </div>
                <ProductModal
                  animationClass='slide-left-right'
                  open={showImageOptions}
                  hasBackground={true}
                  onClose={() => setShowImageOptions(false)}
                >
                  {showImageOptions && (
                    <div className='d-flex flex-column p-5'>
                      <Button
                        onClick={() => fileInputRef.current.click()}
                        text={t('pictureOptions.selectFromGallery')}
                        size='medium'
                      />

                      <Button
                        onClick={() => {
                          fileInputRef.current.setAttribute('capture', 'user');
                          fileInputRef.current.click();
                        }}
                        text={t('pictureOptions.openCamera')}
                        size='medium'
                        className='mt-2'
                        backgroundColor='var(--color-medium-gray)'
                      />
                    </div>
                  )}
                </ProductModal>
                <div className='activity-feed '>
                  <ItemProfile title={t('profile.name')} subtitle={userInfo.fullName} />
                  <ItemProfile title='Email' subtitle={userInfo.email} />
                  <ItemProfile
                    title={t('profile.id')}
                    subtitle={userInfo.documentNumber ? userInfo.documentNumber : '-'}
                  />
                  <ItemProfile title={t('profile.nationality')} subtitle={userInfo.nationality} />
                  <ItemProfile
                    title={t('profile.phoneNumber')}
                    subtitle={<span className='text-secondary p-0'>{phoneNumber}</span>}
                  />
                  <ItemProfileEditable
                    title={t('profile.address')}
                    subtitle={
                      <input
                        type='text'
                        name='address'
                        placeholder={address || '-'}
                        value={address}
                        className='input-user-profile text-truncate text-secondary'
                        ref={addressInputRef}
                        onChange={(e) => setAddress(e.target.value)}
                        onBlur={(e) => {
                          if (e.relatedTarget !== addressInputRef.current) updateAddress(address);
                        }}
                        readOnly={!isAddressEditing}
                      />
                    }
                    isEditing={isAddressEditing}
                    setIsEditing={setIsAddressEditing}
                    onClick={() => {
                      updateAddress(address);
                    }}
                    inputRef={addressInputRef}
                  />
                  <ItemProfileEditable
                    title='RFC'
                    subtitle={
                      <input
                        type='text'
                        name='rfc'
                        placeholder={rfc || '-'}
                        value={rfc}
                        className='input-user-profile text-truncate text-secondary'
                        ref={rfcInputRef}
                        onChange={(e) => setRfc(e.target.value)}
                        onBlur={(e) => {
                          if (e.relatedTarget !== rfcInputRef.current) updateRfc(rfc);
                        }}
                        readOnly={!isRfcEditing}
                      />
                    }
                    isEditing={isRfcEditing}
                    setIsEditing={setIsRfcEditing}
                    onClick={() => updateRfc(rfc)}
                    className={user.userRp ? 'border-bottom border-secondary' : 'border-none'}
                    inputRef={rfcInputRef}
                  />
                </div>
              </div>
            </div>
          ) : (
            <SkeletonProfile />
          )}
        </ContentApp>
      </>
    </>
  );
};

export default UserProfile;
