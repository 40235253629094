export const resetReservation = () => {
  return {
    type: 'RESET_RESERVATION',
  };
};

export const setReservation = (data) => {
  return {
    type: 'RESERVATION_INFO',
    payload: {
      mktCampaign: data.mktCampaign,
      products: data.products,
      performance: data.performance,
      date: data.date,
      paxs: data.paxs,
      reservationType: data.reservationType,
    },
  };
};

export const setReservationType = (type) => {
  return {
    type: 'SET_RESERVATION_TYPE',
    payload: type,
  };
};

export const setExtraPax = (productInfo) => {
  return {
    type: 'RESERVATION_EXTRA_PAX',
    payload: productInfo,
  };
};

export const setOrderOption = (option) => {
  return {
    type: 'SET_ORDER_OPTION',
    payload: option,
  };
};

export const setDateRange = (dateRange) => {
  return {
    type: 'SET_DATE_RANGE',
    payload: dateRange,
  };
};
