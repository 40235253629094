import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GenerateQr.module.css';
import { download } from '../../../../helpers/file';
import DownloadIcon from '../../../../components/icons/DownloadIcon';

const GenerateQr = ({ data}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { t } = useTranslation();
  const handleLightMode = () => {
    setIsDarkMode(false);
  };
  const handleDarkMode = () => {
    setIsDarkMode(true);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className='qr-image mt-2'>
        <img
          style={{ width: '200px', height: '200px', padding: '20px' }}
          src={`https://api.qrserver.com/v1/create-qr-code/?size=256x256&bgcolor=${
            isDarkMode ? '000' : 'FFFFFF'
          }&color=${isDarkMode ? 'd9d9d9' : '000'}&format=png&data=${data.link}`}
          alt={isDarkMode ? 'QR Code Dark' : 'QR Code Light'}
        />
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        <button
          type='button'
          onClick={handleLightMode}
          style={{ fontSize: '12px' }}
          className={`${isDarkMode ? styles.custombuttonDark : styles.custombuttonUno} `}
        >
          {t('fondoWhite')}
        </button>
        <button
          type='button'
          onClick={handleDarkMode}
          style={{ fontSize: '12px' }}
          className={`${isDarkMode ? styles.custombuttonDarkDark2 : styles.custombuttonDos} `}
        >
          {t('fondoBlack')}
        </button>
      </div>
      <div style={{ paddingTop: '10px', color: '#1D9BF0' }}>
        <div
          className='text-center text-primary mt-2'
          onClick={() =>
            download(
              isDarkMode
                ? `https://api.qrserver.com/v1/create-qr-code/?size=256x256&bgcolor=000&color=d9d9d9&format=png&data=${data.link}`
                : `https://api.qrserver.com/v1/create-qr-code/?size=256x256&format=png&data=${data.link}`,
              isDarkMode ? 'qr-dark.png' : 'qr-light.png',
            )
          }
        >
          {t('download')}{' '}
          <DownloadIcon
            size={18}
            color={'var(--color-primary)'}
            styles={{ marginLeft: '.5rem', marginTop: '-2px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateQr;
