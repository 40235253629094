import React from 'react';

const ArrowIcon = ({ size = 24, color = '#000' }) => {
  return (
    <svg
      id='b'
      data-name='Capa_2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15.06 15.06'
      width={size}
      height={size}
      fill={color}
    >
      <g id='c' data-name='Capa_1'>
        <path d='M14.97.74c-.12-.29-.36-.53-.65-.65-.15-.06-.3-.09-.46-.09H1.45C.79,0,.25.54.25,1.2s.54,1.2,1.2,1.2h9.51L.35,13.01c-.47.47-.47,1.23,0,1.7.23.23.54.35.85.35s.61-.12.85-.35L12.66,4.1v9.51c0,.66.54,1.2,1.2,1.2s1.2-.54,1.2-1.2V1.2c0-.16-.03-.31-.09-.46Z' />
      </g>
    </svg>
  );
};

export default ArrowIcon;
