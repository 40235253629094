import React, { useState } from 'react';
import styles from './DigitalCard.module.css';
import ChevronIcon from '../../icons/chevronIcon';
import DigitalIcon from '../../icons/ConsumerIcons/DigitalCard';
import { useTranslation } from 'react-i18next';
const DigitalCard = ({ name, category }) => {
  const [activeButton, setActiveButton] = useState(null);
  const { t } = useTranslation();
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  return (
    <div className={`card p-3 ${styles.cardContainer}`}>
      <div className='row px-3'>
        <div>
          <div style={{ color: '#D9D9D9', fontSize: '20px', fontWeight: '400' }}>{name}</div>
          <div className={`flex-grow-1 ${styles.textSecondary} semi-bold`}>Socio {category}</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className={`flex-grow-1 ${styles.myCard} semi-bold`}>{t("consumer.goToMyCard")}</div>
            <div>
              <ChevronIcon size={23} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.redRectangle}>
        <div style={{ marginTop: '25px', marginBottom: '4px' }}>
          <DigitalIcon></DigitalIcon>
        </div>
        <div className={`flex-grow-1 ${styles.myCardDigital} semi-bold`}>{t("consumer.digitalCard")}</div>
      </div>
    </div>
  );
};

export default DigitalCard;
