import React from 'react';
import NotificactionScreen from '../../components/Common/NotificactionScreen/NotificactionScreen';
import ContentApp from '../../components/ContentApp';
import { NoInternetIcon } from '../../components/icons/NoInternetIcon';
import { useTranslation } from 'react-i18next';


const OfflineScreen = () => {
  const { t } = useTranslation();
  return (
    <ContentApp classes="no-scroll-complete">
      <NotificactionScreen>
        <div className='d-flex justify-content-center w-100'>
          <NoInternetIcon color='var(--color-font-primary)' size='100px' />
        </div>
        <div className='px-2 mt-5 pt-5'>
          <h3
            className='font-large bold text-left pl-3'
            style={{ color: 'var(--color-font-primary)' }}
          >
           {t('connection.WithoutConnection')}
          </h3>
          <p className='text-left pl-3 text-secondary'>
           {t('connection.noInternetConnection')} <br />
           {t('connection.makesurewifimobile')}
          </p>
        </div>
      </NotificactionScreen>
    </ContentApp>
  );
};

export default OfflineScreen;
