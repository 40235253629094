const initialProductsState = {
  mktCampaign: null,
  products: [],
  performance: null,
  date: null,
  paxs: null,
  orderOption: null,
  reservationType: null,
  dateRange: [
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ],
};

export default (state = initialProductsState, action) => {
  switch (action.type) {
    case 'RESET_RESERVATION':
      return initialProductsState;
    case 'SET_RESERVATION_TYPE':
      return {
        ...state,
        reservationType: action.payload,
      };
    case 'RESERVATION_INFO': {
      return {
        ...state,
        mktCampaign: action.payload.mktCampaign,
        products: action.payload.products,
        performance: action.payload.performance,
        date: action.payload.date,
        paxs: action.payload.paxs,
      };
    }
    case 'RESERVATION_EXTRA_PAX': {
      let newProducts = [...state.products];

      newProducts.forEach((item, i) => {
        newProducts[i].extraPaxs = 0;
      });

      action.payload.forEach((item) => {
        const index = newProducts.findIndex((p) => p.id === item.id);

        if (item.specialType === 'EXTRAPAX') newProducts[index].extraPaxs += item.quantity;
        else {
          if (index === -1 && item.quantity > 0) {
            newProducts.push({
              id: item.id,
              quantity: item.quantity,
              specialType: item.specialType,
            });
          } else if(index !== -1) {
            if (item.quantity === 0) newProducts.splice(index, 1);
            else newProducts[index].quantity = item.quantity;
          }
        }
      });

      return {
        ...state,
        products: newProducts,
      };
    }
    case 'SET_ORDER_OPTION': {
      return {
        ...state,
        orderOption: action.payload,
      };
    }
    case 'SET_DATE_RANGE': {
      return {
        ...state,
        dateRange: action.payload,
      };
    }
    default:
      return state;
  }
};
