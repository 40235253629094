import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Chart from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

const TimeLine = ({ title, subtitle, data, xAxis, yAxis, parentClass }) => {
  // Creating the JSON object to store the chart configurations
  const chartConfigs = {
    type: 'area2d',
    width: '100%', // Width of the chart
    height: '200', // Height of the chart
    dataFormat: 'json', // Data type
    dataEmptyMessage: 'No hay información para esas fechas.',

    dataSource: {
      chart: {
        bgColor: '#000000',
        baseFont: '"Inter", sans-serif',
        borderColor: '#ffffff',
        // Gradients

        plotFillAngle: '90',
        plotFillAlpha: '30',
        plotFillColor: '#1D9BF0',
        // Line (border top) color
        plotBorderColor: '#ffffff',
        plotBorderAlpha: '100',
        caption: title,
        baseFontSize: '12',
        labelDisplay: 'none',
        subCaption: subtitle,
        xAxisName: xAxis,
        xAxisNameFontSize: 12,
        showYAxisValues: 1,
        showLabels: 0,
        drawFullAreaBorder: 0,
        showPlotBorder: 0,
        plotBorderThickness: 1,
        theme: 'fusion',
        drawAnchors: 0,
        anchorBgColor: '#1D9BF0',
        anchorBorderColor: '#ffffff',
        plottooltext: '<b>$legend</b>',
      },
      data,
    },
  };
  return <ReactFC {...chartConfigs} />;
};

export default TimeLine;
