import React, { useState } from 'react';
import './input-form-border.css';
import '../../../css/icon-wellet.css';
import EditIcon from '../../icons/EditIcon';
import { EyeHideIcon } from '../../icons/EyeHideIcon';
import { EyeIcon } from '../../icons/EyeIcon';

const InputFormBorder = ({
  value,
  onChange,
  name,
  type,
  inputmode,
  error,
  icon,
  customClass = '',
  isDisabled = false,
  maxLength,
  classInput = '',
  label,
  placeholder,
  forceLowecase = false,
  dataCheckout,
  handleEdit,
  editInput = false,
  isTextArea = false,
}) => {
  const [forceToShow, setForceToShow] = useState(false);

  return (
    <div
      className={`field-border-container ${error ? 'error' : ''} ${
        isDisabled ? 'disabled-input' : ''
      }`}
    >
      <div className={`field-border ${customClass}`}>
        {icon && <i className={`icon-wellet icon-wellet-${icon}`} />}
        <div className={`${icon ? 'input-icon' : ''}`}>
          {isTextArea ? (
            <textarea
              name={name}
              type={forceToShow ? 'text' : type}
              inputMode={inputmode}
              value={forceLowecase ? value?.toLowerCase() : value}
              onChange={onChange}
              className={`text-area-styles ${classInput}`}
              disabled={isDisabled}
              maxLength={maxLength}
              placeholder={placeholder}
              data-checkout={dataCheckout}
            />
          ) : (
            <input
              name={name}
              type={forceToShow ? 'text' : type}
              inputMode={inputmode}
              value={forceLowecase ? value?.toLowerCase() : value}
              onChange={onChange}
              className={classInput}
              disabled={isDisabled}
              maxLength={maxLength}
              placeholder={placeholder}
              data-checkout={dataCheckout}
            />
          )}
          {type === 'password' ? (
            !forceToShow ? (
              <span onClick={() => setForceToShow(true)} className={'reveal'}>
                <EyeHideIcon color={'#fff'} size={'21px'} />
              </span>
            ) : (
              <span onClick={() => setForceToShow(false)} className={'reveal'}>
                <EyeIcon color={'#fff'} size={'21px'} />
              </span>
            )
          ) : null}
          {label && (
            <span
              className={`floatingPlaceholder ${classInput}`}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </div>
        {editInput && (
          <div style={{ position: 'absolute', right: '15px', bottom: '18px' }}>
            <EditIcon color='#D9D9D9' size='20px' handleClick={handleEdit} />
          </div>
        )}
      </div>
      {error && error.length > 0 && <div className='errorLabel'>{error}</div>}
    </div>
  );
};

export default InputFormBorder;
