import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircleSpinner } from 'react-spinners-kit';
import { format } from 'date-fns';
import { WelletContext } from '../../../context/WelletContext';
import { getLocale } from '../../../helpers/dates';
import Money from '../../../components/Common/Money/Money';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import { ExclamationIcon } from '../../../components/icons/ExclamationIcon';
import { useTranslation } from 'react-i18next';

const MyMoneyDetail = ({ history }) => {
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const welletContext = useContext(WelletContext);
  const [settlements, setSettlements] = useState(null);
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  const renderMoney = (money) => {
    return (
      <div className='pt-4 row'>
        <div className='col'>
          {format(new Date(money.availableFromUTC), 'd MMMM yyyy', {
            locale,
          })}
        </div>
        <div className='col-auto'>
          <Money value={money.amount} lowDecimals decimal={false} />
        </div>
      </div>
    );
  };

  const getUpcomingSettlements = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get('/wallet/getUpcomingSettlements/3/0');
      setSettlements(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUpcomingSettlements();
  }, []);

  return (
    <>
      <Header title={t('moneySettled')} hasBackground={true} showBack={true} />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <>
          <ContentApp>
            <div className='card p-3'>
              <div className='bold'>
                {t('moneyRelease')} <ExclamationIcon size={16} color={'#fff'} />
              </div>
              <div className='mt-3'>
                {loading ? (
                  <CircleSpinner size={20} color='var(--color-font-primary)' />
                ) : (
                  <Money value={settlements?.amount} fontSize='1.7rem' />
                )}
              </div>
            </div>

            {settlements?.upcomingSettlements?.length > 0 ? (
              <div className='card p-3 mt-2'>
                <div>{t('upcomingReleases')}</div>
                <div
                  className='mt-3'
                  style={{ border: '1px solid var(--color-medium-gray)' }}
                ></div>{' '}
                <>
                  {settlements?.upcomingSettlements?.map((p, index) => (
                    <div key={index}>{renderMoney(p)}</div>
                  ))}
                </>
              </div>
            ) : null}
            <div className='mt-2'> * {t('moneyReleaseDescription')}</div>
          </ContentApp>
        </>
      )}
    </>
  );
};

export default MyMoneyDetail;
