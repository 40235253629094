import React from 'react';

const VisaIcon = ({ fillColor = '#FFF', size = '60px', onClick }) => (
    <svg
        width={size}
        height={30}
        viewBox="0 0 57 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
    >
        <path
            d="M29.6899 6.07359C29.658 8.63158 31.9334 10.0591 33.6476 10.9078C35.4088 11.7787 36.0003 12.3371 35.9936 13.1157C35.9801 14.3076 34.5887 14.8336 33.2863 14.8541C31.0142 14.8899 29.6933 14.2308 28.6429 13.7322L27.8245 17.6238C28.8782 18.1173 30.8293 18.5476 32.8527 18.5664C37.6019 18.5664 40.7092 16.1843 40.726 12.4908C40.7445 7.80339 34.345 7.54383 34.3887 5.44861C34.4038 4.81338 35.0004 4.13546 36.3079 3.96299C36.9549 3.87591 38.7413 3.80931 40.7663 4.75703L41.5612 0.991766C40.4722 0.588772 39.0723 0.202854 37.3296 0.202854C32.8594 0.202854 29.7151 2.6174 29.6899 6.07359ZM49.1992 0.527297C48.3321 0.527297 47.601 1.04129 47.275 1.8302L40.4907 18.2898H45.2365L46.181 15.6379H51.9805L52.5284 18.2898H56.7112L53.0611 0.527297H49.1992ZM49.863 5.32566L51.2327 11.9956H47.4817L49.863 5.32566ZM23.9358 0.527297L20.1949 18.2898H24.7172L28.4564 0.527297H23.9358ZM17.2456 0.527297L12.5384 12.6171L10.6343 2.33736C10.4108 1.18985 9.52856 0.527297 8.54881 0.527297H0.853648L0.746094 1.04299C2.32579 1.39134 4.1206 1.95315 5.20791 2.55422C5.8734 2.92136 6.0633 3.24238 6.28177 4.11497L9.88819 18.2898H14.6676L21.9948 0.527297H17.2456Z"
            fill={fillColor}
        />
    </svg>
);

export default VisaIcon;
