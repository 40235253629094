import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Chart from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

const Pie2D = ({ title, subtitle, data, parentClass, legendPosition, pieRadius }) => {
  // Creating the JSON object to store the chart configurations
  const chartConfigs = {
    type: 'doughnut2d', // The chart type
    width: '100%', // Width of the chart
    height: '250', // Height of the chart
    dataFormat: 'json', // Data type
    dataEmptyMessage: 'No hay información para esas fechas.',

    dataSource: {
      // Chart Configuration
      chart: {
        bgAlpha: '100',
        bgColor: '#131313',
        pieRadius: '50',
        showLabels: 0,
        showLegend: 1,
        showValues: 0,
        legendPosition: 'bottom-left',
        legendBorderAlpha: 0,
        baseFont: '"Inter", sans-serif',
        labelFont: '"Inter", sans-serif',
        labelFontSize: '12px',
        legendItemFont: '"Inter", sans-serif',
        legendItemFontSize: 12,
        //Set the chart caption
        caption: title,
        subtitle: subtitle,
        theme: 'fusion',
        doughnutRadius: '70%',
        palettecolors: '#1D9BF0, #1B80C4, #196598, #17496B, #152E3F, #000000',
      },
      // Chart Data
      data,
    },
  };

  return <ReactFC {...chartConfigs} />;
};

export default Pie2D;
