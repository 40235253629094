import React, { useEffect, useRef, useState } from 'react';
import ProductModal from '../../../../components/Common/ProductModal/ProductModal';
import Header from '../../../../components/Common/Header/Header';
import styles from './ShareCampaign.module.css';
import { useTranslation } from 'react-i18next';
import { Toast } from '../../../../components/Common/Toast/Toast';
import step1Android from '../../../../images/step1-android.png';
import step2Android from '../../../../images/step2-android.png';
import step1 from '../../../../images/step1.png';
import step2 from '../../../../images/step2.png';
import step3 from '../../../../images/step3.png';
import step4 from '../../../../images/step4.png';
import ContentApp from '../../../../components/ContentApp';
import InstagramIcon from '../../../../components/icons/InstagramIcon';
import StickerIcon from '../../../../components/icons/StickerIcon';
import LinkInstagramIcon from '../../../../components/icons/LinkInstagramIcon';
import { getDevice } from '../../../../helpers/device';
import { CircleSpinner } from 'react-spinners-kit';
import Button from '../../../../components/Common/Button/Button';
import CustomerNav from '../../../../components/CustomerNav/CustomerNav';
import { getLocalStorage, setLocalStorage } from '../../../../helpers/verificationLocalStorage';
import QuestionMark from '../../../../components/icons/QuestionMark';
import { setCanRefresh } from '../../../../actions/appActions';
import { useDispatch } from 'react-redux';
import ChevronIcon from '../../../../components/icons/chevronIcon';

const ShareCampaign = ({ history }) => {
  const { t } = useTranslation();
  const campaign = history?.location?.state?.campaign;
  const videoModalRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [downloading, setDownloading] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const isIOS = getDevice() === 'iOS';
  const dispatch = useDispatch();
  const stepsCompletedCampaign = getLocalStorage('stepsCompletedCampaign');
  const [objectFit, setObjectFit] = useState('');

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const videoElement = videoModalRef.current;

    const handleLoadedMetadata = () => {
      const { videoWidth, videoHeight } = videoElement;

      // Si el video es más alto que ancho, aplicar object-fit: cover
      if (videoHeight > videoWidth) {
        setObjectFit('cover');
      }
    };

    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (window.webkit) {
      window.addEventListener('media-download-finished', (event) => {
        setDownloading(false);
      });
    }
  }, []);

  useEffect(() => {
    dispatch(setCanRefresh(false));
    if (!campaign) {
      history.push('/campaigns-feed');
      return;
    }
  }, []);

  const steps = [
    !isIOS && {
      img: step1Android,
      title: t('shareCampaigns.clickMore'),
    },
    !isIOS && {
      img: step2Android,
      title: t('shareCampaigns.selectStories'),
    },
    {
      img: step1,
      title: (
        <div>
          {t('shareCampaigns.clickOnThe')} <StickerIcon size={20} />
        </div>
      ),
    },
    {
      img: step2,
      title: (
        <div>
          {t('shareCampaigns.clickOnTheStiker')} <LinkInstagramIcon />
        </div>
      ),
    },
    {
      img: step3,
      title: t('shareCampaigns.pasteTheLink'),
    },
    {
      img: step4,
      title: t('shareCampaigns.frameYourLink'),
    },
  ].filter(Boolean);

  const handleClickShare = (mediaUrl, type, link) => {
    setDownloading(true);
    if (window.webkit) {
      const request = { mediaUrl: mediaUrl, mediaType: type, link: link };

      try {
        window.webkit.messageHandlers.welletShare.postMessage(request);
      } catch (e) {
        setDownloading(false);
        console.error(e);
      }
    }
  };

  const handleVideoClick = () => {
    if (showSteps) {
      return;
    }

    if (videoModalRef.current) {
      if (isPlaying) {
        videoModalRef.current.pause();
      } else {
        videoModalRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleCloseShare = () => {
    setOpenShareModal(false);
  };

  const handleOpenModal = () => {
    if (stepsCompletedCampaign) {
      setDownloading(true);
      handleShare(campaign?.media[0]?.mediaUrl, campaign?.media[0]?.mediaType, campaign.link);
      return;
    }
    setOpenShareModal(true);
  };

  const normalizeFileName = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };

  const handleShare = async (mediaUrl, mediaType, link) => {
    if (isIOS) {
      handleClickShare(mediaUrl, mediaType, link);
    } else {
      try {
        const response = await fetch(mediaUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const fileExtension = mediaUrl.includes('.mp4')
          ? '.mp4'
          : mediaUrl.includes('.webm')
          ? '.webm'
          : '.png';
        const filesArray = [
          new File([blob], `${normalizeFileName(campaign.campaignName)}${fileExtension}`, {
            type: blob.type,
          }),
        ];

        await navigator.share({
          title: campaign.campaignName,
          url: link,
          files: filesArray,
        });
      } catch (error) {
        setDownloading(false);
      }
    }
    setLocalStorage('stepsCompletedCampaign', 'true');
  };

  return (
    <div className={styles.containerShare}>
      <Header title='Reels' absolute />
      <div className={styles.mediaContainer} onClick={showSteps ? () => setShowSteps(false) : null}>
        {campaign?.media[0]?.mediaUrl?.includes('mp4') ? (
          <video
            autoPlay
            src={campaign?.media[0]?.mediaUrl}
            loop
            className={styles.videoModal}
            onClick={(e) => handleVideoClick(e)}
            ref={videoModalRef}
            webkit-playsinline='true'
            x5-playsinline='true'
            playsInline
            style={{ objectFit }}
          />
        ) : (
          <img src={campaign?.media[0]?.mediaUrl} className={styles.imgModal} alt='logo' />
        )}
      </div>
      <div
        className={`${styles.containerSteps} ${showSteps ? styles.show : styles.hide}`}
        style={{ display: !showSteps && 'none' }}
      >
        <div className='text-center text-white mt-2'>{campaign?.campaignName}</div>
        <div className={`m-3 px-3 py-4 ${styles.stepCard}`}>
          <div className='bold'>{t('shareCampaigns.step1')}</div>
          <div className='font-smaller bold text-secondary my-2'>{campaign?.link}</div>
          <Button
            className='btn-primary'
            onClick={() => onCopy(campaign?.link)}
            text={t('shareCampaigns.copyLink')}
            size='medium'
            width={'50%'}
          />

          {copied && <Toast top={false} type={'success'} message={t('linkCopied')} delay={3} />}
        </div>
        <div className={`mx-3 p-3 ${styles.stepCard}`}>
          <span className='mb-3 px-3 bold'>{t('shareCampaigns.step2')}</span>
          <Button
            className='btn-primary'
            width={'50%'}
            size='medium'
            onClick={handleOpenModal}
            text={
              downloading ? (
                <div className='d-flex justify-content-center'>
                  <CircleSpinner
                    className=''
                    style={{ margin: 'auto' }}
                    size={23}
                    color='var(--color-font-primary)'
                  />
                </div>
              ) : (
                t('shareCampaigns.share')
              )
            }
          />
        </div>
        <div className='text-center text-secondary' onClick={() => setOpenShareModal(true)}>
          <QuestionMark size={20} color='#7e7e7e' />
          <span className='ml-2'>{t('howToShare')}</span>
        </div>
      </div>
      <div
        style={{
          display: showSteps ? 'none' : 'block',
        }}
        onClick={() => setShowSteps(true)}
        className={styles.shareText}
      >
        <ChevronIcon size={25} rotation={270} />
        <br />
        {t('shareOnYourStory')} <br /> {t('of')} <span className='fw-semibold'>Instagram</span>
      </div>
      <CustomerNav history={history} />

      <ProductModal
        animationClass='fade-up-down'
        open={openShareModal}
        hasBackground
        onClose={() => handleCloseShare()}
        showBack={false}
        showClose
        backIcon
        customStyle={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <ContentApp classes='pt-0'>
          <div className='d-flex justify-content-center'>
            <div className={styles.iconButton}>
              <InstagramIcon size={20} color='#000' />
            </div>
          </div>
          <div className={styles.cardShareCampaigns}>
            <div className='mt-2 px-3 pt-3'>{t('shareCampaigns.howToAdd')}</div>
            <div className={styles.stepsButtons}>
              {steps.map((step, idx) => (
                <button
                  key={idx}
                  style={{
                    backgroundColor: activeStep === idx + 1 ? '#000' : '',
                    color: activeStep === idx + 1 ? '#fff' : '#000',
                  }}
                  onClick={() => setActiveStep(idx + 1)}
                >
                  {idx + 1}
                </button>
              ))}
            </div>
            {steps.map((step, idx) => (
              <div key={idx} style={{ display: idx + 1 === activeStep ? 'block' : 'none' }}>
                <div className='my-3'>{step.title}</div>
                <img className={styles.imgStep} src={step.img} alt={`Step ${idx + 1}`} />
              </div>
            ))}
            {(activeStep === 4 && isIOS) || (activeStep === 6 && !isIOS) ? (
              <>
                <button
                  className={styles.nextStepButton}
                  onClick={() =>
                    handleShare(
                      campaign?.media[0]?.mediaUrl,
                      campaign?.media[0]?.mediaType,
                      campaign.link,
                    )
                  }
                >
                  {downloading ? (
                    <div className='d-flex justify-content-center'>
                      <CircleSpinner
                        className=''
                        style={{ margin: 'auto' }}
                        size={23}
                        color='var(--color-font-primary)'
                      />
                    </div>
                  ) : (
                    t('shareCampaigns.shareInstagram')
                  )}
                </button>
              </>
            ) : (
              <button
                className={styles.nextStepButton}
                onClick={() => setActiveStep(activeStep + 1)}
              >
                {t('shareCampaigns.nextStep')}
              </button>
            )}
          </div>
        </ContentApp>
      </ProductModal>
    </div>
  );
};

export default ShareCampaign;
