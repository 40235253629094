import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { getDateFromOffset } from '../../../helpers/dates';
import { WelletContext } from '../../../context/WelletContext';
import { skeletonLoading } from '../../../components/Skeletons/SkeletonAccount';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import Balance from '../../../components/Balance/Balance';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';

import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import Button from '../../../components/Common/Button/Button';
import WarningBox from '../../../components/Common/WarningBox/WarningBox';
import ActivityReservationItem from '../../../components/Activities/ActivityReservationItem.js/ActivityReservationItem';
import useRealtimeOrders from '../../../hooks/useRealtimeOrders';
import { CircleSpinner } from 'react-spinners-kit';
import AccountFilterIcon from '../../../components/icons/AccountFilterIcon';

import BalanceAmount from '../../../components/Balance/BalanceAmount/BalanceAmount';
import BalanceConsumer from '../../../components/Consumer/Balance/BalanceConsumer';
import DigitalCard from '../../../components/Consumer/DigitalCard/DigitalCard';
import Ticket from '../../../components/Consumer/Tickets/Tickets';
import NavSelector from '../../../components/NavSelector/NavSelector';

const Home = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const jwt = useSelector((state) => state.associates.jwt);
  const user = useSelector((state) => state.associates.user);
  const userWithdrawalMethod = user?.userWithdrawalMethod;
  const today = getDateFromOffset(user.companyUtcMinutesOffset);
  const todayFilter = format(today, 'yyyy-MM-dd');
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [filterDate, setFilterDate] = useState(localStorage.getItem('filterDate') ?? 'ORDER_DATE');

  const [myEarningsToday, setMyEarningsToday] = useState(null);
  const [monthEarning, setMonthEarning] = useState(0);
  const kycStatus = useSelector((state) => state.associates.user.kycStatus);
  const advantageNetworkStatus = useSelector(
    (state) => state?.associates?.user?.advantageNetworkStatus,
  );
  const [selectedOption, setSelectedOption] = useState(filterDate);
  const app = useSelector((state) => state.app);

  const wireMethod =
    user.withdrawalMethod === 'WIRE_INTERNATIONAL' || user.withdrawalMethod === 'WIRE_MEXICO';
  const cashOrCertificate =
    user.withdrawalMethod === 'CASH' || user.withdrawalMethod === 'CERTIFICATE';

  const getMyEarningsToday = async () => {
    setLoadingBalance(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${todayFilter}&to=${todayFilter}`,
      );
      setMyEarningsToday(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBalance(false);
    }
  };

  const VoucherList = () => {
    history.push('/voucherList');
  };

  const getEarnings = async (fromDate, toDate) => {
    setLoadingBalance(true);
    try {
      const balance = await welletContext.apis.tickets.get(
        `/associates/GetEarnings?from=${fromDate}&to=${toDate}`,
      );
      setMonthEarning(balance.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingBalance(false);
    }
  };

  const handleValidReservationUpdate = useCallback(() => {
    getMyEarningsToday();

    const date = getDateFromOffset(user.companyUtcMinutesOffset);
    const firstMonthDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const lastMonthDate = format(endOfMonth(date), 'yyyy-MM-dd');

    getEarnings(firstMonthDate, lastMonthDate);
  }, [getMyEarningsToday, getEarnings]);

  const { orders, loading, hasMore, loadingMore, fetchMoreOrders } = useRealtimeOrders(
    today,
    filterDate,
    handleValidReservationUpdate,
  );

  useEffect(() => {
    getMyEarningsToday();
    const date = getDateFromOffset(user.companyUtcMinutesOffset);
    const firstMonthDate = format(startOfMonth(date), 'yyyy-MM-dd');
    const lastMonthDate = format(endOfMonth(date), 'yyyy-MM-dd');

    getEarnings(firstMonthDate, lastMonthDate);
  }, [jwt]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedFilterDate = localStorage.getItem('filterDate');
    if (storedFilterDate) {
      setFilterDate(storedFilterDate);
    }
  }, []);

  const isEmptyActivity = orders?.length === 0 || undefined;

  useEffect(() => {
    if (!localStorage.getItem('filterDate')) localStorage.setItem('filterDate', 'ORDER_DATE');
  }, []);

  const handleSelectChange = (selectedOption) => {
    localStorage.setItem('filterDate', selectedOption);
    setFilterDate(selectedOption);
    setSelectedOption(selectedOption);
  };

  const renderWithdrawalMethodWarningBox = () => {
    if (
      (kycStatus !== 'APPROVED' || advantageNetworkStatus !== 'APPROVED') &&
      !userWithdrawalMethod
    ) {
      return null;
    }

    if (userWithdrawalMethod) {
      return null;
    }

    if (cashOrCertificate) {
      return null;
    }

    let title = '';
    let subtitle = '';
    let nextUrl = '';

    if (user.withdrawalMethod === 'STRIPE') {
      title = t('noAccountAssociated.STRIPE.title');
      subtitle = t('noAccountAssociated.STRIPE.subtitle');
      nextUrl = '/stripeaccount';
    } else if (user.withdrawalMethod === 'MERCADOPAGO') {
      title = t('noAccountAssociated.mercadoPago.title');
      subtitle = t('noAccountAssociated.mercadoPago.subtitle');
      nextUrl = '/mercadopagoaccount';
    } else if (wireMethod) {
      title = t('noAccountAssociated.WIRE.title');
      subtitle = t('noAccountAssociated.WIRE.subtitle');
      if (user.withdrawalMethod === 'WIRE_INTERNATIONAL') {
        nextUrl = '/international-bank';
      } else if (user.withdrawalMethod === 'WIRE_MEXICO') {
        nextUrl = '/national-bank';
      }
    }

    return (
      <WarningBox
        title={title}
        subtitle={
          <div>
            {subtitle}
            <span style={{ color: 'var(--color-primary)', textDecoration: 'underline' }}>
              {t('here')}
            </span>
            .
          </div>
        }
        onClick={() => history.push(nextUrl)}
      />
    );
  };
  return (
    <div>
      <AccountHeader icon={'config'} pathName='/configuration' />
      <ContentApp menu header>
        <div className='mb-2'>
          {advantageNetworkStatus !== 'APPROVED'
            ? null
            : // <WarningBox
              //   title={t('identityPendingSubmission.title')}
              //   subtitle={t('identityPendingSubmission.subtitle')}
              // />
              (kycStatus !== 'PENDING' || kycStatus !== 'REJECTED') && (
                <WarningBox
                  title={t('identityNotValidated.title')}
                  subtitle={t('identityNotValidated.subtitle')}
                  textLink={t('identityNotValidated.textLink')}
                  onClickText={() => history.push('/kyc-start')}
                />
              )}
          <div className='mt-2'>
            <BalanceConsumer points={user.salesCategory.points}></BalanceConsumer>
          </div>
          <div className='mt-2'>
            <DigitalCard name={user.name} category={user.salesCategory.category}></DigitalCard>
          </div>

          <div className='mt-3'>
            <div className='text-lighter-gray font-small '>
              <div className='row mt-5'>
                <div style={{ fontSize: '16px', color: '#FFF' }} className='col pr-0  bold'>
                  Get rewarded
                </div>
                <div className='col-auto'>
                  <span
                    style={{ fontSize: '14px', fontWeight: '400', color: '#FFF', position: 'end' }}
                    className='text-secondary'
                    onClick={VoucherList}
                  >
                    See All
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowX: 'auto' }}>
            <div
              className='mt-2'
              style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5em' }}
            >
              <Ticket />
              <Ticket />
              <Ticket />
              <Ticket />
              <Ticket />
              <Ticket />
              <Ticket />
              <Ticket />
            </div>
          </div>
        </div>
        <NavSelector history={history} />
      </ContentApp>
    </div>
  );
};

export default Home;
