import React from 'react';
import styles from './DetailsVoucher.module.css';
import fondoDetailsVoucher from '../../../images/fondo-details-voucher.png';
import HeaderNoBackground from '../../../components/Common/Header/HeaderNoBackground';
import Button from '../../../components/Common/Button/Button';

const DetailsVoucher = () => {
  return (
    <>
      <HeaderNoBackground showBack={true} />
      <div className={styles.fullscreenContainer}>
        <img src={fondoDetailsVoucher} alt='Descripción de la imagen' className={styles.backgroundImage} />
        
        <div className={styles.textContainer}>
          <div className={styles.buttonContainer}>
            <Button
              size='Voucher'
              text='Claim now'
              color='#FFF'
              backgroundColor='var(--color-primary)'
              onClick={() => console.log('Button clicked')}
            />
          </div>
          <div className={styles.title}>
            Caramel Frappuccino Large
          </div>
          <div className={styles.dates}>Ends on Dec 2023</div>
          <div className={styles.description}>
            Buttery caramel syrup meets coffee, milk and ice for a rendezvous in the blender. Then whipped cream and caramel sauce layer the love on top.
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsVoucher;
