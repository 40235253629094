import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import SuccessCheck from '../../../../components/icons/SuccessCheck';
import LinkIcon from '../../../../components/icons/LinkIcon';
import ContentApp from '../../../../components/ContentApp';
import CopyIcon from '../../../../components/icons/copyIcon';
import Line from '../../../../components/Common/Line/Line';
import DownloadIcon from '../../../../components/icons/DownloadIcon';
import Accordion from '../../../../components/Common/Accordion/Accordion';
import Button from '../../../../components/Common/Button/Button';
import { getQueryString } from '../../../../helpers/url';
import { WelletContext } from '../../../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import { CircleSpinner } from 'react-spinners-kit';
import { download } from '../../../../helpers/file';
import styles from './SuccessCampaign.module.css';
import { fetchLink } from '../../../../services/campaigns/campaignsService';
import { useSelector } from 'react-redux';
import PlayIcon from '../../../../components/icons/PlayIcon';
import GenerateQr from '../GenerateQr/GenerateQr';

const SuccessCampaign = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const id = getQueryString('id');
  const name = getQueryString('name');
  const type = getQueryString('type');
  const [data, setData] = useState(null);
  const [copied, setCopied] = useState(null);
  const [loading, setLoading] = useState(true);
  const language = useSelector((state) => state.app.language);
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad|crios|fxios/.test(userAgent);

  const getLink = async () => {
    setLoading(true);
    try {
      const response = await fetchLink(welletContext, id, language);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    getLink();
  }, []);

  const normalizeFileName = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };
  
  const renderDownloadButton = (url, fileName) => {
    const isVideo = url.includes('.mp4');
    const fileExtension = isVideo ? 'mp4' : 'jpg';
    const fullFileName = `${fileName}.${fileExtension}`;
    const downloadUrl = ios
      ? `https://wr-scope.wellet.dev/#descargar?url=${url}&filename=${fullFileName}`
      : null;

    return (
      <div className='text-primary mt-2 text-decoration-none'>
        {downloadUrl ? (
          <a href={downloadUrl} target='_blank' rel='noopener noreferrer'>
            {t('download')}{' '}
            <DownloadIcon
              size={18}
              color={'var(--color-primary)'}
              style={{ marginLeft: '.5rem', marginTop: '-2px' }}
            />
          </a>
        ) : (
          <div onClick={() => download(url, fullFileName)}>
            {t('download')}{' '}
            <DownloadIcon
              size={18}
              color={'var(--color-primary)'}
              style={{ marginLeft: '.5rem', marginTop: '-2px' }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <div className='loading-app'>
          <div className='loaderDiv'>
            <CircleSpinner
              className='spinner-app mx-auto'
              style={{ margin: 'auto' }}
              size={40}
              color='var(--color-font-primary)'
            />
          </div>
        </div>
      ) : (
        <>
          <Header title='Nueva campaña creada' showBack={false} />
          <ContentApp>
            <div className='text-center mt-4'>
              <SuccessCheck color='var(--color-primary)' size={70} />
              <h2 className='semi-bold'>{t('Congratulations')}</h2>
              <div className='text-secondary mt-3'>
                {t('theCmapaign')}
                <span className='text-secondary bold'>{data?.campaignName}</span>
                {t('createdSuccessfully')}
              </div>
            </div>

            {type === 'QR_DIGITAL' || type === 'QR_PRINTED' ? (
              <GenerateQr type={name} data={data}></GenerateQr>
            ) : (
              <>
                <div className='bold text-secondary mt-4 d-flex align-items-center'>
                  <LinkIcon color='var(--color-deep-gray)' size={17} />
                  <div className='ml-2'>{t('link')}</div>
                </div>
                <div
                  className='p-2 mt-2 text-truncate font-medium bold d-flex align-items-center justify-space-between'
                  style={{
                    backgroundColor: 'var(--color-primary)',
                    color: '#000',
                    borderRadius: '10px',
                  }}
                >
                  <div className='text-truncate col'>{data.link}</div>
                  <div className='col-auto pl-0' onClick={() => onCopy(data.link)}>
                    <CopyIcon fillColor='#000' size='25' />
                  </div>
                  {copied && (
                    <div className='text-center mt-1'>
                      <div className='alert alert-info copy-link mb-1' role='alert'>
                        {t('copied')}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className='card p-3 mt-3'>
              <div className='bold'>{t('Multimedia')}</div>
              <Line className='mt-2' color='#212121' />
              <div className='row mt-3 align-items-center fw-semibold'>
                {data?.media?.map((d, index) => (
                  <div key={index} className='col-12 mb-3'>
                    <div className='row align-items-center'>
                      <div className='col-auto'>
                        {d.mediaUrl.includes('.mp4') ? (
                          <div
                            className={`video-item ${styles.selectedVideo} position-relative`}
                            controls='controls'
                          >
                            <video preload='metadata'>
                              <source src={d.mediaUrl + '#t=0.1'} type='video/mp4' />
                            </video>
                            {d.mediaUrl.includes('.mp4') && (
                              <div className={styles.playButton}>
                                <PlayIcon size='45px' color='#d3cfcc' />
                              </div>
                            )}
                          </div>
                        ) : (
                          <img className={styles.selectedMedia} src={d.mediaUrl} alt='media' />
                        )}
                      </div>
                      <div className='col'>
                        <div className='font-medium'>{d.title}</div>
                        {renderDownloadButton(d.mediaUrl, normalizeFileName(data.campaignName))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='card mt-2'>
              {data.instructions.map((p, index) => (
                <Accordion
                  key={index}
                  title={p.title}
                  content={p.description}
                  videoUrl={p.videoUrl}
                />
              ))}
            </div>
            <div className='d-flex mt-2'>
              <Button
                size='large'
                text='Mis campañas'
                className='col-6 text-secondary '
                backgroundColor='#000'
                hasBorder
                color=''
                onClick={() => history.push('/my-campaigns')}
              />
              <Button
                size='large'
                text='Ver detalle'
                className='col-6 ml-1'
                icon
                typeIcon='arrow'
                iconColor='#000'
                onClick={() => history.push(`/my-campaigns/campaign-detail?id=${id}`)}
              />
            </div>
          </ContentApp>
        </>
      )}
    </div>
  );
};

export default SuccessCampaign;
