import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { format } from 'date-fns';
import { convertTime12to24, getDatePerformance, getLocale } from '../../../helpers/dates';
import { useTranslation } from 'react-i18next';
import useForm from '../../../helpers/useForm';
import ContentApp from '../../../components/ContentApp';
import ButtonProccessing from '../../../components/Common/ButtonProccessing/ButtonProccessing';
import OccasionSelector from '../../../components/ReservationCheck/OccasionSelector/OccasionSelector';
import ReservationCheckCustomerInfo from '../../../components/ReservationCheck/ReservationCheckCustomerInfo/ReservationCheckCustomerInfo';
import CustomerSpecialRequest from '../../../components/ReservationCheck/CustomerSpecialRequest/CustomerSpecialRequest';
import Header from '../../../components/Common/Header/Header';
import ErrorBox from '../../../components/Common/ErrorBox/ErrorBox';
import styles from './reservationCheck.module.css';
import Summary from '../../../components/ReservationCheck/Summary/Summary';
import SummaryProducts from '../../../components/ReservationCheck/Summary/SummaryProducts';
import SummaryBox from '../../../components/ReservationCheck/Summary/SummaryBox';
import ProductPerformanceSelector from '../../../components/ProductPerformanceSelector/ProductPerformanceSelector';
import { getQueryString } from '../../../helpers/url';
import Money from '../../../components/Common/Money/Money';
import '../../../components/ReservationDetail/reservation-details.css';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import InputFormBorder from '../../../components/Common/InputFormBorder/InputFormBorder';
import { putReference } from '../../../services/reservations/reservationsService';

const ReservationCheck = ({ history }) => {
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const refReservationId = getQueryString('refReservationId');
  const currency = useSelector((state) => state.currency.selected);

  const reservation = useSelector((state) => state.reservation);
  const hasProducts = reservation.products?.length > 0;
  const [paxs, setPaxs] = useState(reservation.paxs);

  const language = useSelector((state) => state.app.language);
  const locale = getLocale(language);
  const [selectedButton, setSelectedButton] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const [reservationInfo, setReservationInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reservationDate, setReservationDate] = useState(null);

  //const performanceObj = performance && parse(performance, 'HH:mm', new Date());
  //const shouldAddDay = performanceObj && performanceObj.getHours() >= 0 && performanceObj.getHours() <= 3;

  //Areas vip
  const [intervals, setIntervals] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(true);
  const [selectedPerformance, setSelectedPerformance] = useState(null);

  const handleSelectOccasion = (value) => {
    if (selectedButton === value) {
      setSelectedButton(null);
      handleChange(undefined, 'occasion', null);
    } else {
      handleChange(undefined, 'occasion', value);
      setSelectedButton(value);
    }
  };

  function validate(values) {
    let errors = {};

    if (!values.customerName) {
      errors.customerName = t('nameError.EMPTY_NAME');
    } else if (/\d/.test(values.customerName)) {
      errors.customerName = t('nameError.NOT_NUMBERS');
    } else if (values.customerName.length < 3) {
      errors.customerName = t('nameError.MORE_LETTERS');
    }

    if (!values.phone) {
      errors.phone = t('phoneError.EMPTY_PHONE_NUMBER');
      setPhoneError(errors.phone);
    } else {
      setPhoneError('');
    }

    if (values.phone && !isValidPhoneNumber(values.phone)) {
      errors.phone = t('phoneError.INVALID_PHONE_NUMBER');
      setPhoneError(errors.phone);
    }

    if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = t('emailError.INVALID_EMAIL');
    }

    if (!reservation.performance && !selectedPerformance) {
      errors.performance = 'Seleccione el horario de llegada';
    }

    return errors;
  }

  const sendForm = async () => {
    setBtnStatus(ButtonStatus.InProgress);
    let reservationLocalTime = '';

    if (selectedPerformance != null)
      reservationLocalTime = reservation.date + 'T' + selectedPerformance;
    else reservationLocalTime = getDatePerformance(reservation.date, reservation.performance);

    try {
      // let url = '/reservation/GenerateReservation';
      // if (reservationInfo && reservationInfo.parentReservationReferenceCode) {
      //   url = '/reservation/modifyreservation';
      // }

      const body = {
        mktCampaignId: reservation.mktCampaign.id,
        paxs: paxs,
        customerName: values.customerName,
        customerPhone: values.phone,
        customerLanguage: values.language,
        email: values.email ?? '',
        comments: values.comments ?? '',
        occasion: selectedButton ?? null,
        countryCode: values.countryCode ?? 'MX',
        reservationDateLocalTime: reservationLocalTime,
        totalAmount: reservationInfo?.totalAmount ?? null,
        products: reservation.products ?? null,
        oldReservationId: refReservationId,
        eventName: values.eventName,
      };

      const result = await putReference(welletContext, reservation.mktCampaign, body);

      setBtnStatus(ButtonStatus.ProgressFinished);
      setTimeout(() => {
        history.push(`/reservation-success?reservationId=${result.reservationId}`);
      }, 1500);
    } catch (error) {
      setBtnStatus(ButtonStatus.Error);
      let errorMessage;
      switch (error.response.data.errorType) {
        case 'GENERIC_ERROR':
          errorMessage = t('reservationError.GENERIC_ERROR');
          break;
        case 'SHOW_NOT_FOUND':
          errorMessage = t('reservationError.SHOW_NOT_FOUND');
          break;
        case 'SCHEDULE_SOLD_OUT':
          errorMessage = t('reservationError.SCHEDULE_SOLD_OUT');
          break;
        case 'INVALID_RESERVATION_DATE':
          errorMessage = t('reservationError.INVALID_RESERVATION_DATE');
          break;
        default:
          errorMessage = t('reservationError.GENERIC_ERROR');
      }
      setError(errorMessage);
    }
  };

  let { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
  //const alignedDate = date && alignTimezone(date);
  //const adjustedDate = shouldAddDay ? addDays(alignedDate, 1) : alignedDate;

  const getReservation = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.post('/reservation/preview', {
        showId: reservation.showId,
        reservationDateLocalTime: reservation.date,
        products: reservation.products,
        language: language,
        oldReservationId: refReservationId,
      });

      if (response.status === 200) {
        setReservationInfo(response.data);
        setInitialValues(response.data);
        setPaxs(response.data.paxs);
        setReservationDate(new Date(reservation.date + 'T00:00:00'));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getSchedule = async (date) => {
    setLoadingSchedule(true);
    try {
      const result = await welletContext.apis.tickets.get(
        `/mktcampaign/${reservation.mktCampaign.id}/schedule?date=${date}`,
      );

      if (result.data.length === 0) {
        setIntervals([]);
      }

      const formattedTimes = result.data?.map((time) => {
        const time24 = new Date(reservation.date + 'T' + convertTime12to24(time.timeStart));

        if (time.timeMinutesStart >= 0 && time.timeMinutesStart <= 360)
          time24.setDate(time24.getDate() + 1);

        return { time24, soldOut: time.isSoldOut };
      });
      formattedTimes.sort();

      const allIntervals = formattedTimes.map(({ time24, soldOut }) => ({
        time: time24,
        soldOut,
      }));

      setIntervals(allIntervals);
      setLoadingSchedule(false);
    } catch (error) {
      console.error(error);
    }
  };

  const setInitialValues = (reservation) => {
    handleChange(null, 'customerName', reservation.customerName);
    handleChange(null, 'phone', reservation.customerPhone);
  };

  useEffect(() => {
    handleChange(null, 'language', language);

    if (reservation.date && reservation.performance) {
      setReservationDate(new Date(reservation.date + 'T' + reservation.performance));
    }

    if (hasProducts) {
      getReservation();
    }
  }, []);

  useEffect(() => {
    if (!selectedPerformance) getSchedule(reservation.date);
  }, []);

  return (hasProducts && loading) || reservationDate === null ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header title={<div className='text-capitalize'>{reservation.mktCampaign.name}</div>} />
      <ContentApp>
        <div className={`order-details ${styles.reservation}`}>
          <div className='mt-3 bold'>
            <div className='show-name'>{reservation.showName}</div>
            <div className='bold text-lighter-gray'>
              <div className='mt-1'>
                {reservationInfo?.kidsPaxs > 0 ? (
                  <>
                    {t('adult', { count: reservationInfo?.paxs - reservationInfo?.kidsPaxs })} +{' '}
                    {t('kid', { count: reservationInfo?.kidsPaxs })}
                  </>
                ) : (
                  t('person', { count: paxs })
                )}
              </div>
              <div className='mt-1 sentences-capitalize'>
                {locale.code === 'es'
                  ? format(reservationDate, "EEEE, d 'de' MMMM", { locale })
                  : format(reservationDate, 'EEEE, d MMMM', { locale })}
              </div>
              {reservation.performance ? (
                <div className='mt-1'>{reservation.performance} hr</div>
              ) : null}
            </div>
          </div>
          {reservationInfo ? (
            <>
              <div className={styles.paymentSection}>
                <SummaryProducts reservationInfo={reservationInfo} />
                {reservationInfo.consumptionAmount > 0 && (
                  <div className={`${styles.consumption} text-center mt-4`}>
                    {t('consumptionIncluded', {
                      consumption: '$' + reservationInfo?.consumptionAmount,
                    })}
                  </div>
                )}
              </div>
              <div className={`border-none ` + styles.paymentSection}>
                <Summary reservation={reservationInfo} />
              </div>

              {hasProducts && reservationInfo.remainingDepositAmount > 0 && (
                <div className='card text-center font-tiny p-3'>
                  <div className='bold font-smaller text-uppercase d-flex justify-content-center align-items-center'>
                    {t('important')}
                  </div>
                  <div className='mt-1'>
                    <span className='bold'>{t('paymentRequiredTitle')}</span>{' '}
                    {t('paymentRequiredText')}
                  </div>

                  <div className='font-small fw-semibold mt-2'>
                    <Money
                      value={reservationInfo.remainingDepositAmount}
                      symbol={currency.shortForm}
                      lowDecimals={true}
                      decimal={false}
                    />
                  </div>
                </div>
              )}

              {reservationInfo.remainingAmount > 0 ? (
                <div className={`row justify-content-center my-4`}>
                  <div className='col-4 pr-1'>
                    <SummaryBox title='Total' value={reservationInfo.totalAmount} />
                  </div>
                  <div className='col-4 px-2'>
                    <SummaryBox
                      title={t('deposit')}
                      value={reservationInfo.depositAmount}
                      subtitle={t('productsInfo.payUponConfirmation')}
                    />
                  </div>
                  <div className='col-4 pl-1'>
                    <SummaryBox
                      title='Balance'
                      value={reservationInfo.remainingAmount}
                      subtitle={t('productsInfo.payUponArrival')}
                    />
                  </div>
                </div>
              ) : null}
              {reservationInfo.paymentDetails && reservationInfo.paymentDetails.length > 0 ? (
                <div className={styles.paymentSection}>
                  <div className='bold'>{t('paymentsMade')}</div>
                  {reservationInfo.paymentDetails.map((payment) => (
                    <div className='row mt-3' key={payment.chargeId}>
                      <div className='col'>
                        <div className='fw-bold font-small'>
                          {t('payment')} #{payment.chargeId}
                        </div>
                        <div className='text-secondary font-tiny mt-1'>
                          <div>
                            <span className='text-capitalize'>{payment.brand}</span>
                            (xxxx xxxx xxxx {payment.last4})
                          </div>
                          <div>
                            {format(new Date(payment.paymentLocalDate), 'PPP HH:mm', {
                              locale,
                            })}
                          </div>
                        </div>
                      </div>
                      <div className={`col-auto ${styles.paymentAmount}`}>
                        -<Money value={payment.amount} lowDecimals={true} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {reservationInfo?.remainingDepositAmount > 0 &&
              reservationInfo.parentReservationReferenceCode ? (
                <div className={styles.paymentSection}>
                  <div className='row font-normal'>
                    <div className='col bold pr-0'>{t('remainingBalance')}:</div>
                    <div className='col-auto bold'>
                      <Money
                        value={reservationInfo?.remainingDepositAmount}
                        symbolSize='14px'
                        decimal={false}
                        lowDecimals={true}
                      />
                    </div>
                  </div>
                  <div className='font-small text-secondary mt-4 px-3 text-center'>
                    {t('remainingBalanceText')}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}

          {reservationInfo?.customerName ? (
            <div className={styles.paymentSection}>
              <div className='text-secondary text-medium fw-semibold'>{t('bookingHolder')}</div>
              <div className='font-normal bold mt-1'>{reservationInfo.customerName}</div>
            </div>
          ) : (
            <div className='mt-4'>
              <ReservationCheckCustomerInfo
                handleChange={handleChange}
                values={values}
                errors={errors}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
              />
            </div>
          )}

          {intervals.length === 0 && hasProducts ? (
            <div className='mt-3'>
              <EmptyActivity title={t('noSchedule')} />
            </div>
          ) : (
            <>
              {!reservation.performance ? (
                <div className='position-relative'>
                  <div className='mt-5' style={{ marginBottom: '-1rem' }}>
                    {reservation.mktCampaign.mktCampaignType.toLowerCase() === 'group' && (
                      <>
                        <span className='text-primary font-small fw-bold'>
                          {t('eventInformation')}
                        </span>
                        <div className='mt-4'>
                          <InputFormBorder
                            color='#757575'
                            name='eventName'
                            type='text'
                            onChange={handleChange}
                            value={values.eventName}
                            error={errors.eventName}
                            placeholder={t('event')}
                            label={t('eventName')}
                            border={'none'}
                            maxLength={200}
                          />
                        </div>
                      </>
                    )}

                    <p className='m-0 bold mt-4'>
                      {reservation.mktCampaign.mktCampaignType.toLowerCase() === 'area'
                        ? t('arrivalTime')
                        : t('startTimeEvent')}
                    </p>
                    <div className='mt-3'>
                      <ProductPerformanceSelector
                        performances={intervals}
                        onSelect={setSelectedPerformance}
                        selectedPerformance={selectedPerformance}
                        language={language}
                        selectedDate={reservation.date}
                        loadingSchedule={loadingSchedule}
                      />
                    </div>
                  </div>
                  {errors.performance && errors.performance.length > 0 && (
                    <div className='errorLabel mt-2 position-relative' style={{ color: '#fff' }}>
                      <span></span> {errors.performance}
                    </div>
                  )}
                </div>
              ) : null}
            </>
          )}

          <div className='mt-4'>
            <OccasionSelector
              handleSelectButton={handleSelectOccasion}
              selectedButton={selectedButton}
            />
          </div>

          <div className='mt-4'>
            <CustomerSpecialRequest
              open={open}
              setOpen={setOpen}
              values={values}
              handleChange={handleChange}
            />

            {error && (
              <>
                <ErrorBox title={error} />
                <div className='mt-4 text-center text-primary' onClick={() => history.goBack()}>
                  {t('reschedule')}
                </div>
              </>
            )}
          </div>

          <ButtonProccessing
            status={btnStatus}
            text={t('requestReservation')}
            size='large'
            className={`mt-4 ${styles.btn}`}
            type='submit'
            disabled={intervals.length === 0 && hasProducts}
            onClick={handleSubmit}
            arrow
            color='#000'
            backgroundColor='var(--color-primary)'
            iconColor='#000'
          />
        </div>
      </ContentApp>
    </>
  );
};

export default ReservationCheck;
