import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '../../icons/InfoIcon';
import Money from '../../Common/Money/Money';
import ProductModal from '../../Common/ProductModal/ProductModal';
import ReservationTicketInfo from '../../../screens/Reservation/ReservationTicketInfo/ReservationTicketInfo';

const SummaryProducts = ({ reservationInfo }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productId, setProductId] = useState(false);
  const [prices, setPrices] = useState(null);

  const toggleModal = (id) => {
    if (id) {
      const product = reservationInfo.products.find((p) => p.productId === id);
      const price = {
        consumption: product.consumptionAmount,
        priceExtraPax: product.amountPerExtraPax,
        price: product.amountPerUnit,
      };
      setPrices(price);
    }

    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <table className='w-100'>
        <thead>
          <th className='font-smaller'>{t('items')}</th>
          <th className='font-smaller text-center pr-2'>{t('quantityAbbr')}</th>
          <th className='font-smaller text-right'>{t('price')}</th>
        </thead>
        <tbody>
          {reservationInfo?.products.map((p, index) => (
            <>
              <tr className='font-small py-1'>
                <td className='position-relative fw-bold'>
                  {p.productName}
                  <span
                    style={{ top: '0', right: '0' }}
                    className='pl-2'
                    onClick={() => {
                      setProductId(p.productId);
                      toggleModal(p.productId);
                    }}
                  >
                    <InfoIcon size='13px' styles={{ marginTop: '-4px' }} />
                  </span>
                </td>
                <td className='font-small text-center py-1 pr-2'>{p.quantity}</td>
                <td className='font-small text-right py-1'>
                  <Money value={p.totalAmountWithoutExtraPaxs} symbolSize='14px' />
                </td>
              </tr>

              {p.extraPaxs && p.extraPaxs > 0 ? (
                <tr className='py-1' key={index}>
                  <td className='position-relative'>{t('extraGuest')}</td>
                  <td className='text-right py-1 pr-2'>{p.extraPaxs}</td>
                  <td className='text-right py-1'>
                    <Money value={p.totalAmountExtraPaxs} symbolSize='14px' />
                  </td>
                </tr>
              ) : null}
            </>
          ))}
        </tbody>
      </table>
      <ProductModal
        open={isModalOpen}
        onClose={toggleModal}
        animationClass='slide-left-right'
        fullScreen={true}
        hasBackground={true}
        backIcon={true}
      >
        <ReservationTicketInfo
          productId={productId}
          prices={prices}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          showAddButton={false}
        />
      </ProductModal>
    </>

    // <>
    //   {reservationInfo?.products.map((p, index) => (
    //     <div key={index}>
    //       <div className='row flex-nowrap mb-2'>
    //         <div className='col text-truncate'>
    //           <div
    //             className='text-truncate'
    //             style={{
    //               paddingRight: '25px',
    //               display: 'inline-block',
    //               maxWidth: '100%',
    //               position: 'relative',
    //             }}
    //           >
    //             {p.productName}
    //             <span
    //               style={{ position: 'absolute', top: '0', right: '0' }}
    //               className='pl-2'
    //               onClick={() => {
    //                 setProductId(p.productId);
    //                 toggleModal(p.productId);
    //               }}
    //             >
    //               <InfoIcon styles={{ marginTop: '-4px' }} />
    //             </span>
    //           </div>
    //         </div>
    //         <div className='col-auto'>
    //           {p.quantity}
    //         </div>
    //         <div className='col-auto'>
    //           <Money value={p.totalAmountWithoutExtraPaxs} symbolSize='14px' />
    //         </div>
    //       </div>

    //       {p.extraPaxs && p.extraPaxs > 0 ? (
    //         <div className='row flex-nowrap fw-semibold mb-2'>
    //           <div className='col text-truncate'>
    //             <div
    //               className='text-truncate'
    //               style={{
    //                 paddingRight: '25px',
    //                 display: 'inline-block',
    //                 maxWidth: '100%',
    //                 position: 'relative',
    //               }}
    //             >
    //               {p.productName} - {t('extraGuest')} x {p.extraPaxs}
    //               <span
    //                 style={{ position: 'absolute', top: '0', right: '0px' }}
    //                 onClick={() => {
    //                   setProductId(p.productId);
    //                   toggleModal(p.productId);
    //                 }}
    //               >
    //                 <InfoIcon styles={{ marginTop: '-4px' }} />
    //               </span>
    //             </div>
    //           </div>
    //           <div className='col-auto'>
    //             <Money value={p.totalAmountExtraPaxs} symbolSize='14px' />
    //           </div>
    //         </div>
    //       ) : null}
    //     </div>
    //   ))}

    // </>
  );
};

export default SummaryProducts;
