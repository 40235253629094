import React from 'react';

const DigitalCard = ({ fillColor = '#FFF', size = '20px', onClick }) => (
  <svg width={size} height={size} viewBox="0 0 51 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.538 0.5625C2.15945 0.5625 0.375 2.63246 0.375 5.7255V33.1346C0.375 36.2276 2.14755 38.2976 5.538 38.2976H45.462C48.8406 38.2976 50.625 36.2276 50.625 33.1346V5.7255C50.625 2.63246 48.8525 0.5625 45.462 0.5625H5.538Z" fill={fillColor} />
    <path d="M5.94247 2.66797C3.2658 2.66797 2.46875 4.27397 2.46875 6.26065V19.3823L14.2818 19.4061C17.4343 19.4061 18.731 20.679 20.0515 22.4634C20.3846 22.9155 20.7891 23.5341 21.1935 23.9267C22.3832 25.1163 23.9416 25.7111 25.5 25.7111C27.0584 25.7111 28.6168 25.1163 29.8065 23.9267C30.199 23.5341 30.6154 22.9155 30.9485 22.4634C32.269 20.6909 33.5538 19.418 36.7182 19.4061L48.5312 19.3823V6.26065C48.5312 4.26207 47.7342 2.66797 45.0575 2.66797H5.94247Z" fill="#F16D5E" />
    <path d="M5.94247 2.66797C3.2658 2.66797 2.46875 4.27397 2.46875 6.26065V10.46C2.46875 8.46147 3.2658 6.86737 5.94247 6.86737H45.0575C47.7342 6.86737 48.5312 8.47337 48.5312 10.46V6.26065C48.5312 4.26207 47.7342 2.66797 45.0575 2.66797H5.94247Z" fill="#3295C9" />
    <mask id="mask0_3663_5703" mask-type="luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="47" height="9">
      <path d="M5.94247 2.66797C3.2658 2.66797 2.46875 4.27397 2.46875 6.26065V10.46C2.46875 8.46147 3.2658 6.86737 5.94247 6.86737H45.0575C47.7342 6.86737 48.5312 8.47337 48.5312 10.46V6.26065C48.5312 4.26207 47.7342 2.66797 45.0575 2.66797H5.94247Z" fill="white" />
    </mask>
    <g mask="url(#mask0_3663_5703)">
      <path opacity="0.3" d="M5.94247 6.87891C3.2658 6.87891 2.46875 8.48491 2.46875 10.4716V14.671C2.46875 12.6724 3.2658 11.0783 5.94247 11.0783H45.0575C47.7342 11.0783 48.5312 12.6843 48.5312 14.671V10.4716C48.5312 8.47301 47.7342 6.87891 45.0575 6.87891H5.94247Z" fill="black" />
    </g>
    <path d="M5.94247 6.87891C3.2658 6.87891 2.46875 8.48491 2.46875 10.4716V14.671C2.46875 12.6724 3.2658 11.0783 5.94247 11.0783H45.0575C47.7342 11.0783 48.5312 12.6843 48.5312 14.671V10.4716C48.5312 8.47301 47.7342 6.87891 45.0575 6.87891H5.94247Z" fill="#FCAD00" />
    <mask id="mask1_3663_5703" mask-type="luminance" maskUnits="userSpaceOnUse" x="2" y="6" width="47" height="9">
      <path d="M5.94247 6.87891C3.2658 6.87891 2.46875 8.48491 2.46875 10.4716V14.671C2.46875 12.6724 3.2658 11.0783 5.94247 11.0783H45.0575C47.7342 11.0783 48.5312 12.6843 48.5312 14.671V10.4716C48.5312 8.47301 47.7342 6.87891 45.0575 6.87891H5.94247Z" fill="white" />
    </mask>
    <g mask="url(#mask1_3663_5703)">
      <path opacity="0.3" d="M5.94247 11.0781C3.2658 11.0781 2.46875 12.6841 2.46875 14.6708V18.8702C2.46875 16.8716 3.2658 15.2775 5.94247 15.2775H45.0575C47.7342 15.2775 48.5312 16.8835 48.5312 18.8702V14.6708C48.5312 12.6722 47.7342 11.0781 45.0575 11.0781H5.94247Z" fill="black" />
    </g>
    <path d="M5.94247 11.0781C3.2658 11.0781 2.46875 12.6841 2.46875 14.6708V18.8702C2.46875 16.8716 3.2658 15.2775 5.94247 15.2775H45.0575C47.7342 15.2775 48.5312 16.8835 48.5312 18.8702V14.6708C48.5312 12.6722 47.7342 11.0781 45.0575 11.0781H5.94247Z" fill="#50BE3D" />
    <mask id="mask2_3663_5703" mask-type="luminance" maskUnits="userSpaceOnUse" x="2" y="11" width="47" height="8">
      <path d="M5.94247 11.0781C3.2658 11.0781 2.46875 12.6841 2.46875 14.6708V18.8702C2.46875 16.8716 3.2658 15.2775 5.94247 15.2775H45.0575C47.7342 15.2775 48.5312 16.8835 48.5312 18.8702V14.6708C48.5312 12.6722 47.7342 11.0781 45.0575 11.0781H5.94247Z" fill="white" />
    </mask>
    <g mask="url(#mask2_3663_5703)">
      <path opacity="0.3" d="M5.94247 15.2773C3.2658 15.2773 2.46875 16.8833 2.46875 18.8699V23.0693C2.46875 21.0707 3.2658 19.4766 5.94247 19.4766H45.0575C47.7342 19.4766 48.5312 21.0826 48.5312 23.0693V18.8699C48.5312 16.8713 47.7342 15.2773 45.0575 15.2773H5.94247Z" fill="black" />
    </g>
    <path d="M5.94247 15.2773C3.2658 15.2773 2.46875 16.8833 2.46875 18.8699V23.0693C2.46875 21.0707 3.2658 19.4766 5.94247 19.4766H45.0575C47.7342 19.4766 48.5312 21.0826 48.5312 23.0693V18.8699C48.5312 16.8713 47.7342 15.2773 45.0575 15.2773H5.94247Z" fill="#DC1C1C" />
    <mask id="mask3_3663_5703" mask-type="luminance" maskUnits="userSpaceOnUse" x="2" y="15" width="47" height="8">
      <path d="M5.94247 15.2773C3.2658 15.2773 2.46875 16.8833 2.46875 18.8699V23.0693C2.46875 21.0707 3.2658 19.4766 5.94247 19.4766H45.0575C47.7342 19.4766 48.5312 21.0826 48.5312 23.0693V18.8699C48.5312 16.8713 47.7342 15.2773 45.0575 15.2773H5.94247Z" fill="white" />
    </mask>
    <g mask="url(#mask3_3663_5703)">
      <path opacity="0.3" d="M5.94247 19.4766C3.2658 19.4766 2.46875 21.0826 2.46875 23.0693V28.2694C2.46875 26.2707 3.2658 24.6766 5.94247 24.6766H45.0575C47.7342 24.6766 48.5312 26.2826 48.5312 28.2694V23.0693C48.5312 21.0707 47.7342 19.4766 45.0575 19.4766H5.94247Z" fill="black" />
    </g>
    <path d="M5.94247 19.4766C3.2658 19.4766 2.46875 21.0826 2.46875 23.0693V28.2694C2.46875 26.2707 3.2658 24.6766 5.94247 24.6766H45.0575C47.7342 24.6766 48.5312 26.2826 48.5312 28.2694V23.0693C48.5312 21.0707 47.7342 19.4766 45.0575 19.4766H5.94247Z" fill="#6B6B6B" />
  </svg>
);

export default DigitalCard;
