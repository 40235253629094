import React, { useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/WelletContext';
import { getAssociateSaleSetup } from '../../../actions/associatesActions';
import { Toast } from '../../../components/Common/Toast/Toast';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import useForm from '../../../helpers/useForm';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import InputFormNew from '../../../components/Common/InputFormNew/InputFormNew';
import Button from '../../../components/Common/Button/Button';
import { Spinner } from '../../../components/Common/Spinner/Spinner';
import ErrorBox from '../../../components/Common/ErrorBox/ErrorBox';

const UserChangePasswordScreen = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const needPasswordReset = useSelector((state) => state.associates.user.needPasswordReset);
  const [changingPassword, setChangingPassword] = useState(false);
  const [successChanged, setSuccessChanged] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.associates.jwt);
  const onPasswordChangeHandle = () => {
    history.push({
      pathname: '/',
    });
  };
  function validate(values) {
    let errors = {};

    const rexCapital = /[A-Z]/;
    const rexNumber = /[0-9]/;
    const rexSpace = /\s/;

    if (!values.password) {
      errors.password = t('passwordError.PASSWORD_TOO_SHORT');
    } else if (values.password.length < 6) {
      errors.password = t('passwordError.PASSWORD_TOO_SHORT');
    } else if (!rexCapital.test(values.password)) {
      errors.password = t('passwordError.PASSWORD_HAS_NOT_UPPERCASE_LETTER');
    } else if (!rexNumber.test(values.password)) {
      errors.password = t('passwordError.PASSWORD_HAS_NOT_NUMBER');
    } else if (rexSpace.test(values.password)) {
      errors.password = t('passwordError.PASSWORD_CONTAIN_SPACES');
    } else if (values.password !== values.passwordconfirmation) {
      errors.passwordconfirmation = t('passwordError.PASSWORD_NOT_MATCH');
    }

    return errors;
  }
  const sendForm = async () => {
    setChangingPassword(true);
    setError('');

    try {
      const result = await welletContext.apis.tickets.put(`/users/password/change`, {
        NewPassword: values.password,
        confirmation: values.passwordconfirmation,
      });
      if (result.status) {
        setSuccessChanged(true);
        if (needPasswordReset) {
          dispatch(getAssociateSaleSetup(jwt));
        }
      }
      setChangingPassword(false);
    } catch (error) {
      console.error(error);
      setError('UNKNOWN');
      setChangingPassword(false);
    }
  };
  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);
  return (
    <>
      <Header title={t('changePassword')} />
      <ContentApp>
        {needPasswordReset && (
          <EmptyActivity
            showIcon={false}
            title={`${t('resetPasswordTitle')}:`}
            subtitle={t('resetPasswordDescription')}
          />
        )}
        <div className='mt-3'>
          <form onSubmit={handleSubmit}>
            <div className='input-character mt-5'>
              <InputFormNew
                name={'password'}
                type={'password'}
                value={values.password}
                error={errors.password}
                placeholder={t('newPassword')}
                onChange={handleChange}
              />
            </div>
            <div className='input-character mt-5'>
              <InputFormNew
                name={'passwordconfirmation'}
                type={'password'}
                value={values.passwordconfirmation}
                error={errors.passwordconfirmation}
                placeholder={t('repeatNewPassword')}
                onChange={handleChange}
              />
            </div>
            <div className='mt-5'>
              {error !== '' ? (
                <div className='mb-3'>
                  <ErrorBox
                    title={t('passwordError.CHANGE_PASSWORD_TITLE')}
                    description={t('passwordError.CHANGE_PASSWORD_DESCRIPTION')}
                  />
                </div>
              ) : null}
              <div className='w-100 d-flex justify-content-center'>
                <Button
                  size={'large'}
                  text={
                    changingPassword ? (
                      <div className='d-flex justify-content-center'>
                        <Spinner size={25} color='#000' />
                      </div>
                    ) : (
                      t('changePassword')
                    )
                  }
                  iconColor='#000'
                  className='btn-primary'
                />
              </div>
              {successChanged ? (
                <div className='mt-3 text-center'>
                  <Toast
                  top={false}
                    type={'success'}
                    message={t('passwordUpdated')}
                    delay={3}
                    onCallback={onPasswordChangeHandle}
                  />
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </ContentApp>
    </>
  );
};

export default UserChangePasswordScreen;
