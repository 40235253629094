import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRegisterCompleted } from '../../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Common/Header/Header';
import SuccessScreen from '../../../Common/SuccessScreen';
import ErrorScreen from '../../../Common/ErrorScreen';
import { getQueryString } from '../../../../helpers/url';

const FinishKyc = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const kycResponse = getQueryString('success');
  const error = getQueryString('error');

  useEffect(() => {
    if (kycResponse === 'true') {
      dispatch(setRegisterCompleted(true));
    }
  }, []);

  return (
    <>
      {kycResponse === 'true' ? (
        <>
          <Header />
          <SuccessScreen title={`${t('done')}!`} subtitle={t('kyc.successKyc')} path={'/'} />
        </>
      ) : (
        <>
          <Header title={t('kyc.validateIdentity')} hasBackground={true} />
          <ErrorScreen
            title={t('somethingWentWrong')}
            subtitle={t(`kycError.${error}.description`)}
            buttonText={t('tryAgain')}
            path={'/kyc-start'}
          />
        </>
      )}
    </>
  );
};

export default FinishKyc;
