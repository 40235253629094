import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { WelletContext } from '../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { useSelector } from 'react-redux';
import styles from './SignUp.module.css';
const TermsAndConditions = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const [termsAndConditions, setTermsAndConditions] = useState('');

  const getTermsAndConditions = async () => {
    try {
      const response = await welletContext.apis.tickets.get(
        `/entity/policy/term?Language=${language}`,
      );
      setTermsAndConditions(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  return (
    <>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white'>{t('termsAndConditions')}</div>
        <div className='text-secondary mt-3'>
          <p className='text-white bold'>{t('termsConditions.subtitle1')}</p>

          {loading ? (
            <div className='loaderDiv'>
              <CircleSpinner
                className='spinner-app mx-auto'
                style={{ margin: 'auto' }}
                size={40}
                color='var(--color-font-primary)'
              />
            </div>
          ) : (
            
            <div className={`${styles.terminos} mt-2`} dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
          )}
        </div>
      </ContentApp>
    </>
  );
};

export default TermsAndConditions;
