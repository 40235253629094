import React from 'react';
import WarningCircleIcon from '../../icons/WarningCircleIcon';

const WarningBox = ({ title, subtitle, onClick, textLink, onClickText }) => {
  return (
    <div className='card p-3' onClick={onClick}>
      <div className='row px-3'>
        <div className='col-auto p-0'>
          <WarningCircleIcon color={'var(--color-warning)'} size={'20px'} />
        </div>
        <div className='col pl-2 pr-0 font-small'>
          <div className='bold'>{title}</div>
          <div className='text-secondary mt-1'>{subtitle}</div>
          <u onClick={onClickText} className='text-primary'>
            {textLink}
          </u>
        </div>
      </div>
    </div>
  );
};

export default WarningBox;
