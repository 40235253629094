import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircleSpinner } from 'react-spinners-kit';
import { WelletContext } from '../../context/WelletContext';
import ContentApp from '../../components/ContentApp';
import Header from '../../components/Common/Header/Header';
import { getQueryString } from '../../helpers/url';
import DownloadIcon from '../../components/icons/DownloadIcon';
import CopyIcon from '../../components/icons/copyIcon';
import { download } from '../../helpers/file';
import './social-media-screen.css';

const SocialMediaScreen = () => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const showId = getQueryString('showId');
  const [show, setShow] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad|crios|fxios/.test(userAgent);

  const getShow = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get(`/shows/associateLink?idshow=${showId}`);

      setShow(result.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const normalizeFileName = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };

  const renderDownloadButton = (url, fileName) => {
    if (ios) {
      const downloadUrl =
        'https://wr-scope.wellet.dev/#descargar' + '?url=' + url + '&filename=' + fileName;
      return (
        <div className='text-center text-primary mt-2'>
          <a href={downloadUrl} target='_blank'>
            {t('download')}{' '}
            <DownloadIcon
              size={18}
              color={'var(--color-primary)'}
              styles={{ marginLeft: '.5rem', marginTop: '-2px' }}
            />
          </a>
        </div>
      );
    } else {
      return (
        <div className='text-center text-primary mt-2' onClick={() => download(url, fileName)}>
          {t('download')}{' '}
          <DownloadIcon
            size={18}
            color={'var(--color-primary)'}
            styles={{ marginLeft: '.5rem', marginTop: '-2px' }}
          />
        </div>
      );
    }
  };

  const renderVideos = () => {
    if (show.videoUrlList && show.videoUrlList.length > 0) {
      return (
        <>
          <div className='bold mt-4'>{t('showSocialMediaScreen.videoSocialNetwork')}</div>
          <div className='text-secondary mt-1'>
            {t('showSocialMediaScreen.videoSocialNetworkText')}
          </div>
          <div className='row mt-3'>
            {show.videoUrlList.map((video, i) => (
              <div className='col-6 mb-4' key={i}>
                <div className='video-item'>
                  <video controls='controls' preload='metadata'>
                    <source src={video + '#t=0.1'} type='video/mp4' />
                  </video>
                </div>
                {renderDownloadButton(video, normalizeFileName(show.name) + '-' + i + '.mp4')}
              </div>
            ))}
          </div>
        </>
      );
    }

    return null;
  };

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    getShow();
  }, []);

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header title={<div className='text-capitalize'>{show.name}</div>} />
      <ContentApp classes='mb-4'>
        <div className='text-lighter-gray font-normal bold'>
          {t('showSocialMediaScreen.getAutomaticIncome')}
        </div>
        <div className='text-secondary mt-1'>
          {t('showSocialMediaScreen.getAutomaticIncomeText')}
        </div>
        <div className='text-secondary bold mt-4'>{t('link')}</div>
        <div className='font-normal' onClick={() => onCopy(show.link)}>
          <div className='text-truncate float-left' style={{ width: '90%' }}>
            {show.link}
          </div>
          <span className='ml-2'>
            <CopyIcon fillColor='var(--color-primary)' size='20' styles={{ marginTop: '-4px' }} />
          </span>
          {copied && <div className='text-center mt-1'>{t('copied')}</div>}
        </div>
        {renderVideos()}
        <div className='text-lighter-gray font-normal bold mt-5'>
          {t('showSocialMediaScreen.commissionableqrCode')}
        </div>
        <div className='text-secondary mt-1'>
          {t('showSocialMediaScreen.commissionableqrCodeText')}
        </div>
        <div className='row mt-3'>
          <div className='col-6'>
            <div className='text-secondary bold mt-3'>{t('showSocialMediaScreen.qrDark')}</div>
            <div className='qr-image mt-2'>
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=256x256&bgcolor=000&color=d9d9d9&format=png&data=${show.link}`}
              />
            </div>
            <div
              className='text-center text-primary mt-2'
              onClick={() =>
                download(
                  `https://api.qrserver.com/v1/create-qr-code/?size=256x256&bgcolor=000&color=d9d9d9&format=png&data=${show.link}`,
                  'qr-' + normalizeFileName(show.name) + '-dark.png',
                )
              }
            >
              {t('download')}{' '}
              <DownloadIcon
                size={18}
                color={'var(--color-primary)'}
                styles={{ marginLeft: '.5rem', marginTop: '-2px' }}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='text-secondary bold mt-3'>{t('showSocialMediaScreen.qrLight')}</div>
            <div className='qr-image mt-2'>
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=256x256&format=png&data=${show.link}`}
              />
            </div>
            <div
              className='text-center text-primary mt-2'
              onClick={() =>
                download(
                  `https://api.qrserver.com/v1/create-qr-code/?size=256x256&format=png&data=${show.link}`,
                  'qr-' + normalizeFileName(show.name) + '.png',
                )
              }
            >
              {t('download')} <DownloadIcon size={18} color={'var(--color-primary)'} />
            </div>
          </div>
        </div>
      </ContentApp>
    </>
  );
};

export default SocialMediaScreen;
