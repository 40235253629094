import React from 'react';
import ContentApp from '../../../components/ContentApp';
import Header from '../../../components/Common/Header/Header';
import ChevronIcon from '../../../components/icons/chevronIcon';
import styles from './Campaigns.module.css';
import CampaignsLogo from '../../../images/Onboarding/CampaignsLogo.png';
import AnalyticsLogo from '../../../images/Onboarding/AnalyticsLogo.png';
import { useTranslation } from 'react-i18next';

const Campaigns = ({ history }) => {
  const { t } = useTranslation();

  const items = [
    {
      name: t('campaign.campaign'),
      logoUrl: CampaignsLogo,
      description: t('campaign.descriptionCampanas'),
      path: '/campaigns-feed',
    },
    {
      name: t('campaign.analytics'),
      logoUrl: AnalyticsLogo,
      description: t('campaign.descriptionAnalytics'),
      path: '/analytics',
    },
  ];

  const renderItem = (index, item) => {
    return (
      <div
        key={index}
        className={`${item.path ? styles.restaurantCard : styles.campaignCardUnactive} mt-2`}
        onClick={() => history.push(item.path)}
      >
        <div className='col-auto p-0 ml-3 my-auto'>
          <div className={styles.containerImg}>
            <img src={item.logoUrl} alt='logo-restaurant' />
          </div>
        </div>
        <div className='col p-0 ml-3'>
          <div className='bold font-normal text-white'>
            {item.name}
            {!item.path ? <span className={styles.upcomingText}>{t('comingSoon')}</span> : null}
          </div>
          <div className='font-small text-secondary mt-1'>{item.description}</div>
        </div>
        {item.path ? (
          <div className='col-auto pl-0'>
            <ChevronIcon fillColor={'var(--color-primary)'} size={35} />
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <Header title={t('campaign.campaign')} pathName='/' />
      <ContentApp style={{ height: '100%' }}>
        <div className='px-2'>
          <div className='bold font-medium mt-3'>{t('campaign.campaignCenter')}</div>
          <div className='text-secondary mt-2'>{t('campaign.viewCampaignCenter')}</div>
        </div>
        <div className='mt-3'>
          {items.slice(0, 2).map((item, index) => renderItem(index, item))}
        </div>
      </ContentApp>
    </div>
  );
};

export default Campaigns;
