import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import step1 from '../../../../images/Onboarding/FirstOnboarding.png';
import step2 from '../../../../images/Onboarding/SecondOnboarding.png';
import step3 from '../../../../images/Onboarding/ThirdOnboarding.png';
import './Onboarding.css';
import Button from '../../../../components/Common/Button/Button';
import ContentApp from '../../../../components/ContentApp';
import Header from '../../../../components/Common/Header/Header';
import { WelletContext } from '../../../../context/WelletContext';

const OnboardingCampaigns = () => {
  const welletContext = useContext(WelletContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();

  const steps = [
    {
      title: t('onboardingCarousel.image1.title'),
      subtitle: t('onboardingCarousel.image1.subtitle'),
      image: step1,
    },
    {
      title: t('onboardingCarousel.image2.title'),
      subtitle: t('onboardingCarousel.image2.subtitle'),
      image: step2,
    },
    {
      title: t('onboardingCarousel.image3.title'),
      subtitle: t('onboardingCarousel.image3.subtitle'),
      image: step3,
    },
  ];

  const firstOnboarding = async () => {
    try {
      await welletContext.apis.tickets.patch('/Campaign/onboarding');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDotClick = (index) => {
    setCurrentStep(index);
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50 && currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    }

    if (touchStart - touchEnd < -50 && currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <>
      <Header
        rightText={t('onboarding.skip')}
        onClickRight={() => {
          history.push('/campaigns');
          firstOnboarding();
        }}
      />
      <ContentApp>
        <div className='carousel-container'>
          <div
            className='carousel-slide'
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={steps[currentStep].image} alt={`step-${currentStep + 1}`} />
          </div>
          <div className='carousel-dots'>
            {steps.map((step, index) => (
              <span
                key={index}
                className={`dot ${index === currentStep ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></span>
            ))}
          </div>
          <h2 className='carousel-title'>{steps[currentStep].title}</h2>
          <p className='carousel-subtitle'>{steps[currentStep].subtitle}</p>
          {currentStep === steps.length - 1 && (
            <Button
              size='large'
              text={t('perfect')}
              onClick={() => {
                history.push('/campaigns');
                firstOnboarding();
              }}
              className='btn-test'
            />
          )}
        </div>
      </ContentApp>
    </>
  );
};

export default OnboardingCampaigns;
