import React from 'react';

const AmexIcon = ({ fillColor = '#FFF', size = '50px', onClick }) => (
    <svg  width={size}
    height={size} viewBox="0 0 119 86" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.43542 0H111.533C115.637 0 118.968 3.33095 118.968 7.43908V78.0765C118.968 82.181 115.637 85.512 111.533 85.512H7.43542C3.32728 85.5124 0 82.181 0 78.077V7.43908C0 3.33095 3.33095 0 7.43542 0Z" fill="#26A6D1"/>
    <path d="M19.1818 29.7422L7.43359 55.7407H21.4979L23.2414 51.5954H27.2268L28.9704 55.7407H44.4512V52.5769L45.8307 55.7407H53.8386L55.218 52.51V55.7407H87.4139L91.3288 51.7031L94.9945 55.7407L111.531 55.7741L99.7457 42.814L111.531 29.7422H95.251L91.4401 33.7052L87.8898 29.7422H52.8649L49.8573 36.4526L46.7791 29.7422H32.7442V32.7983L31.1829 29.7422H19.1818ZM21.9032 33.434H28.7588L36.5514 51.0637V33.434H44.0614L50.0803 46.0744L55.6274 33.434H63.0999V52.0896H58.553L58.5159 37.4712L51.8871 52.0896H47.8197L41.1537 37.4712V52.0896H31.7998L30.0265 47.9073H20.4459L18.6762 52.0859H13.6645L21.9032 33.434ZM67.2598 33.434H85.7483L91.403 39.5422L97.24 33.434H102.895L94.303 42.8103L102.895 52.0786H96.9836L91.3288 45.8995L85.462 52.0786H67.2598V33.434ZM25.2382 36.5904L22.0818 44.0409H28.391L25.2382 36.5904ZM71.8255 37.2967V40.7022H81.9117V44.498H71.8255V48.2159H83.1386L88.3954 42.7398L83.3617 37.2934H71.8255V37.2967Z" fill="white"/>
    </svg>
    
);

export default AmexIcon;
