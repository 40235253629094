import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import { WelletContext } from '../../../../context/WelletContext';
import { getQueryString } from '../../../../helpers/url';
import { fetchCampaignReservations } from '../../../../services/campaigns/campaignsService';
import ActivityReservationItem from '../../../../components/Activities/ActivityReservationItem.js/ActivityReservationItem';
import ContentApp from '../../../../components/ContentApp';
import { CircleSpinner } from 'react-spinners-kit';

const CampaignReservations = ({ history }) => {
  const welletContext = useContext(WelletContext);
  const [campaignReservations, setCampaignReservations] = useState(null);
  const [loading, setLoading] = useState(true);
  const fromDate = getQueryString('fromDate');
  const toDate = getQueryString('toDate');

  const bodyReservations = {
    FilterDate: 'ANALYTICS',
    skip: 0,
    limit: 100,
    From: fromDate,
    To: toDate,
  };

  const getCampaignReservations = async () => {
    const responseReservations = await fetchCampaignReservations(welletContext, bodyReservations);
    setCampaignReservations(responseReservations.data);
    setLoading(false);
  };

  useEffect(() => {
    getCampaignReservations();
  }, []);

  return (
    <div>
      <Header title='Reservas de la campaña' />
      <ContentApp>
        {loading ? (
          <div className='loaderDiv'>
            <CircleSpinner
              className='spinner-app mx-auto'
              style={{ margin: 'auto' }}
              size={40}
              color='var(--color-font-primary)'
            />
          </div>
        ) : (
          <div className='mt-2'>
            {campaignReservations?.map((r, index) => (
              <ActivityReservationItem
                activity={r}
                key={index}
                date={r.createdDate}
                index={index}
                lastIndex={index}
                handleClick={() => history.push(`operation-details?id=${r.id}`)}
                rightText
              />
            ))}
          </div>
        )}
      </ContentApp>
    </div>
  );
};

export default CampaignReservations;
