import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const welletContext = useContext(WelletContext);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [loading, setLoading] = useState(true);
  const language = useSelector((state) => state.app.language);

  const getPrivacyPolicy = async () => {
    try {
      const response = await welletContext.apis.tickets.get(
        `/entity/policy/privacy?Language=${language}`,
      );
      setPrivacyPolicy(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white'>
          {t('privacyStatementTerms.title', {
            organization: app.name,
          })}
        </div>

        {loading ? (
          <div className='loaderDiv'>
            <CircleSpinner
              className='spinner-app mx-auto'
              style={{ margin: 'auto' }}
              size={40}
              color='var(--color-font-primary)'
            />
          </div>
        ) : (
          <div className='mt-2' dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
        )}
      </ContentApp>
    </>
  );
};

export default PrivacyPolicy;
