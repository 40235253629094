import axios from 'axios';

export function initNativeAppBridge() {
  if (!window.welletApp) {
    window.welletApp = {};
  }
  window.welletApp.fcmToken = null;
  window.welletApp.fcmTokenRetries = 0;

  // --- ios ----
  window.addEventListener('push-notification-token', (event) => {
    window.welletApp.fcmToken = event.detail;
    // alert('Received push-notification-token from native app:' + JSON.stringify(event.detail));
  });

  //   window.addEventListener('push-notification', (event) => {
  //     //alert('Received push-notification from native app:' + JSON.stringify(event.detail));
  //   });

  // push-permission-request
  //   window.addEventListener('push-permission-request', (event) => {
  //     // alert('Received push-permission-request from native app:' + JSON.stringify(event.detail));
  //   });

  //push-permission-state
  //   window.addEventListener('push-permission-state', (event) => {
  //     // alert('Received push-permission-state from native app:' + JSON.stringify(event));
  //   });
  // --- end ios ---

  // --- android ---
  // parse url to get query param fcmToken -- used for android
  const urlParams = new URLSearchParams(window.location.search);
  const fcmToken = urlParams.get('fcmToken');
  if (fcmToken) {
    window.welletApp.fcmToken = fcmToken;
  }
  // --- end android ---
}

export function saveToken(jwt) {
  if (window.welletApp.fcmToken) {
    axios.post(
      `${process.env.REACT_APP_SALES_API_URL}/users/savedeviceid`,
      {
        token: window.welletApp.fcmToken,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      },
    );
  } else {
    // retry 3 times, in case ios push-notification event has not been triggered yet
    if (window.welletApp.fcmTokenRetries < 3) {
      window.welletApp.fcmTokenRetries += 1;
      setTimeout(() => saveToken(jwt), 10000);
    }
  }
}
