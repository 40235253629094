import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { WelletContext } from '../../../context/WelletContext';
import { skeletonNotificationConfiguration } from '../../../components/Skeletons/SkeletonNotifications';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../actions/appActions';
import { cleanLanguageCode } from '../../../helpers/language';
import Header from '../../../components/Common/Header/Header';
import styles from './configuration.module.css';
import ContentApp from '../../../components/ContentApp';
import NotificationSwitch from '../../../components/Notifications/NotificationSwitch/NotificationSwitch';
import SolidChevron from '../../../components/icons/SolidChevron';

const Configuration = () => {
  const { t } = useTranslation();
  const options = [
    {
      value: 'en',
      label: t('languages.english'),
    },
    {
      value: 'es',
      label: t('languages.spanish'),
    },
  ];
  const [loading, setLoading] = useState(true);
  const [userNotifications, setUserNotifications] = useState(null);
  const language = useSelector((state) => state.app.language);
  const [selectedLanguage, setSelectedLanguage] = useState(
    options.find((option) => option.value === cleanLanguageCode(language)),
  );
  const welletContext = useContext(WelletContext);
  const dispatch = useDispatch();

  const getNotificationName = (notificationType) => {
    switch (notificationType) {
      case 'DISCOUNT':
        return t('discounts');
      case 'PROMOTION':
        return t('promotions');
      case 'REGISTER':
        return t('signUps');
      case 'SYSTEM':
        return t('system');
      default:
        break;
    }
  };

  const getUserNotifications = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get(
        '/api/Notification/GetUserNotificationType',
      );
      setUserNotifications(result.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeLanguage = (language) => {
    dispatch(setLanguage(language.value));
    setSelectedLanguage(language);
    try {
      welletContext.apis.tickets.post('/associates/saveconfig', {
        lang: language.value,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userNotifications) {
      getUserNotifications();
    }
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='mr-3 p-0'>
        <SolidChevron size={18} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const selectorStyles = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '.4rem',
      boxShadow: 'none',
      backgroundColor: '#000',
      border: '1px solid var(--color-primary) !important',
      borderRadius: '10px',
      height: '69px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      color: '#000',
    }),
    singleValue: (provided) => ({
      ...provided,

      color: '#fff',
      fontWeight: 'bold',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#fff',
      fontWeight: 'bold',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
  };

  return (
    <>
      <Header title={<div className='bold'>{t('settings')}</div>} pathName={'/'} />
      <ContentApp>
        {!loading ? (
          <>
            <div className='bold mt-3 font-normal' style={{ color: 'var(--color-font-primary)' }}>
              {t('notifications')}
            </div>
            <div className={`${styles.container} mt-3 fade-up`}>
              {userNotifications?.map((n, i) => (
                <NotificationSwitch
                  key={i}
                  name={getNotificationName(n.notificationType)}
                  isActive={n.isActive}
                  type={n.notificationType}
                />
              ))}
            </div>
            <div className='bold mt-3 font-normal' style={{ color: 'var(--color-font-primary)' }}>
              {t('languages.title')}
            </div>
            <Select
              key={selectedLanguage?.value}
              className={`w-100 mt-3 hide-indicator-separator`}
              components={{ DropdownIndicator }}
              isSearchable={false}
              styles={selectorStyles}
              options={options}
              onChange={(e) => handleChangeLanguage(e)}
              placeholder={t(selectedLanguage.label)}
              value={selectedLanguage}
            />
          </>
        ) : (
          <div className='mt-3'>{skeletonNotificationConfiguration()}</div>
        )}
      </ContentApp>
    </>
  );
};

export default Configuration;
