import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../actions/associatesActions';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import Button from '../../../../components/Common/Button/Button';
import StepUserIdIcon from '../../../../components/icons/Kyc/StepUserIdIcon';
import FixedButton from '../../../../components/Common/FixedButton/FixedButton';
import './Kyc.css';

const ValidateIdKyc = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.associates.user.company);
  const userKyc = useSelector((state) => state.associates.user.kycStatus);

  const handleSignOut = () => {
    dispatch(logout());
    history.push('/login');
  };

  return (
    <>
      <Header showBack={userKyc !== 'PENDING'} hasBackground={true} />
      <ContentApp background={true}>
        <div className='text-center'>
          <div className='text-center' style={{ marginTop: '20%' }}>
            <StepUserIdIcon color='#fff' size='57px' />
          </div>
          <div className='font-medium bold mt-5 text-white'>{t('kyc.validateIdentityTitle')}</div>
         
          <div className='m-2 text-secondary'>
            {(() => {
              switch (user.country) {
                case 'US':
                  return t('kyc.validateIdentityDescriptionUS');
                case 'MX':
                  return t('kyc.validateIdentityDescriptionMX');
                case 'ES':
                  return t('kyc.validateIdentityDescriptionES');
                default:
                  return t('kyc.validateIdentityDescriptionDefault');
              }
            })()}
          </div>
          <div  className='text-secondary segurity'> {t('kyc.identitySecurity')}</div>
        </div>
        <FixedButton>
          <Button
            text={t('menu.logout')}
            size='large'
            onClick={handleSignOut}
            fontSize={'17px'}
            className=' mb-2'
            color='var(--color-lighter-gray)'
            backgroundColor='#000'
          />
          <Button
            text={t('continue')}
            size='large'
            onClick={() => history.push('/kyc-validate-front')}
            fontSize={'17px'}
            icon={true}
            typeIcon='arrow'
            iconColor='#000'
            className='btn-primary'
          />
        </FixedButton>
      </ContentApp>
    </>
  );
};

export default ValidateIdKyc;
