
import React from 'react';
import { useHistory } from 'react-router-dom'; 
import styles from './CreditCard.module.css';

const AddCard = React.forwardRef((props, ref) => {
  const history = useHistory(); 

  const handleClick = () => {
    history.push('/createCard/146eec46-5d61-ef11-b9dd-0a9c6b6471bd'); //todo: remove hardcoded pos id
  };

  return (
    <div ref={ref} className={styles.addCard} onClick={handleClick}>
      <div className={styles.addCardIcon}>+</div>
      <span className={`${styles.addCardText} bold`}>Agregar una tarjeta</span>
      <span className={`${styles.addCardTextSecondary} text-secondary`}>Débito o crédito</span>
    </div>
  );
});

export default AddCard;
