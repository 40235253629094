export default (
  state = {
    items: [],
  },
  action,
) => {
  switch (action.type) {
    case 'SET_EXTRA_PRODUCT':
      return {
        ...state,
        items: action.payload,
      };
    case 'ADD_EXTRA_PRODUCT':
      //if (state.items.findIndex((p) => p.id === action.payload.id) > -1) return { ...state };

      return {
        ...state,
        items: [...state.items, action.payload],
      };

    case 'REMOVE_EXTRA_PRODUCT':
      let newList = state.items.filter((p) => p.id !== action.payload);
      return {
        ...state,
        items: newList,
      };
    default:
      return state;
  }
};
