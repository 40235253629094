import { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import { getQueryString } from '../../../helpers/url';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ReservationTypeCard from '../../../components/ReservationTypeCard/ReservationTypeCard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { resetReservation } from '../../../actions/reservationActions';

const MktEntityScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const mktEntityId = getQueryString('entityId');
  const [loading, setLoading] = useState(true);
  const [mktEntity, setMktEntity] = useState(null);

  const getCampaigns = async () => {
    try {
      const result = await welletContext.apis.tickets.post(`/mktcampaign/entity/${mktEntityId}`, {
        page: 1,
        pagesize: 10,
        lang: language
      });

      if(result.data.mktCampaigns.length === 1) {
        history.replace({
          pathname: 'mktcampaign',
          search: `?campaignId=${result.data.mktCampaigns[0].id}`,
        });
        return;
      }

      setMktEntity(result.data);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch(resetReservation());
    getCampaigns();
  }, []);

  const handleClick = (campaign) => {
    history.push({
      pathname: 'mktcampaign',
      search: `?campaignId=${campaign.id}`,
    });
  };

  return loading ? (
    <div className='loaderDiv'>
      <CircleSpinner
        className='spinner-app mx-auto'
        style={{ margin: 'auto' }}
        size={40}
        color='var(--color-font-primary)'
      />
    </div>
  ) : (
    <>
      <Header title={<div className='text-capitalize px-3'>{mktEntity.mktEntityName}</div>} />
      <ContentApp>
        {mktEntity.mktCampaigns.map((c) => (
          <div className='mt-3' key={c.id}>
            <ReservationTypeCard
              title={c.name}
              image={c.imageUrl}
              onHandleClick={() => handleClick(c)}
            />
          </div>
        ))}
      </ContentApp>
    </>
  );
};

export default MktEntityScreen;
