import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import { fetchTypesCampaign } from '../../../../services/campaigns/campaignsService';
import { getQueryString } from '../../../../helpers/url';
import { useSelector } from 'react-redux';
import { WelletContext } from '../../../../context/WelletContext';
import styles from './SelectCampaignType.module.css';
import { CircleSpinner } from 'react-spinners-kit';

import GroupMessageIcon from '../../../../components/icons/CampaignsType/GroupMessageIcon';
import StatusIcon from '../../../../components/icons/CampaignsType/statusIcon';
import BroadcastMessageIcon from '../../../../components/icons/CampaignsType/BroadcastMessageIcon';
import ChevronIcon from '../../../../components/icons/chevronIcon';
import HistoryIcon from '../../../../components/icons/CampaignsType/HistoryIcon';
import ReelIcon from '../../../../components/icons/CampaignsType/ReelIcon';
import PostIcon from '../../../../components/icons/CampaignsType/PostIcon';
import { useTranslation } from 'react-i18next';
import QrPrintedIcon from '../../../../components/icons/QRPrintedIcon';
import QrDigitalIcon from '../../../../components/icons/QrDigitalIcon';

const SelectCampaignType = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const type = getQueryString('type');
  const app = useSelector((state) => state.app);
  const [campaignTypes, setCampaignTypes] = useState(null);
  const [loading, setLoading] = useState(true);
  const social = getQueryString('social');

  const getTypes = async () => {
    setLoading(true);
    try {
      const response = await fetchTypesCampaign(welletContext, type, app.language);
      setCampaignTypes(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getIconAndDescriptionByName = (name) => {
    switch (name) {
      case 'STATUS':
        return {
          icon: <StatusIcon size='52px' />,
          description: t('campaignTypes.STATUS.description'),
        };
      case 'BROADCAST_MESSAGE':
        return {
          icon: <BroadcastMessageIcon size='52px' />,
          description: t('campaignTypes.BROADCAST_MESSAGE.description'),
        };
      case 'GROUP_MESSAGE':
        return {
          icon: <GroupMessageIcon size='52px' />,
          description: t('campaignTypes.GROUP_MESSAGE.description'),
        };
      case 'HISTORY':
        return {
          icon: <HistoryIcon size='52px' />,
          description: t('campaignTypes.HISTORY.description'),
        };
      case 'REEL':
        return {
          icon: <ReelIcon size='52px' />,
          description: t('campaignTypes.REEL.description'),
        };
      case 'PUBLICATION':
        return {
          icon: <PostIcon size='52px' />,
          description: t('campaignTypes.PUBLICATION.description'),
        };
      case 'QR_PRINTED':
        return {
          icon: <QrPrintedIcon size='52px' />,
          description: t('campaignTypes.QR_PRINTED.description'),
          value: 'QR_PRINTED',
        };
      case 'QR_DIGITAL':
        return {
          icon: <QrDigitalIcon size='52px' />,
          description: t('campaignTypes.QR_DIGITAL.description'),
          value: 'QR_DIGITAL',
        };
      default:
        return {
          icon: null,
          description: '',
        };
    }
  };

  const card = (campaignType, index) => {
    const { icon, description } = getIconAndDescriptionByName(campaignType.campaignType);

    return (
      <div
        key={index}
        className={styles.campaignCard}
        onClick={() =>
          history.push(
            `/create-campaign?social=${social}&type=${campaignType.campaignType}&id=${campaignType.id}&networkId=${type}`,
          )
        }
      >
        <div className={styles.containerImg}>{icon}</div>

        <div className='col'>
          <div className='fw-semibold'>{t(`campaignTypes.${campaignType.campaignType}.name`)}</div>
          <div className='text-secondary font-small mt-1'>{description}</div>
        </div>
        <div className='col-auto p-0 m-auto'>
          <ChevronIcon size={30} fillColor='var(--color-primary)' />
        </div>
      </div>
    );
  };

  useEffect(() => {
    getTypes();
  }, []);

  return (
    <div>
      <Header title={t('campaign.newCampaign')} />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <>
          <ContentApp>
            <div className='fw-semibold font-normal'>{t('selectCampaignType')}</div>
            <div className='text-secondary mt-2'>{t('selectCampaignTypeDescription')}</div>
            <div className='mt-3'>{campaignTypes.map((p, index) => card(p, index))}</div>
          </ContentApp>
        </>
      )}
    </div>
  );
};

export default SelectCampaignType;
