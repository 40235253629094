import React from 'react';

const WarningTriangleIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 15'
      fill='none'
    >
      <path
        d='M9.02546 0.626601L15.8414 13.1202C15.9453 13.3107 16 13.5268 16 13.7468C16 13.9668 15.9453 14.1829 15.8414 14.3734C15.7374 14.5639 15.588 14.7221 15.408 14.8321C15.2279 14.9421 15.0238 15 14.8159 15H1.18411C0.97625 15 0.772056 14.9421 0.592049 14.8321C0.412042 14.7221 0.262563 14.5639 0.158637 14.3734C0.0547105 14.1829 -1.32011e-06 13.9668 0 13.7468C1.32016e-06 13.5268 0.0547158 13.3107 0.158644 13.1202L6.97454 0.626601C7.43003 -0.208867 8.56917 -0.208867 9.02546 0.626601ZM8 10.5286C7.79063 10.5286 7.58984 10.6166 7.44179 10.7733C7.29375 10.93 7.21057 11.1425 7.21057 11.364C7.21057 11.5856 7.29375 11.7981 7.44179 11.9548C7.58984 12.1115 7.79063 12.1995 8 12.1995C8.20937 12.1995 8.41016 12.1115 8.55821 11.9548C8.70625 11.7981 8.78942 11.5856 8.78942 11.364C8.78942 11.1425 8.70625 10.93 8.55821 10.7733C8.41016 10.6166 8.20937 10.5286 8 10.5286ZM8 4.68029C7.80664 4.68032 7.62002 4.75545 7.47553 4.89143C7.33104 5.02741 7.23872 5.21479 7.2161 5.41801L7.21057 5.51576V8.85764C7.2108 9.07058 7.28784 9.2754 7.42597 9.43024C7.56409 9.58508 7.75287 9.67826 7.95373 9.69074C8.1546 9.70322 8.35238 9.63406 8.50668 9.49739C8.66097 9.36071 8.76013 9.16684 8.7839 8.95539L8.78942 8.85764V5.51576C8.78942 5.29418 8.70625 5.08168 8.55821 4.925C8.41016 4.76832 8.20937 4.68029 8 4.68029Z'
        fill='#1D9BF0'
      />
    </svg>
  );
};

export default WarningTriangleIcon;
