import React from 'react';
import Header from './Header';

const HeaderNoBackground = (props) => {
  const headerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 10, 
    background: 'transparent',
  };

  return (
    <div style={headerStyle}>
      <Header
        {...props}
        hasBackground={false}
      />
    </div>
  );
};

export default HeaderNoBackground;
