import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAssociateSaleSetup } from '../../../../actions/associatesActions';
import { useDispatch } from 'react-redux';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import Button from '../../../../components/Common/Button/Button';
import ScanFaceScreen from '../ScanScreen/ScanFaceScreen';
import { useTranslation } from 'react-i18next';
import FixedButton from '../../../../components/Common/FixedButton/FixedButton';
import ProcessingScreen from '../../../Common/ProcessingScreen';
import { WelletContext } from '../../../../context/WelletContext';

const SelfieValidateKyc = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const welletContext = useContext(WelletContext);
  const [selfie, setSelfie] = useState(null);
  const [loading, setLoading] = useState(false);
  const jwt = useSelector((state) => state.associates.jwt);

  const handleCaptureFace = (img) => {
    setSelfie(img);
  };

  useEffect(() => {
    if (history.location.state === undefined) {
      history.push('/kyc-start');
    }
  }, []);

  const handleConfirmFace = async () => {
    setLoading(true);
    let response = null;
    try {
      response = await welletContext.apis.tickets.put('users/kyc', {
        document: history.location.state.frontImage,
        documentback: history.location.state.backImage,
        face: selfie,
      });

      dispatch(getAssociateSaleSetup(jwt));

      history.push({
        pathname: '/kyc-validate-finish',
        state: { response: response },
        search:
          `?success=${response.data.success}` +
          (response.data.errors && response.data.errors.length > 0
            ? `&error=${response.data.errors[0]}`
            : ''),
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  const handleReset = () => {
    setSelfie(null);
  };
  return (
    <>
      {loading && loading ? (
        <ProcessingScreen title={t('wait')} subtitle={t('verifyingAccount')} />
      ) : (
        <>
          <Header pathName={'/kyc-start'} />
          <ContentApp background={true}>
            <div>
              {selfie ? (
                <div className='container p-0 text-center'>
                  <div className='font-medium bold' style={{ color: 'var(--color-font-primary)' }}>
                    {t('kyc.confirmPicture')}
                  </div>
                  <div className='mt-2 text-secondary'>{t('kyc.selfieConfirm')}</div>

                  <div
                    className='camera-container validate mt-4'
                    style={{ width: '100%', height: '315px', overflow: 'hidden' }}
                  >
                    <div className='mask-face' style={{ transform: 'scaleX(-1)' }}>
                      <img src={selfie} alt='Captured face' />
                      <div className='face'></div>
                    </div>
                  </div>

                  <div className='text-center'>
                    <div
                      onClick={handleReset}
                      className='mt-4'
                      style={{
                        color: 'var(--color-primary)',
                        zIndex: 30,
                        position: 'absolute',
                        right: 0,
                        left: 0,
                        bottom: '-13%',
                      }}
                    >
                      {t('kyc.tryAgain')}
                    </div>
                    <FixedButton>
                      <Button
                        text={t('confirm')}
                        size='large'
                        onClick={handleConfirmFace}
                        fontSize={'17px'}
                        icon={true}
                        typeIcon='arrow'
                        iconColor='#000'
                        className='btn-primary'
                      />
                    </FixedButton>
                  </div>
                </div>
              ) : (
                <ScanFaceScreen onCaptureFace={handleCaptureFace} />
              )}
            </div>
          </ContentApp>
        </>
      )}
    </>
  );
};
export default SelfieValidateKyc;
