import React, { useContext, useState } from 'react';
import Header from '../../components/Common/Header/Header';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../components/ContentApp';
import InputFormBorder from '../../components/Common/InputFormBorder/InputFormBorder';
import { WelletContext } from '../../context/WelletContext';
import useForm from '../../helpers/useForm';
import Button from '../../components/Common/Button/Button';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import { getQueryString } from '../../helpers/url';
import { useSelector } from 'react-redux';

const SignUpCreateNickname = ({ history }) => {
  const { t } = useTranslation();
  const email = getQueryString('email');
  const phone = getQueryString('phone');
  const token = getQueryString('token');
  const countryCode = useSelector((state) => state.app.countryCode);

  const [errorNickname, setErrorNickname] = useState('');
  const [verifyingUser, setVerifyingUser] = useState(false);
  const welletContext = useContext(WelletContext);

  function validate(values) {
    let errors = {};
    const validChars = /^[a-zA-Z0-9_]+$/;
    if (!values.nickname) {
      errors.nickname = 'Ingrese Alias';
    } else if (!validChars.test(values.nickname)) {
      errors.nickname = t('nickname.invalidnickName');
    }
    return errors;
  }

  const sendForm = async () => {
    if (Object.keys(errors).length > 0) return;

    setVerifyingUser(true);
    try {
      const result = await welletContext.apis.tickets.post(
        `users/checkNickname/${values.nickname}`,
      );
      if (result.data) {
        setErrorNickname(t('nickname.alreadyExists'));
      } else {
        history.push({
          pathname: '/sign-up-create-password',
          search: `?&phone=${phone}&countryCode=${countryCode}&token=${token}&email=${email}&nickName=${values.nickname}`,
        });
      }
      setErrorNickname(result.data === true ? t('nickname.alreadyExists') : '');

      setVerifyingUser(false);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.errorCode) {
        switch (error.response.data.errorCode) {
          case 'NICKNAME_HAS_INVALID_CHARACTERS':
            setErrorNickname(t('nickname.invalidnickName'));
            break;
          case 'NICKNAME_ALREADY_EXISTS':
            setErrorNickname(t('nickname.alreadyExists'));
            break;
          default:
            setErrorNickname(t('nickname.genericError')); 
        }
      } else {
        setErrorNickname(t('nickname.genericError'));
      }
      setVerifyingUser(false);
    }
  };

  const onChange = (e) => {
    setErrorNickname('');
    handleChange(e);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <div>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
          {t('nickname.enterNickname')}
        </div>
        <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
          {t('explanationNickname')}
        </div>
        <form onSubmit={handleSubmit}>
          <div className='mt-3'>
            <InputFormBorder
              type='text'
              name='nickname'
              value={values.nickname}
              error={errors.nickname || errorNickname}
              onChange={onChange}
              placeholder={t('nickname.enterNicknameInput')}
              forceLowecase={true}
              maxLength={24}
            />
          </div>

          <Button
            className='btn-primary mt-3'
            size='large'
            fontSize={'18px'}
            text={
              verifyingUser ? (
                <div className='d-flex justify-content-center'>
                  <Spinner size={25} color='#000' />
                </div>
              ) : (
                t('continue')
              )
            }
            icon={verifyingUser ? false : true}
            typeIcon='arrow'
            iconColor='#000'
            disabled={!values.nickname}
          />
        </form>
      </ContentApp>
    </div>
  );
};

export default SignUpCreateNickname;