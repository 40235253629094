import React from 'react';
import styles from './VoucherList.module.css';
import { useSelector } from 'react-redux';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';

import VoucherTicket from './VoucherTicket';

const VoucherList = () => {
  const app = useSelector((state) => state.app);

  return (
    <>
      <Header></Header>
      <ContentApp>
        <div className={styles.container} style={{ display: 'flex', alignItems: 'center' }}>
          <div
            className={styles.all}
            style={{
              fontSize: '14px',
              fontWeight: '400',
              border: '1px solid #242422',
              padding: '4px 8px',
            }}
          >
            <div className={styles.circle}>12</div>
            Filter: All
          </div>
        </div>

        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
        <VoucherTicket></VoucherTicket>
      </ContentApp>
    </>
  );
};

export default VoucherList;
