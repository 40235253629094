import React from 'react';

const PostIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 52 52'
      fill='none'
    >
      <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
      <g clipPath='url(#clip0_397_2158)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.6349 13.305C16.139 13.305 13.305 16.139 13.305 19.6349V32.3651C13.305 35.861 16.139 38.695 19.6349 38.695H32.3651C35.861 38.695 38.695 35.861 38.695 32.3651V19.6349C38.695 16.139 35.861 13.305 32.3651 13.305H19.6349ZM11.1621 19.6349C11.1621 14.9555 14.9555 11.1621 19.6349 11.1621H32.3651C37.0445 11.1621 40.8379 14.9555 40.8379 19.6349V32.3651C40.8379 37.0445 37.0445 40.8379 32.3651 40.8379H19.6349C14.9555 40.8379 11.1621 37.0445 11.1621 32.3651V19.6349Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26 19.4023C26.5918 19.4023 27.0714 19.882 27.0714 20.4738V31.5264C27.0714 32.1181 26.5918 32.5978 26 32.5978C25.4083 32.5978 24.9286 32.1181 24.9286 31.5264V20.4738C24.9286 19.882 25.4083 19.4023 26 19.4023Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.4022 26.0001C19.4022 25.4084 19.8819 24.9287 20.4737 24.9287H31.5263C32.118 24.9287 32.5977 25.4084 32.5977 26.0001C32.5977 26.5919 32.118 27.0716 31.5263 27.0716H20.4737C19.8819 27.0716 19.4022 26.5919 19.4022 26.0001Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_397_2158'>
          <rect width='30' height='30' fill='white' transform='translate(11 11)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PostIcon;
