import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import CampaignCard from '../../../../components/Common/CampaignCard/CampaignCard';
import { WelletContext } from '../../../../context/WelletContext';
import { fetchAllSocialNetworks } from '../../../../services/campaigns/campaignsService';
import SkeletonNewCampaign from '../../../../components/Skeletons/SkeletonNewCampaign';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NewCampaign = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(true);
  const [socialNetworks, setSocialNetworks] = useState(null);
  const language = useSelector((state) => state.app.language);

  const getAllSocialNetworks = async () => {
    setLoading(true);
    try {
      const response = await fetchAllSocialNetworks(welletContext, language);
      setSocialNetworks(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllSocialNetworks();
  }, []);

  return (
    <div>
      <Header title={t('campaign.newCampaign')} pathName={'/campaigns'} />
      <ContentApp>
        <div className='bold font-medium '>{t('campaign.createCampaign')}</div>
        <div className='text-secondary mb-3'>{t('campaign.selectChannel')}</div>

        {loading ? (
          SkeletonNewCampaign()
        ) : (
          <>
            {socialNetworks?.map((c, index) => (
              <div className='mt-2' key={index}>
                <CampaignCard
                  campaign={c}
                  path={`/select-campaign?type=${c.id}&social=${c.name}`}
                  history={history}
                  showComingSoon
                />
              </div>
            ))}
          </>
        )}
      </ContentApp>
    </div>
  );
};

export default NewCampaign;
