import React from 'react';
import Money from '../Common/Money/Money';
import { useTranslation } from 'react-i18next';

const EarningsNotPaid = ({ amount, history }) => {
  const { t } = useTranslation();
  return (
    <div
      className='p-3 mt-2 text-white card'
      style={{ borderRadius: '7px' }}
      onClick={() => history.push('/my-money/detail')}
    >
      <div className='row semi-bold align-items-center fade-up'>
        <div className='col pr-0'>{t('earningsPending')}</div>
        <div className='col-auto bold '>
          <Money value={amount} decimal={false} lowDecimals symbolSize='.85rem' />
        </div>
      </div>
    </div>
  );
};

export default EarningsNotPaid;
