export const downloadBlob = (
  result,
  defaultFilename = 'content.dat',
  mime = 'application/octet-stream',
) => {
  if (window && result && result.data) {
    // get filename
    const disposition = result.headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    const filename =
      matches != null && matches[1]
        ? decodeURIComponent(matches[1].replace(/['"]/g, ''))
        : defaultFilename;

    // extract user agent
    const userAgent = window.navigator.userAgent.toLowerCase();
    const ios = /iphone|ipod|ipad|crios|fxios/.test(userAgent);
    const chrome = /crios/.test(userAgent);

    // create blob
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const blob = new Blob([result.data], { type: mime });
    const blobURL = window.URL.createObjectURL(blob);

    // if its ipad or iphone
    if (ios) {
      var reader = new FileReader();
      reader.filename = filename;
      reader.onload = () => {
        if (chrome) {
          window.open(blobURL);
        } else {
          window.location.href = blobURL;
        }
      };
      reader.readAsDataURL(blob, filename);
      return;
    }

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE doesn't allow using a blob object directly as link href.
      // Workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
      return;
    }

    // Other browsers
    // Create a link pointing to the ObjectURL containing the blob
    const link = document.createElement('a');
    link.href = blobURL;
    link.style.display = 'none';
    link.setAttribute('download', filename);
    link.download = filename;
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof link.download === 'undefined') {
      link.setAttribute('target', '_blank');
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }
};

export const download = async (url, fileName) => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad|crios|fxios/.test(userAgent);

  if (ios) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const response = await fetch(url);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
};


