import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './QrCampaign.module.css';
import { WelletContext } from '../../../../context/WelletContext';
import { CircleSpinner } from 'react-spinners-kit';
import Line from '../../../../components/Common/Line/Line';
import ShareIcon from '../../../../components/icons/ShareIcon';
import Qr from '../../../../components/Common/Qr/Qr';

const QrCampaign = () => {
  const { t } = useTranslation();
  const company = useSelector((state) => state.app);
  const welletContext = useContext(WelletContext);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);

  const getLink = async () => {
    try {
      const response = await welletContext.apis.tickets.get(`/Campaign/affiliate/link`);
      setLink(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLink();
  }, []);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: company.name,
          url: link,
        });
      } else {
        console.warn('Web Share API not supported in this browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <ContentApp>
          <div className='card align-items-center p-3'>
            <div className={styles.imageContainer}>
              <img src={company.logoType} alt='logo' />
            </div>
            <div className='font-large fw-semibold my-2 text-center'>{company.titleWeb}</div>

            <Line color='#353535' className='w-100 my-4' />
            <Qr
              qrUrl={link}
              logo={
                company.name === 'Xcaret'
                  ? 'https://cdn1.wellet.dev/xcaret/xcaret-logo.jpg'
                  : company.logoType
              }
              welletContext={welletContext}
              logoBorder='SQUARE'
            />
            <div className='font-large text-center fw-semibold m-3'>
              {t('scanToMakeReservation')}
            </div>
          </div>

          <div className='text-center text-secondary font-small mt-3' onClick={() => handleShare()}>
            {t('shareLinkToBook')}
            <ShareIcon size={25} color={'#7e7e7e'} styles={{ marginBottom: '4px' }} />
          </div>
        </ContentApp>
      )}
    </>
  );
};

export default QrCampaign;
