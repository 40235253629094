import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import styles from './AnalyticsDetail.module.css';
import Money from '../../../../components/Common/Money/Money';
import {
  fetchCampaignById,
  fetchCampaignOccassion,
  fetchCampaignReservations,
  fetchCampaignReservationsStats,
  fetchCampaignStatistics,
} from '../../../../services/campaigns/campaignsService';
import { WelletContext } from '../../../../context/WelletContext';
import { getQueryString } from '../../../../helpers/url';
import {
  campaignDate,
  formatDateLong,
  getDateFromOffset,
  getLocale,
} from '../../../../helpers/dates';
import { useSelector } from 'react-redux';
import { Column2d, Pie2d, TimeLine } from '../../../../components/Charts';
import { CircleSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';
import StarIcon from '../../../../components/icons/StarIcon';
import Button from '../../../../components/Common/Button/Button';
import NoCampaignsIcon from '../../../../components/icons/NoCampaignIcon';
import ProductModal from '../../../../components/Common/ProductModal/ProductModal';
import { DateRange } from 'react-date-range';
import CursorIcon from '../../../../components/icons/CursorIcon';

import Line from '../../../../components/Common/Line/Line';
import WarningTriangleIcon from '../../../../components/icons/WarningTriangleIcon';
import { format, parse } from 'date-fns';
import ActivitiesHome from '../../../../components/Activities/ActivitiesHome/ActivitiesHome';
import ActivityReservationItem from '../../../../components/Activities/ActivityReservationItem.js/ActivityReservationItem';

const AnalyticsDetails = ({ history }) => {
  const { t } = useTranslation();
  const id = getQueryString('id');
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);
  const welletContext = useContext(WelletContext);
  const [campaignInfo, setCampaignInfo] = useState(null);
  const [campaignStatics, setCampaignStatics] = useState(null);
  const [campaignOccassion, setCampaignOccassion] = useState(null);
  const [campaignReservations, setCampaignReservations] = useState(null);
  const [campaignReservationsStats, setCampaignReservationsStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const timeLineData = formatPosObjects(campaignReservationsStats) ?? [];

  const user = useSelector((state) => state.associates.user);
  const fetchData = async () => {
    const body = {
      CampaignId: id,
      ToDateLocal: format(initialDates[0].endDate, 'yyyy-MM-dd'),
      FromDateLocal: format(initialDates[0].startDate, 'yyyy-MM-dd'),
    };

    const bodyReservations = {
      FilterDate: 'ANALYTICS',
      skip: 0,
      limit: 6,
      From: format(initialDates[0].startDate, 'yyyy-MM-dd'),
      To: format(initialDates[0].endDate, 'yyyy-MM-dd'),
    };

    setLoading(true);

    try {
      const responseCampaign = await fetchCampaignById(welletContext, 'es', id);
      setCampaignInfo(responseCampaign.data);

      const responseStatics = await fetchCampaignStatistics(welletContext, body);
      setCampaignStatics(responseStatics.data);

      const responseOccassion = await fetchCampaignOccassion(welletContext, body);
      setCampaignOccassion(responseOccassion.data);

      const responseReservationsStats = await fetchCampaignReservationsStats(welletContext, body);
      setCampaignReservationsStats(responseReservationsStats.data);

      const responseReservations = await fetchCampaignReservations(
        welletContext,
        bodyReservations,
        id,
        'marketing',
      );
      setCampaignReservations(responseReservations.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getInitialDates = () => {
    const toDate = new Date(getDateFromOffset(user.companyUtcMinutesOffset));
    toDate.setHours(0, 0, 0, 0);
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);

    return [
      {
        startDate: fromDate,
        endDate: toDate,
        key: 'selection',
      },
    ];
  };

  const [initialDates, setInitialDates] = useState(getInitialDates());

  const handleSetDateRangeToToday = () => {
    const today = new Date();
    const campaignCreationDate = new Date(campaignInfo.createdDateTime);

    setInitialDates([
      {
        startDate: campaignCreationDate,
        endDate: today,
        key: 'selection',
      },
    ]);

    fetchData();
  };

  function formatPosObjects(posObjects) {
    if (posObjects?.group === 'DAY') {
      if (posObjects?.items?.length > 15) {
        const modifiedObjects = posObjects.items.slice();

        for (let i = 1; i < modifiedObjects?.length - 1; i++) {
          if ((i + 1) % 3 !== 0) {
            delete modifiedObjects[i].group;
          }
        }

        return modifiedObjects;
      } else {
        return posObjects?.items;
      }
    } else {
      return posObjects?.items;
    }
  }

  const card = (campaign) => {
    return (
      <div className={styles.campaignCard}>
        <div className={styles.containerImg}>
          <img src={campaign.logo || campaign.showLogo} alt='logo' className={styles.logoImg} />
        </div>

        <div className='col'>
          <div className='text-secondary  bold'> {campaign.campaignName}</div>
          <div className='bold font-medium'> {campaign.showName}</div>

          <div className='text-secondary font-small mt-2'>
            {t('target')}: <span className='bold'>{t('sales')}</span>
          </div>

          <div className='text-secondary font-extra-small mt-1'>
            {t('createdDate')}: {formatDateLong(campaign.createdDateTime, locale)}
          </div>
        </div>
      </div>
    );
  };

  const getDataForColumn = () => {
    const result = campaignStatics.amountReservationTypes?.map((a) => ({
      value: a.amount,
      label: `${a.amount}\n${t('reservationType.' + a.reservationType)}`,
    }));
    return result;
  };

  const handleApplyDateRange = () => {
    fetchData();
    setShowCalendar(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header title='Analytics' />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <>
          <ContentApp>
            {card(campaignInfo)}
            <div className='row mt-2 align-items-center justify-content-center'>
              <div className='col pr-1'>
                <Button
                  className={styles.calendarButton}
                  onClick={() => setShowCalendar(true)}
                  size='medium'
                  text={
                    <>
                      <NoCampaignsIcon
                        size={20}
                        color1='#000'
                        color2='#000'
                        color3='#1D9BF0'
                        stroke1='#000'
                        styles={{
                          marginRight: '5px',
                        }}
                      />
                      {campaignDate(initialDates[0].startDate, locale)} -{' '}
                      {campaignDate(initialDates[0].endDate, locale)}{' '}
                    </>
                  }
                />
              </div>
              <div className='col-auto pl-0'>
                <Button
                  backgroundColor='#000'
                  hasBorder
                  className={`col-auto text-center text-secondary ${styles.secondButton}`}
                  text='Hasta la fecha'
                  size='medium'
                  onClick={handleSetDateRangeToToday}
                />
              </div>
            </div>
            <div className='card mt-2 p-3'>
              <div className='font-1rem bold mb-2'>{t('commissionsEarnings')}</div>
              <div className='row'>
                <div className='col-6 pr-1'>
                  <div className='font-small text-secondary'>{t('byCommissions')}</div>
                  <div className='font-extra-large bold mt-1'>
                    <Money value={campaignStatics.totalCommission} />
                  </div>
                </div>
                <div className='col-6' style={{ borderLeft: '1px solid #212121' }}>
                  <div className='font-small text-secondary'>Prom. por reserva</div>
                  <div className='font-extra-large bold mt-1'>
                    <Money value={campaignStatics.avgCommission} />
                  </div>
                </div>
              </div>
            </div>
            <div className='card mt-2 p-3'>
              <div className='font-normal bold mb-2'>{t('options.reserves')}</div>
              <div className='row'>
                <div className='col-4 pr-1' style={{ borderRight: '1px solid #212121' }}>
                  <div className='font-small text-secondary'>{t('completed')}</div>
                  <div className='font-extra-large bold'>{campaignStatics.totalCompleted}</div>
                </div>
                <div className='col-4' style={{ borderRight: '1px solid #212121' }}>
                  <div className='font-small text-secondary'>{t('options.cancelled')}</div>
                  <div className='font-extra-large bold'>{campaignStatics.totalCancelled}</div>
                </div>
                <div className='col-4'>
                  <div className='font-small text-secondary'>{t('total')}</div>
                  <div className='font-extra-large bold'>{campaignStatics.totalReservation}</div>
                </div>
              </div>
            </div>
            <div className='mt-2'>
              <TimeLine
                data={timeLineData?.map((c) => {
                  const date = parse(c?.group, 'MM/dd/yyyy', new Date());

                  return {
                    label: c.group,
                    value: c.reservation,
                    toolText: c.group,
                  };
                })}
                xAxis={`${t('completedReservesFrom', {
                  startDate: format(initialDates[0].startDate, 'dd MMM', {
                    locale,
                  }),
                  endDate: format(initialDates[0].endDate, 'dd MMM', {
                    locale,
                  }),
                })}`}
              />
            </div>
            <div className='card p-3 mt-2 '>
              <div className='font-normal fw-semibold'>{t('reservationsType')}</div>
              <div className='mt-2'>
                <Column2d data={getDataForColumn()} />
              </div>
              {campaignStatics.biggestTypeName && (
                <>
                  <Line color='#212121' />
                  <div className='mt-2 row align-items-center'>
                    <div className='col-auto pr-0'>
                      <StarIcon size={16} styles={{ marginBottom: '3px' }} />
                    </div>
                    <div className='col font-smaller'>
                      {t('analyticsTexts.thePercentageOfReserves', {
                        percentage: campaignStatics.biggestTypePercentage,
                        type: t(`reservationType.${campaignStatics.biggestTypeName}`),
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className='card p-3 mt-2 flex-row bold'>
              <div className='col-auto p-0'>{t('totalPeople')}</div>
              <div className='col p-0 text-right'>{campaignStatics.attendendPaxs}</div>
            </div>
            <div className='card p-3 mt-2 bold'>
              <div>{t('analyticsTexts.reservesOccassions')}</div>{' '}
              <Pie2d
                data={campaignOccassion.occassions
                  ?.sort((a, b) => b?.percentage - a?.percentage)
                  ?.map((o) => ({
                    label: t(`typeOccasions.${o.occassion}`),
                    value: `${o.percentage}`,
                  }))}
              />
            </div>
            <div className='card p-3 mt-2'>
              <div className='bold d-flex align-items-center justify-content-between'>
                <div className='mr-2'>
                  {t('analyticsTexts.linkAccess')}{' '}
                  <CursorIcon styles={{ marginBottom: '4px', marginLeft: '4px' }} size={20} />
                </div>
                <span>{campaignOccassion.totalVisit}</span>
              </div>

              <div className='mt-2 row'>
                <div className='col-auto pr-0'>
                  <WarningTriangleIcon color={'var(--color-primary)'} size={15} />
                </div>
                <div className='col font-smaller'>
                  {t('analyticsTexts.accessCompleted', {
                    percentage: campaignOccassion.percentajeVisit,
                  })}
                  <br />
                  {/* <u className='text-primary'>{t('analyticsTexts.increaseNumber')}</u> */}
                </div>
              </div>
            </div>
            {campaignReservations.length > 0 && (
              <div className='mt-2'>
                <ActivitiesHome
                  title={<div className='bold'>{t('yourLatestOperations')}</div>}
                  linkTitle={campaignReservations?.length >= 5 ? t('viewAllReserves') : null}
                  pathName={`/campaign-reservations?fromDate=${format(
                    initialDates[0].startDate,
                    'yyyy-MM-dd',
                  )}
                  &toDate=${format(initialDates[0].endDate, 'yyyy-MM-dd')}`}
                  history={history}
                  children={
                    <div className='p-2'>
                      {campaignReservations?.slice(0, 5).map((campaign, index) => (
                        <ActivityReservationItem
                          activity={campaign}
                          handleClick={() =>
                            history.push(`operation-details?id=${campaign.reservationId}`)
                          }
                          key={index}
                          className='px-2 py-2'
                          date={campaign.reservationDateLocalTime}
                          isLastItem={index === campaignReservations.length - 1}
                          index={index}
                          amount={campaign}
                          isCampaign
                        />
                      ))}
                    </div>
                  }
                />
              </div>
            )}
            <ProductModal
              open={showCalendar}
              animationClass='fade-up-horizontal'
              showCloseButton={false}
              customStyle={{ position: 'absolute', top: '15%' }}
            >
              <DateRange
                onChange={(item) => setInitialDates([item.selection])}
                ranges={initialDates}
                months={1}
                direction='horizontal'
                locale={locale}
                showDateDisplay={false}
              />

              <div
                className='d-flex justify-content-end mb-2 mr-3'
                style={{ backgroundColor: '#fff' }}
              >
                <Button
                  size={'small'}
                  backgroundColor='var(--color-light-gray)'
                  color='#000'
                  text={<div className='light text-white'>{t('cancel')}</div>}
                  className='mr-3'
                  onClick={() => setShowCalendar(false)}
                />
                <Button
                  onClick={handleApplyDateRange}
                  size={'small'}
                  className='btn-secondary light text-white'
                  text={t('apply')}
                  type='button'
                />
              </div>
            </ProductModal>
          </ContentApp>
        </>
      )}
    </div>
  );
};

export default AnalyticsDetails;
