import { Box, Skeleton } from '@mui/material';
import React from 'react';
import styles from './skeleton.module.css';
export const SkeletonPoints = () => {
  return (
    <div className='mt-2'>
      <Skeleton
        variant='rectangular'
        sx={{
          bgcolor: 'grey.900',
          width: '100%',
          borderRadius: '.4rem',
          height: 513,
        }}
      />
    </div>
  );
};

export const SkeletonCommissions = () => {
  const numberOfSkeletons = 5;
  const skeletons = Array.from({ length: numberOfSkeletons });
  return (
    <Box
      className='mt-3'
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
     {skeletons.map((_, index) => (
        <span key={index} className={styles.lineSkeleton}>
          <Skeleton
          height={40}
            sx={{
              bgcolor: 'grey.900', 
            }}
            width="100%" 
          />
        </span>
      ))}
    </Box>
  );
};
export const SkeletonTotalPoints = () => {
  return (
    <Skeleton
      variant='rectangular'
      sx={{
        bgcolor: 'grey.900',
        width: '100%',
        borderRadius: '.4rem',
        height: 22,
      }}
    />
  );
};
