import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import styles from './SignUp.module.css';
import { useSelector } from 'react-redux';
import isLocalStorageAvailable from '../../helpers/verificationLocalStorage';

const SignUpTerms = ({ history }) => {
  const { t } = useTranslation();
  const [policyChecked, setPolicyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const app = useSelector((state) => state.app);

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      const storedPolicyChecked = localStorage.getItem('policyChecked') === 'true';
      const storedTermsChecked = localStorage.getItem('termsChecked') === 'true';
      setPolicyChecked(storedPolicyChecked);
      setTermsChecked(storedTermsChecked);
    }
  }, []);

  const handlePolicyCheck = () => {
    const newPolicyChecked = !policyChecked;
    setPolicyChecked(newPolicyChecked);
    if (isLocalStorageAvailable()) {
      localStorage.setItem('policyChecked', newPolicyChecked);
    }
  };

  const handleTermsCheck = () => {
    const newTermsChecked = !termsChecked;
    setTermsChecked(newTermsChecked);
    if (isLocalStorageAvailable()) {
      localStorage.setItem('termsChecked', newTermsChecked);
    }
  };

  const handleValidation = () => {
    return policyChecked && termsChecked;
  };

  const handleContinue = () => {
    setPolicyChecked(false);
    setTermsChecked(false);
    if (isLocalStorageAvailable()) {
      localStorage.removeItem('policyChecked');
      localStorage.removeItem('termsChecked');
    }
    history.push('/sign-up');
  };

  return (
    <>
      <Header />
      <ContentApp>
        <div className='font-medium bold text-white terms-and-conditions'>{t('terms.title')}</div>
        <div className='text-white bold mt-5'>{t('privacyStatement')}</div>
        <div className='text-secondary font-tiny mt-1'>
          {t('terms.description1')}{' '}
          <u className='text-primary' onClick={() => history.push('/privacy-policy')}>
            {t('privacyStatement')}.
          </u>
          <div className='mt-4 row'>
            <label className='checkbox-container ml-3'>
              <input
                type='checkbox'
                style={{ width: '20px', height: '20px' }}
                checked={policyChecked}
                onChange={handlePolicyCheck}
              />
              <span className='checkmark' style={{ marginLeft: '' }}></span>
            </label>
            <div className='col ml-3'>{t('terms.acceptTerms1')}</div>
          </div>
        </div>
        <div className='text-white bold mt-4'>{t('termsAndConditions')}</div>
        <div className='text-secondary font-tiny mt-1'>
          {t('')}
          {t('terms.description2', {
            organization: app.name,
          })}{' '}
          <u className='text-primary' onClick={() => history.push('/terms-and-conditions')}>
            {t('termsAndConditions')}.
          </u>
          <div className='mt-4 row'>
            <label className='checkbox-container ml-3'>
              <input
                type='checkbox'
                style={{ width: '20px', height: '20px' }}
                checked={termsChecked}
                onChange={handleTermsCheck}
              />
              <span className='checkmark' style={{ marginLeft: '' }}></span>
            </label>
            <div className='col ml-3'>{t('terms.acceptTerms2')}</div>
          </div>
        </div>
        <Button
          className={`${styles.btn} mt-5`}
          size='large'
          text={t('continue')}
          iconColor='#000'
          icon
          typeIcon='arrow'
          onClick={handleContinue}
          disabled={!handleValidation()}
        />
      </ContentApp>
    </>
  );
};

export default SignUpTerms;
