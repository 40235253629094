import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SkeletonTotalPoints } from '../Skeletons/SkeletonPoints';
import Line from '../Common/Line/Line';
import { useTranslation } from 'react-i18next';
import { getCategory } from '../../helpers/category';
import {formatDateShort, getLocale } from '../../helpers/dates';
import { useSelector } from 'react-redux';

const HeaderUserPoints = ({ user, category, expirationDate, points }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const app = useSelector((state) => state.app);
  const locale = getLocale(app.language);

  return (
    <div className='p-3 card'>
      <div className='pr-1 lh-title font-large bold'>{`${user.firstName} ${user.lastName}`}</div>
      <div className='row'>
        <div className='col'>
          <div className='font-small text-secondary mt-1 text-capitalize'>{t('status')}</div>
          <div className='bold'>{category}</div>
        </div>
        <div
          className=' d-flex align-items-center text-right pl-0 pr-2'
          onClick={() => history.push('/points')}
        >
          <span className='mr-1 text-right'>
            <div className='font-small text-secondary'>{t('validThru')}</div>
            <div className='bold'>

              {expirationDate && formatDateShort(expirationDate, locale)}
        
            </div>
          </span>
        </div>
      </div>
      <Line color='#212121' className='my-3' />
      <div className='d-flex align-items-center justify-content-between'>
        <div className='bold'>
          {t('classificationPoints')}
        </div>
        <div className='font-medium bold ml-1 fade-up'>
          {isNaN(points) ? SkeletonTotalPoints() : points}
        </div>
      </div>
    </div>
  );
};

export default HeaderUserPoints;
