import React from 'react';
import styles from './button.module.css';

const ButtonReservation = ({
  leftContent,
  rightContent,
  size,
  type = 'submit',
  onClick,
  className,
  disabled = false,
  backgroundColor = 'var(--color-primary)',
  fixed = false,
}) => {
  const sizeConfig = {
    large: {
      widthConfig: '100%',
      height: '4rem',
    },
    normal: {
      widthConfig: '100%',
      height: '3.2rem',
      fontSizeConfig: '1rem',
    },
  };

  const { height, widthConfig, fontSizeConfig } = sizeConfig[size];

  const buttonStyles = {
    width: widthConfig,
    height,
    fontSize: fontSizeConfig,
    backgroundColor: backgroundColor,
  };

  return (
    <button
      style={buttonStyles}
      className={`${styles.buttonWellet} bold ${className ? className : null}
      ${disabled && styles.disabledOpacity}
      ${fixed && styles.buttonWelletFixed}
      `}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={`row m-0`}>
        <div className='col-auto text-left'>{leftContent}</div>
        <div className='col text-right my-auto pl-0'>{rightContent}</div>
      </div>
    </button>
  );
};

export default ButtonReservation;
