import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getQueryString } from '../../helpers/url';
import { WelletContext } from '../../context/WelletContext';
import { useDispatch, useSelector } from 'react-redux';
import { errorText } from '../../helpers/login';
import { useTranslation } from 'react-i18next';
import { setCanRefresh } from '../../actions/appActions';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import useForm from '../../helpers/useForm';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import 'react-phone-number-input/style.css';
import InputFormBorder from '../../components/Common/InputFormBorder/InputFormBorder';

const SignUpPhoneValidationScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const phone = getQueryString('phone');
  const token = getQueryString('token');
  const countryCode = getQueryString('countryCode');
  const welletContext = useContext(WelletContext);
  const tenantId = useSelector((state) => state.app.tenantId);
  const dispatch = useDispatch();
  const [verifyingUser, setVerifyingUser] = useState(false);
  const [errorUser, setErrorUser] = useState(undefined);

  useEffect(() => {
    dispatch(setCanRefresh(false));
  }, []);

  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = t('emailError.EMPTY_EMAIL');
    } else if (!values.email.includes('@')) {
      errors.email = t('emailError.EMAIL_@');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = t('emailError.INVALID_EMAIL');
    }
    if (values.email && !values.emailConfirmation) {
      errors.emailConfirmation = t('emailError.EMAIL_NOT_CONFIRMED');
    } else if (values.email !== values.emailConfirmation) {
      errors.emailConfirmation = t('emailError.EMAIL_NOT_MATCH');
    }

    return errors;
  }

  const sendForm = async () => {
    setVerifyingUser(true);
    try {
      const result = await welletContext.apis.tickets.post(`users/Available`, {
        email: values.email?.trim(),
        tenantId: tenantId,
      });
      if (result.data.isAvailable) {
        errorText(result.data.availableMessage, setErrorUser, t);
      } else {
        history.push({
          pathname: '/sign-up-create-nickname',
          search:
            '?phone=' +
            phone +
            '&countryCode=' +
            countryCode +
            '&token=' +
            token +
            '&email=' +
            values.email +
            '&nickName=' +
            values.nickName,
        });
      }
      setVerifyingUser(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (e) => {
    setErrorUser('');
    handleChange(e);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <>
      <Header />
      <ContentApp background={true}>
        <div className=''>
          <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
            {t('enterEmail')}
          </div>
          <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
            {t('signUp.subtitle3')}
          </div>
          <form onSubmit={handleSubmit}>
            <div className='mt-4 font-normal'>
              <InputFormBorder
                name='email'
                type='email'
                value={values.email?.trim()}
                error={errors.email}
                placeholder={t('enterEmail')}
                onChange={onChange}
                forceLowecase={true}
                label='E-mail'
              />
              <div className='mt-4'>
                <InputFormBorder
                  name='emailConfirmation'
                  type='email'
                  value={values.emailConfirmation?.trim()}
                  error={errors.emailConfirmation}
                  placeholder={t('reEnterEmail')}
                  onChange={onChange}
                  forceLowecase={true}
                  label={t('signUp.confirmEmail')}
                />
              </div>
            </div>
            <div className='mt-3'>{errorUser && <ErrorBox title={errorUser} />}</div>
            <div className='mt-4'>
              <Button
                className='btn-primary mt-5'
                size='large'
                fontSize={'18px'}
                text={
                  verifyingUser ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner size={25} color='#000' />
                    </div>
                  ) : (
                    t('continue')
                  )
                }
                icon={verifyingUser ? false : true}
                typeIcon='arrow'
                iconColor='#000'
                disabled={!values.email || !values.emailConfirmation}
              />
            </div>
          </form>
        </div>
      </ContentApp>
    </>
  );
};

export default SignUpPhoneValidationScreen;
