import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getStripeOnboardingRefreshLink } from '../../services/associate/associateSalesService';
import { CircleSpinner } from 'react-spinners-kit';
import { WelletContext } from '../../context/WelletContext';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import styles from './StripeConnectRefresh.module.css';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../components/icons/ErrorIcon';
import FixedButton from '../../components/Common/FixedButton/FixedButton';
import Button from '../../components/Common/Button/Button';

const StripeConnectRefresh = () => {
  const { token } = useParams();
  const welletContext = useContext(WelletContext);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const getAndRefreshUrl = async () => {
    var decodedToken = decodeURIComponent(token);
    try {
      var newLink = await getStripeOnboardingRefreshLink(welletContext, decodedToken);
      window.location.href = newLink;
    } catch (error) {
      setError('ERROR');
      setErrorMessage(t('resetAccountLinking'));
      console.error(error);
      throw error;
    }
  };

  const [newLink] = useState(() => {
    getAndRefreshUrl();
  });

  return (
    <>
      {error ? (
        <div className={styles.container}>
          <div className={styles.logo}>
            <ErrorIcon color='var(--color-primary)' size={100} />
          </div>
          <div className={styles.instructionsTitleCheck}>{t('somethingWentWrong')}</div>
          <div className={styles.instructionsSubTitleCheck}>{t('resetAccountLinking')}</div>
        </div>
      ) : (
        <div className='loaderDiv'>
          <CircleSpinner
            className={styles.spinnerApp}
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      )}
    </>
  );
};

export default StripeConnectRefresh;
