import React from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Money from '../../Common/Money/Money';
import {
  skeletonLoadingBalanceLast7Days,
  skeletonLoadingBalanceTodayDay,
} from '../../Skeletons/SkeletonAccount';
import { formatDateLong } from '../../../helpers/dates';

const BalanceAmount = ({
  loadingSummary,
  showAllBalances,
  todayBalance,
  last7DaysBalance,
  loading,
  isToday,
  selectedDate,
  locale,
  showAmount,
  today,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const balanceClassname = () => {
    const balanceLength = todayBalance?.toString().length || last7DaysBalance?.toString().length;
    if (balanceLength === 7) return 'font-large';
    if (balanceLength === 8) return 'font-medium';
    if (balanceLength >= 9) return 'font-normal';
    return 'font-extra-large';
  };

  const balanceValue = (balance) => {
    if (showAmount) {
      return (
        <div className='fade-up'>
          <Money value={balance} symbolSize='.85rem' />
        </div>
      );
    } else {
      return (
        <div className='mt-2'>
          <span className='fade-up' style={{ fontSize: '.85rem' }}>
            ${' '}
          </span>
          <span className='fade-up'>••••</span>
        </div>
      );
    }
  };

  const renderBalance = () => {
    if (showAllBalances) {
      return (
        <div className='row balance' onClick={() => history.push('/incomes-per-month')}>
          <div className='col-5 pr-0'>
            <div className='text-secondary font-small normal mb-2'>{t('today')}</div>
            <span className={`bold ${balanceClassname()}`}>
              {!loading
                ? balanceValue(todayBalance?.total || todayBalance)
                : skeletonLoadingBalanceLast7Days()}
            </span>
          </div>
          <div className='col-7 p-0 pl-3' style={{ borderLeft: '1px solid #212121' }}>
            <div className='text-secondary font-small text-capitalize normal mb-2'>
              {format(today, 'MMMM yyyy', { locale })}
            </div>
            <span className={`bold ${balanceClassname()}`} style={{ color: '#fff' }}>
              {!loading ? balanceValue(last7DaysBalance) : skeletonLoadingBalanceLast7Days()}
            </span>
          </div>
        </div>
      );
    } else if (!loadingSummary && !showAllBalances && !loading) {
      return (
        <div className='balance mb-2'>
          <div className='text-secondary font-small normal mb-1 fade-up mt-1'>{`${
            isToday ? t('today') : formatDateLong(selectedDate, locale)
          }`}</div>
          <span className={`font-extra-large bold`}>
            {balanceValue(todayBalance.total || todayBalance)}
          </span>
        </div>
      );
    } else {
      return skeletonLoadingBalanceTodayDay();
    }
  };

  return <div>{renderBalance()}</div>;
};

export default BalanceAmount;
