import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import SolidChevron from '../../icons/SolidChevron';
import PhoneInput from 'react-phone-number-input';
import InputFormBorder from '../../Common/InputFormBorder/InputFormBorder';
import LanguageSelector from '../../Common/LanguageSelector/LanguageSelector';
import styles from './reservationCheckCustomerInfo.module.css';

const ReservationCheckCustomerInfo = ({
  handleChange,
  values,
  errors,
  phoneError,
  setPhoneError,
}) => {
  const { t } = useTranslation();
  const lang = useSelector((state) => state.app.language);

  const treatments = [
    {
      value: 'mr',
      label: t('customerTreatment.mr'),
    },
    {
      value: 'mrs',
      label: t('customerTreatment.mrs'),
    },
    {
      value: 'miss',
      label: t('customerTreatment.miss'),
    },
  ];
  const [treat, setTreat] = useState(treatments[0]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='p-0'>
        <SolidChevron size={15} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const handleTreat = (treat) => {
    setTreat(treat);
  };

  const change = (e) => {
    handleChange({ target: { name: 'phone', value: e }, persist: () => {} });
    setPhoneError('');
  };

  const handleCountryChange = (country) => {
    handleChange({ target: { name: 'countryCode', value: country }, persist: () => {} });
  };

  const handleChangeLanguage = (lang) => {
    handleChange({ target: { name: 'language', value: lang }, persist: () => {} });
  };

  return (
    <div>
      <span className='text-primary font-small fw-bold'>{t('customerInformation')}</span>
      <div className='mt-4'>
        <div
          className={`field-border-container ${
            errors.customerName && errors.customerName.length > 0 ? 'error' : ''
          }`}
        >
          <div className='field-border'>
            <div className='field-border-name'>{t('fullName')}</div>
            <div className='row align-items-center reservation-form'>
              <div className='col-auto my-auto pr-0' style={{ width: '90px' }}>
                <Select
                  className={'treat-selector'}
                  classNamePrefix={'treat-selector'}
                  components={{ DropdownIndicator }}
                  options={treatments}
                  formatOptionLabel={(p) => (
                    <div>
                      <span>{p.label}</span>
                    </div>
                  )}
                  onChange={handleTreat}
                  value={treat}
                  isSearchable={false}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 99,
                    }),
                  }}
                />
              </div>
              <div className='col mr-3 px-0'>
                <InputFormBorder
                  color='#757575'
                  name='customerName'
                  type='text'
                  onChange={handleChange}
                  value={values.customerName}
                  placeholder={t('fullName')}
                  customClass='border-none p-0'
                />
              </div>
            </div>
          </div>
          {errors.customerName && errors.customerName.length > 0 ? (
            <div className='errorLabel mt-2'>{errors.customerName}</div>
          ) : null}
        </div>

        <div className={`field-border-container mt-4 ${phoneError ? 'error' : ''}`}>
          <div className={`field-border`}>
            <div className='field-border-name'>{t('customerWhatsApp')}</div>
            <div className={`phone w-100 focus`}>
              <div className={styles.phoneInputContainer}>
                <PhoneInput
                  className={`${phoneError ? 'phone-error phone-check' : ''}`}
                  name='phone'
                  type='phone'
                  inputMode='numeric'
                  international={true}
                  value={values?.phone}
                  defaultCountry={'MX'}
                  onChange={change}
                  onCountryChange={handleCountryChange}
                />
                <span className='chevron'>
                  <SolidChevron size={15} color='var(--color-primary)' />
                </span>
              </div>
            </div>
          </div>
          {phoneError ? <div className='errorLabel mt-2'>{phoneError}</div> : null}
        </div>

        <div className='field-border-container mt-4' style={{ zIndex: '3' }}>
          <div className={`field-border`}>
            <div className='field-border-name'>{t('customerLanguage')}</div>
            <LanguageSelector lang={lang} handleChange={handleChangeLanguage} />
          </div>
        </div>

        <div className='mt-4'>
          <InputFormBorder
            color='#757575'
            name='email'
            type='text'
            icon={'mail'}
            onChange={handleChange}
            value={values.email}
            error={errors.email}
            placeholder={'E-mail'}
            label={t('reservationEmail')}
            forceLowecase={true}
            border={'none'}
          />
        </div>
      </div>
    </div>
  );
};

export default ReservationCheckCustomerInfo;
