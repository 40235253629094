import React from 'react';

const ReelIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 52 52'
      fill='none'
    >
      <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
      <g clipPath='url(#clip0_397_2141)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19.6349 13.305C16.139 13.305 13.305 16.139 13.305 19.6349V32.3651C13.305 35.861 16.139 38.695 19.6349 38.695H32.3651C35.861 38.695 38.695 35.861 38.695 32.3651V19.6349C38.695 16.139 35.861 13.305 32.3651 13.305H19.6349ZM11.1621 19.6349C11.1621 14.9555 14.9555 11.1621 19.6349 11.1621H32.3651C37.0445 11.1621 40.8379 14.9555 40.8379 19.6349V32.3651C40.8379 37.0445 37.0445 40.8379 32.3651 40.8379H19.6349C14.9555 40.8379 11.1621 37.0445 11.1621 32.3651V19.6349Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.1621 19.6349C11.1621 19.0432 11.6418 18.5635 12.2335 18.5635H39.7664C40.3582 18.5635 40.8379 19.0432 40.8379 19.6349C40.8379 20.2266 40.3582 20.7063 39.7664 20.7063H12.2335C11.6418 20.7063 11.1621 20.2266 11.1621 19.6349Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.4616 11.3887C18.935 11.0336 19.6066 11.1296 19.9616 11.603L25.4756 18.9549C25.8306 19.4283 25.7347 20.0999 25.2613 20.4549C24.7879 20.81 24.1163 20.714 23.7613 20.2406L18.2473 12.8887C17.8923 12.4153 17.9882 11.7437 18.4616 11.3887Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M26.7385 11.3766C27.2118 11.0215 27.8834 11.1172 28.2385 11.5905L33.7648 18.9548C34.12 19.4281 34.0242 20.0997 33.551 20.4549C33.0777 20.81 32.4061 20.7143 32.0509 20.241L26.5246 12.8767C26.1694 12.4034 26.2652 11.7318 26.7385 11.3766Z'
          fill='white'
        />
        <path d='M23.8906 32.7598V25.1365L29.7747 28.9482L23.8906 32.7598Z' fill='white' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M23.5495 24.5089C23.7794 24.3839 24.0594 24.3946 24.279 24.5369L30.1631 28.3486C30.3663 28.4803 30.489 28.7059 30.489 28.9481C30.489 29.1902 30.3663 29.4159 30.1631 29.5476L24.279 33.3593C24.0594 33.5016 23.7794 33.5123 23.5495 33.3873C23.3195 33.2623 23.1764 33.0215 23.1764 32.7598V25.1364C23.1764 24.8747 23.3195 24.6339 23.5495 24.5089ZM24.605 26.4502V31.446L28.4609 28.9481L24.605 26.4502Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_397_2141'>
          <rect width='30' height='30' fill='white' transform='translate(11 11)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReelIcon;
