import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { WelletContext } from '../../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import SuccessScreen from '../../Common/SuccessScreen';
import Button from '../../../components/Common/Button/Button';
import { getPaymentMethod } from '../../../actions/associatesActions';
import styles from './stripe.module.css';
import StripeIcon from '../../../components/icons/StripeIcon';
import FixedButton from '../../../components/Common/FixedButton/FixedButton';

const LinkStripeConnect = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const dispatch = useDispatch();
  const [onboardingEmail, setOnboardingEmail] = useState(null);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null);

  const sendOnboardingLink = async () => {
    try {
      const response = await welletContext.apis.tickets.post('/withdrawal/stripe/OnboardingEmail');
      setOnboardingEmail(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const refreshWithdrawalMethod = async () => {
    try {
      const withdrawalMethodResult = await getPaymentMethod(welletContext);
      dispatch({
        type: 'USER_WITHDRAWAL',
        payload: withdrawalMethodResult.data,
      });
      setWithdrawalMethod(withdrawalMethodResult.data);
    } catch (error) {
      setWithdrawalMethod('none'); // do not do anything if the user does not have a withdrawal method
    }
  };

  useEffect(() => {
    refreshWithdrawalMethod();
  }, []);

  const renderBody = () => {
    if (withdrawalMethod == null) {
      return null;
    }

    return (
      <div className={styles.container}>
        <Header title={t('stripe.receivePayments')} />
        <div className={styles.logo}>
          <StripeIcon size='102px' />
        </div>
        <ContentApp>
          <div className={styles.instructionsTitle}>
            {t('stripe.ReceivePaymentsIntoYourBankAccount')}
          </div>
          <div className={styles.instructionsSubTitle}>{t('stripe.startReceivingPayments')}</div>
        </ContentApp>
        <FixedButton>
          <Button
            text={t('stripe.requestInstructions')}
            size='large'
            icon
            typeIcon='arrow'
            color='#000'
            className='btn-primary'
            iconColor='#000'
            onClick={() => sendOnboardingLink()}
            disabled={false}
          />
        </FixedButton>
      </div>
    );
  };

  if (onboardingEmail) {
    return (
      <SuccessScreen
        title={`${t('stripe.emailSent')}`}
        subtitle={t('stripe.emailWithLinkYourStripeAccount', { onboardingEmail: onboardingEmail })}
        onClick={async () => {
          await refreshWithdrawalMethod();
          history.push('/');
        }}
      />
    );
  }

  if (withdrawalMethod !== null && withdrawalMethod !== 'none') {
    return (
      <SuccessScreen
        title={t('stripe.successLinkStripeSuccessfully')}
        subtitle={t('stripe.successLinkStripe')}
        path='/'
      />
    );
  }

  return <>{renderBody()}</>;
};

export default LinkStripeConnect;
