import { useEffect, useContext, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AssociateRealtime from '../context/AssociateRealtimeProvider';
import axios from 'axios';
import { WelletContext } from '../context/WelletContext';
import { getAssociateSaleSetup } from '../actions/associatesActions';

const useRealtimeOrders = (today, filterDate, onValidReservationUpdate) => {
  const dispatch = useDispatch();
  const { connection } = useContext(AssociateRealtime);
  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const jwt = useSelector((state) => state.associates.jwt);

  const fetchOrders = async (silent = false) => {
    setLoading(true);
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Cancelled due to new request');
    }
    if (!silent) setLoading(true);

    const newCancelTokenSource = axios.CancelToken.source();
    setCancelTokenSource(newCancelTokenSource);
    const body = {
      FilterDate: localStorage.getItem('filterDate') ?? filterDate,
      skip: 0,
      limit: 10,
    };
    try {
      const response = await welletContext.apis.tickets.post(`/order/LiveFeed`, body, {
        cancelToken: newCancelTokenSource.token,
      });

      setOrders(response.data);
      setSkip(10);
      setHasMore(response.data.length === 10);
      setLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error('failed to fetch', error.message);
        setLoading(true);
      }
    }
  };

  const fetchMoreOrders = async () => {
    if (loadingMore || !hasMore) return;
    setLoadingMore(true);

    const body = {
      FilterDate: localStorage.getItem('filterDate') ?? filterDate,
      skip,
      limit: 10,
    };

    try {
      const ordersData = await welletContext.apis.tickets.post(`/order/LiveFeed`, body);
      const newOrder = ordersData.data;
      if (newOrder.length > 0) {
        setOrders((prevOrders) => [...prevOrders, ...newOrder]);
        setSkip(skip + 10);
      }
      setHasMore(newOrder.length === 10);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMore(false);
    }
  };

  const onUpdateReservation = useCallback(
    (updatedReservation) => {
      setOrders((currentOrders) =>
        currentOrders.map((reservation) =>
          reservation.id === updatedReservation.id
            ? { ...reservation, ...updatedReservation }
            : reservation,
        ),
      );
      if (updatedReservation.amount !== 0) {
        onValidReservationUpdate();
      }
    },
    [onValidReservationUpdate],
  );

  const onRemoveReservation = useCallback((deletedReservationId) => {
    setOrders((currentOrders) =>
      currentOrders.filter((reservation) => reservation.id !== deletedReservationId),
    );
  }, []);

  const onNewOrder = useCallback(
    (newOrder) => {
      if (filterDate === 'RESERVATION_DATE') {
        const reservationDate = new Date(newOrder.reservationLocalTime);
        const todayDate = new Date(today);

        if (reservationDate >= today) {
          let updatedOrders = [...orders];

          const endOfToday = new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate(),
            23,
            59,
            59,
          );
          const lastTodayIndex = updatedOrders.reduce((lastIndex, current, index) => {
            const currentDate = new Date(current.reservationLocalTime);
            return currentDate <= endOfToday ? index : lastIndex;
          }, -1);

          const limitIndex = lastTodayIndex + 6;
          const insertIndex = updatedOrders.findIndex(
            (res) => new Date(res.reservationLocalTime) > reservationDate,
          );

          if (
            (insertIndex >= 0 && insertIndex < limitIndex) ||
            (insertIndex === -1 && updatedOrders.length < limitIndex)
          ) {
            updatedOrders.splice(
              insertIndex >= 0 ? insertIndex : updatedOrders.length,
              0,
              newOrder,
            );
            setOrders(updatedOrders);
          } else {
            setNewOrder(newOrder);
          }
        }
      } else {
        if (orders.some((reservation) => reservation.id === newOrder.id)) {
          setOrders(
            orders.map((reservation) => (reservation.id === newOrder.id ? newOrder : reservation)),
          );
        } else {
          setOrders([newOrder, ...orders]);
        }
      }
    },
    [orders],
  );

  useEffect(() => {
    fetchOrders();
  }, [filterDate]);

  useEffect(() => {
    if (connection && orders) {
      connection.on('UpdateReservation', onUpdateReservation);
      connection.on('NewOrder', onNewOrder);
      connection.on('RemoveReservation', onRemoveReservation);
      connection.on('ChangeAdvantageNetwork', () => {
        dispatch(getAssociateSaleSetup(jwt));
      });
    }

    return () => {
      if (connection) {
        connection.off('UpdateReservation', onUpdateReservation);
        connection.off('NewOrder', onNewOrder);
        connection.off('RemoveReservation', onRemoveReservation);
        connection.off('ChangeAdvantageNetwork');
      }
    };
  }, [
    orders,
    onUpdateReservation,
    onNewOrder,
    onRemoveReservation,
    dispatch,
    welletContext.associates,
  ]);

  return {
    orders,
    loading,
    newOrder,
    loadingMore,
    hasMore,
    fetchMoreOrders,
  };
};

export default useRealtimeOrders;
