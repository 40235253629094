import axios from 'axios';
import { resetCalendarState } from './calendarActions';
import { resetOrderState } from './orderActions';
import { resetProductsState } from './productsActions';

export const appReady = () => {
  return {
    type: 'APP_READY',
  };
};

export const fetchAccounts = async (lang, cityId, page, pageSize, welletContext) => {
  const response = await welletContext.apis.tickets.get('/mktEntity/simple', {
    lang: lang,
    cityId: cityId,
    page: page,
    pageSize: pageSize,
  });

  return response.data;
};

export const fetchShow = async (lang, cityId, page, pageSize, welletContext) => {
  const response = await welletContext.apis.tickets.post('/mktcampaign/entities', {
    lang: lang,
    cityId: cityId,
    page: page,
    pageSize: pageSize,
  });

  return response.data;
};

export const setTenantId = (tenantId) => ({
  type: 'SET_TENANT_ID',
  payload: tenantId,
});

const setAppData = (data) => ({
  type: 'SET_APP_DATA',
  payload: data,
});

export const fetchAppData = (tenantId) => async (dispatch) => {
  if (tenantId) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SALES_API_URL}/organization/entity/${tenantId}`,
      );
      dispatch(setAppData(response.data));

      // set title and favicon based on app data
      document.title = response.data.titleWeb;
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.href = response.data.favIconUrl;
      document.head.appendChild(favicon);
    } catch (error) {
      console.error(error);
    }
  }
};

export const resetAll = () => (dispatch) => {
  dispatch(resetCalendarState());
  dispatch(resetOrderState());
  dispatch(resetProductsState());
};

export const setCanRefresh = (value) => ({
  type: 'SET_CAN_REFRESH',
  payload: value,
});

export const setLanguage = (language) => {
  return {
    type: 'SET_LANGUAGE',
    payload: language,
  };
};
