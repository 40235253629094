import React from 'react';
import ChevronIcon from '../../icons/chevronIcon';
import styles from './CampaignCard.module.css';
import { formatDateLong } from '../../../helpers/dates';
import { useTranslation } from 'react-i18next';

const CampaignCard = ({
  campaign,
  path,
  history,
  locale,
  index,
  isSocialNetwork = true,
  showComingSoon = false,
}) => {
  const { t } = useTranslation();
  const getCampaignText = () => {
    switch (campaign.campaignType) {
      case 'VIDEO':
        return 'Video de';
      case 'STATUS':
        return 'Estado de';
      case 'HISTORY':
        return 'Historia de';
      case 'REEL':
        return 'Reel de';
      default:
        return '';
    }
  };
  return (
    <div
      className={
        !campaign.isActive && isSocialNetwork ? styles.campaignCardUnactive : styles.campaignCard
      }
      onClick={!campaign.isActive && isSocialNetwork ? null : () => history.push(path)}
      key={index}
    >
      <div className={styles.containerImg}>
        <img src={campaign.logo || campaign.showLogo} alt='logo' className={styles.logoImg} />
      </div>
      <div className='col'>
        <div className='bold font-normal d-flex align-items-center'>
          <span>{campaign.name} </span>
          {showComingSoon && !campaign.isActive && (
            <span className={styles.upcomingText}>{t('comingSoon')}</span>
          )}
        </div>
        <div className='text-secondary font-small text-truncate'>{campaign.description}</div>
        {campaign.campaignType ? (
          <div className='font-smaller mt-1'>
            {getCampaignText(campaign.campaignType)} {campaign.networkCampaignName}
          </div>
        ) : (
          ''
        )}
        {campaign.createdDateUTC ? (
          <div className='text-secondary font-extra-small'>
            Fecha de creación: {formatDateLong(campaign.createdDateUTC, locale)}
          </div>
        ) : (
          ''
        )}
      </div>
      {campaign.isActive ? (
        <div className='col-auto p-0 m-auto'>
          <ChevronIcon size={30} fillColor='var(--color-primary)' />
        </div>
      ) : null}
    </div>
  );
};

export default CampaignCard;
