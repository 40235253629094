import React from 'react';
const NoCampaignsIcon = ({
  size,
  color1 = '#1D9BF0',
  color2 = '#1D9BF0',
  stroke1 = '#1D9BF0',
  color3 = '#000',
  styles,
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 105 118'
      fill='none'
      style={styles}
    >
      <circle cx='53.5729' cy='36.428' r='30' fill={color3} />
      <path
        d='M52.5 77.9658C51.3388 77.9658 50.1864 77.9114 49.0497 77.8094C43.1842 77.2753 37.5123 75.4009 32.4558 72.3255C27.3993 69.2502 23.0883 65.053 19.8432 60.0461L0 96.0985H25.5826L38.4852 117.858L56.8446 77.7187C55.4019 77.8821 53.9515 77.9646 52.5 77.9658ZM85.2013 60.0007C80.5019 67.2523 73.6176 72.752 65.585 75.672L56.4375 95.9852L66.5148 117.858L79.4174 96.0985H105L85.2013 60.0007Z'
        fill={color1}
      />
      <path
        d='M52.8351 57.25H52.8362C58.4272 57.2437 63.7875 55.0199 67.7409 51.0665C71.6943 47.113 73.9181 41.7528 73.9244 36.1618V36.1607C73.9244 31.9896 72.6875 27.9122 70.3702 24.4441C68.0529 20.976 64.7592 18.273 60.9056 16.6768C57.0521 15.0806 52.8117 14.6629 48.7208 15.4767C44.6299 16.2904 40.8721 18.299 37.9227 21.2483C34.9734 24.1977 32.9648 27.9555 32.1511 32.0464C31.3373 36.1373 31.755 40.3777 33.3512 44.2312C34.9474 48.0848 37.6504 51.3785 41.1185 53.6958C44.5867 56.0131 48.6641 57.25 52.8351 57.25ZM17.6744 36.1607C17.6744 16.7744 33.4488 1 52.8351 1C72.2214 1 87.9958 16.7744 87.9958 36.1607C87.9958 55.547 72.2214 71.3214 52.8351 71.3214C33.4488 71.3214 17.6744 55.547 17.6744 36.1607ZM63.8937 36.1607C63.8937 42.2654 58.9448 47.2143 52.8401 47.2143C46.7354 47.2143 41.7865 42.2654 41.7865 36.1607C41.7865 30.056 46.7354 25.1071 52.8401 25.1071C58.9448 25.1071 63.8937 30.056 63.8937 36.1607Z'
        fill={color2}
        stroke={stroke1}
        strokeWidth='2'
      />
    </svg>
  );
};
export default NoCampaignsIcon;
