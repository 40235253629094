import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/Common/Button/Button';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import { useParams } from 'react-router-dom';
import { WelletContext } from '../../../context/WelletContext';
import Money from '../../../components/Common/Money/Money';
import { useTranslation } from 'react-i18next';
import styles from './Pay.module.css';
import { CircleSpinner } from 'react-spinners-kit';
import CreditCard from '../../../components/Consumer/CreditCard/CreditCard';

import ProcessingScreen from '../../Common/ProcessingScreen';
import SuccessCheck from '../../../components/icons/SuccessCheck';

import { formatDateHours, getLocale } from '../../../helpers/dates';

const PayScreen = ({ history }) => {
  const welletContext = useContext(WelletContext);
  const { pos } = useParams();
  const app = useSelector((state) => state.app);
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentInProcess, setPaymentInProcess] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [showWidgate, setShowWidgate] = useState(false);
  const [currentCardId, setCurrentCardId] = useState(null);
  const [isAddCardVisible, setIsAddCardVisible] = useState(false);
  const { t } = useTranslation();
  const locale = getLocale(app.language);

  const getPaymentInfo = async () => {
    try {
      const response = await welletContext.apis.tickets.get(`/pos/${pos}/paymentOptions`);
      window.wellet = window.Wellet(response.data.gateway.publicKey);
      setPaymentOptions(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentInfo();
  }, []);

  const submitPayment = async (payment) => {
    try {
      const response = await welletContext.apis.tickets.post(`/pos/${pos}/pay`, {
        posPaymentId: paymentOptions.posPaymentId,
        amount: paymentOptions.amount,
        currency: paymentOptions.currency,
        savedCardId: currentCardId,
      });

      return response.data;
    } catch (error) {
      return {
        success: false,
        errorCode: 'GENERIC_ERROR',
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaymentInProcess(true);
    setErrorMessage(null);

    const payment = await submitPayment();
    setPaymentInProcess(false);
    if (!payment.success) {
      setErrorMessage(payment.errorCode);
      return;
    }

    setPaymentSuccess(payment);
  };

  const handleCardIdChange = (id) => {
    setCurrentCardId(id);
  };

  const handleAddCardVisibility = (isVisible) => {
    setIsAddCardVisible(isVisible);
  };

  useEffect(() => {
    if (paymentSuccess) {
      const timer = setTimeout(() => {
        history.push('/');
      }, 1800);

      return () => clearTimeout(timer);
    }
  }, [paymentSuccess, history]);

  const renderPaymentForm = () => {
    if (!paymentOptions) {
      return null;
    }

    if (paymentSuccess) {
      return (
        <div>
          <div className={styles.container}>
            <div className={styles.logo}>
              <SuccessCheck color='var(--color-primary)' size={200} />
            </div>
            <div className={styles.instructionsTitleCheck}>{t('stripe.ready')}</div>
            <div className={styles.instructionsSubTitleCheck}>{t('consumer.haveprocessedyourpayment')}</div>
          </div>
        </div>
      );
    }

    return (
      <>
        <ContentApp>
          <div className='ml-1 mr-1'>
            <div className={styles.iconContainer}>
              <div className={styles.iconWrapper}>
                <img src={app.logoType} alt='logo' className={styles.img} />
              </div>
              {paymentOptions.description && (
                <div className={`${styles.iconDescription} ${styles.centeredDescription}`}>
                  <span className={styles.title}>{paymentOptions.description}</span>
                </div>
              )}
              <span style={{ fontSize: '12px', color: '#939393' }}>
                {t('payment')} {formatDateHours(paymentOptions.localTime, locale)}
              </span>
            </div>

            <div className='text-lighter-gray font-small '>
              <div className='row mt-5'>
                <div className='col pr-0 text-secondary bold'>{t('consumer.yourPurchase')}</div>
                <div className='col-auto'>
                  <Money value={paymentOptions.amountWithoutBenefits} symbolSize='14px' />
                </div>
              </div>

              {paymentOptions.discountAmount > 0 && (
                <div className='row mt-2 mb-3'>
                  <div className='col pr-0 text-secondary bold'>
                    {t('consumer.memberDiscount')}
                    {paymentOptions.consumerBenefits.category}
                  </div>
                  <div className='col-auto'>
                    {' '}
                    - <Money value={paymentOptions.discountAmount} symbolSize='14px' />
                  </div>
                </div>
              )}
              <div
                style={{
                  backgroundColor: '#525252',
                  height: '1px',
                  width: '100%',
                  margin: '0 auto',
                }}
                className='line'
              ></div>

              <div className='row mt-3 mb-4'>
                <div style={{ fontSize: '18px' }} className='col pr-0 bold'>
                  {' '}
                  {t('total')}
                </div>
                <div style={{ fontSize: '24px' }} className='col-auto'>
                  <Money value={paymentOptions.amount} symbolSize='14px' />
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              {showWidgate && <div id='wellet-payment-form' />}
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </form>
          </div>
        </ContentApp>

        <div className={styles.fixed}>
          <div className={styles.target}>
            <div className={styles.customLine}></div>
            <CreditCard
              onCardIdChange={handleCardIdChange}
              cards={paymentOptions.userSavedCards}
              onCardVisibilityChange={handleAddCardVisibility}
            />

            <Button
              onClick={handleSubmit}
              className={`${styles.button} btn`}
              text={
                <div className='bold' style={{ fontSize: '15px' }}>
                  {t('pay')} {''}
                  <Money value={paymentOptions.amount} symbolSize='16px' />
                </div>
              }
              size='pay'
              disabled={paymentInProcess || isAddCardVisible}
            ></Button>
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    if (paymentInProcess) {
      return <ProcessingScreen title={t('wait')} subtitle={t('consumer.WhileProcessYourPayment')} />;
    }

    if (!paymentOptions) {
      return (
        <div className='loading-app'>
          <div className='loaderDiv'>
            <CircleSpinner
              className='spinner-app mx-auto'
              style={{ margin: 'auto' }}
              size={40}
              color='var(--color-font-primary)'
            />
          </div>
        </div>
      );
    }

    return renderPaymentForm();
  };

  return (
    <div>
      {!(paymentInProcess || paymentSuccess) && <Header />}
      {renderBody()}
    </div>
  );
};

export default PayScreen;
