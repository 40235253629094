import React, { useState, useEffect, useContext } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { useParams } from 'react-router-dom';
import { confirmStripeOnboarding } from '../../services/associate/associateSalesService';
import Button from '../../components/Common/Button/Button';
import { CircleSpinner } from 'react-spinners-kit';
import styles from './StripeConnectReturn.module.css';
import Header from '../../components/Common/Header/Header';
import SuccessCheck from '../../components/icons/SuccessCheck';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '../../components/icons/ErrorIcon';
import FixedButton from '../../components/Common/FixedButton/FixedButton';

const StripeConnectReturn = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const { token } = useParams();
  const [onboarding, setOnboarding] = useState(null);

  const confirm = async () => {
    var decodedToken = decodeURIComponent(token);
    try {
      const onboardingResult = await confirmStripeOnboarding(welletContext, decodedToken);
      setOnboarding(onboardingResult);
    } catch (error) {
      setOnboarding({ isComplete: false });
    }
  };

  useEffect(() => {
    confirm();
  }, []);

  if (!onboarding) {
    return (
      <div className='loaderDiv'>
        <CircleSpinner
          className={styles.spinnerApp}
          style={{ margin: 'auto' }}
          size={40}
          color='var(--color-font-primary)'
        />
      </div>
    );
  }

  if (onboarding.isComplete) {
    return (
      <div className={styles.container}>
        <Header showBack={false} title={t('stripe.linkedAccount')} />

        <div className={styles.logo}>
          <SuccessCheck color='var(--color-primary)' size={200} />
        </div>
        <div className={styles.instructionsTitleCheck}>{t('stripe.ready')}</div>
        <div className={styles.instructionsSubTitleCheck}>
        {t('stripe.successLinkStripeContinue')}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logo}>
          <ErrorIcon color='var(--color-primary)' size={100} />
        </div>
        <div className={styles.instructionsTitleCheck}>{t('stripe.incompleteLinkage')}</div>
        <div className={styles.instructionsSubTitleCheck}>
      {t('stripe.notCompletedYourConnectionWithStripe')}
        </div>
        <FixedButton>
          <Button
            text={t('stripe.completeLinking')}
            color='#000'
            fontSize={'17px'}
            size='large'
            onClick={() => history.push(`/onboarding-stripe/refresh/${encodeURIComponent(token)}`)}
            className={`mt-2 ${styles.btn}`}
          />
        </FixedButton>
      </div>
    </>
  );
};

export default StripeConnectReturn;
