import React, { useContext, useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import Select from 'react-select';
import Button from '../../../../components/Common/Button/Button';
import styles from './Analytics.module.css';
import Money from '../../../../components/Common/Money/Money';
import { Column2d, TimeLine } from '../../../../components/Charts';
import { useTranslation } from 'react-i18next';
import Pie2D from '../../../../components/Charts/pie2d';
import CursorIcon from '../../../../components/icons/CursorIcon';
import WarningTriangleIcon from '../../../../components/icons/WarningTriangleIcon';
import { WelletContext } from '../../../../context/WelletContext';
import {
  fetchAllSocialNetworks,
  fetchCampaignOccassion,
  fetchCampaignReservationsStats,
  fetchCampaignStatistics,
  fetchNetworkCampaignIncomes,
} from '../../../../services/campaigns/campaignsService';
import { format, parse } from 'date-fns';
import { useSelector } from 'react-redux';
import { CircleSpinner } from 'react-spinners-kit';
import ChevronIcon from '../../../../components/icons/chevronIcon';
import Line from '../../../../components/Common/Line/Line';
import StarIcon from '../../../../components/icons/StarIcon';

const selectStyles2 = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    outline: 0,
    backgroundColor: '#131313',
    boxShadow: 'none',
    color: '#000',
    padding: '.25rem .5rem',
    height: '100%',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: '#fff',
    marginTop: 0,
    minWidth: state.menuIsOpen && 150,
    zIndex: 99999,
    border: '1px solid #fff',
  }),
  option: (provided) => ({
    ...provided,
    background: '#fff',
    color: '#000',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
    padding: 0,
    fontWeight: 'bold',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 3px',
    color: 'var(--color-primary)',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const Analytics = ({ history }) => {
  const welletContext = useContext(WelletContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [campaignStatistics, setCampaignStatistics] = useState(null);
  const [campaignOccassion, setCampaignOccassion] = useState(null);
  const [graphStats, setGraphStats] = useState(null);
  const [cities, setCities] = useState(null);
  const [socialNetworks, setSocialNetworks] = useState(null);
  const [networkIncomes, setNetworkIncomes] = useState(null);
  const timeLineData = formatPosObjects(graphStats) ?? [];
  const language = useSelector((state) => state.app.language);

  const getInitialDates = () => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return [
      {
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
        key: 'selection',
      },
    ];
  };
  const [initialDates, setInitialDates] = useState(getInitialDates());

  const getData = async () => {
    const body = {
      CampaignCreatedBy: 'MARKETING',
      ToDateLocal: format(initialDates[0].endDate, 'yyyy-MM-dd'),
      FromDateLocal: format(initialDates[0].startDate, 'yyyy-MM-dd'),
    };
    setLoading(true);

    const responseCampaignStatistics = await fetchCampaignStatistics(welletContext, body);
    setCampaignStatistics(responseCampaignStatistics.data);

    const responseCampaignOccassion = await fetchCampaignOccassion(welletContext, body);
    setCampaignOccassion(responseCampaignOccassion.data);

    const responseCampaignGraph = await fetchCampaignReservationsStats(welletContext, body);
    setGraphStats(responseCampaignGraph.data);

    const responseNetworkIncomes = await fetchNetworkCampaignIncomes(welletContext, body);
    setNetworkIncomes(responseNetworkIncomes.data);

    const responseSocialNetworks = await fetchAllSocialNetworks(welletContext, language);
    setSocialNetworks(responseSocialNetworks.data);

    setLoading(false);
  };

  const getCities = async () => {
    setLoading(true);
    try {
      const response = await welletContext.apis.tickets.get('/mktcampaign/cities');
      setCities(response.data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    setInitialDates([
      {
        startDate: firstDayOfMonth,
        endDate: lastDayOfMonth,
        key: 'selection',
      },
    ]);

    getData();
    getCities();
  }, []);

  const months = [
    { value: 0, label: t('days.january') },
    { value: 1, label: t('days.february') },
    { value: 2, label: t('days.march') },
    { value: 3, label: t('days.april') },
    { value: 4, label: t('days.may') },
    { value: 5, label: t('days.june') },
    { value: 6, label: t('days.july') },
    { value: 7, label: t('days.august') },
    { value: 8, label: t('days.september') },
    { value: 9, label: t('days.october') },
    { value: 10, label: t('days.november') },
    { value: 11, label: t('days.december') },
  ];

  function formatPosObjects(posObjects) {
    if (posObjects?.group === 'DAY') {
      if (posObjects?.items?.length > 15) {
        const modifiedObjects = posObjects.items.slice();

        for (let i = 1; i < modifiedObjects?.length - 1; i++) {
          if ((i + 1) % 3 !== 0) {
            delete modifiedObjects[i].group;
          }
        }

        return modifiedObjects;
      } else {
        return posObjects?.items;
      }
    } else {
      return posObjects?.items;
    }
  }

  const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);

  const updateDatesForMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
    const currentYear = new Date().getFullYear();
    const fromDate = new Date(currentYear, selectedMonth.value, 1);
    const toDate = new Date(currentYear, selectedMonth.value + 1, 0);

    setInitialDates([
      {
        startDate: fromDate,
        endDate: toDate,
        key: 'selection',
      },
    ]);

    getData();
  };

  const socialNetworkCard = (item, index) => {
    return (
      <div key={index} className={styles.restaurantCard} onClick={() => history.push(item.path)}>
        <div className='col-auto p-0 ml-3 my-auto'>
          <div className={styles.containerImg}>
            <img src={item.logo} alt='logo-restaurant' />
          </div>
        </div>
        <div className='col p-0 ml-3'>
          <div className='bold font-normal text-white'>{item.networkName}</div>
          <div className='font-small text-secondary my-1'>Ganancias por comisión</div>
          <div className='font-large mt-1'>
            <Money value={item.comission} />
          </div>
        </div>

        <div className='col-auto pl-0'>
          <ChevronIcon fillColor='#fff' size={35} />
        </div>
      </div>
    );
  };

  const getDataForColumn = () => {
    const result = campaignStatistics?.amountReservationTypes?.map((a) => ({
      value: a.amount,
      label: `${a.amount}  ${t('reservationType.' + a.reservationType)}`,
    }));
    return result;
  };

  return (
    <>
      <Header title='Analytics' />
      {loading ? (
        <div className='loaderDiv'>
          <CircleSpinner
            className='spinner-app mx-auto'
            style={{ margin: 'auto' }}
            size={40}
            color='var(--color-font-primary)'
          />
        </div>
      ) : (
        <ContentApp>
          <div className={styles.buttonsContainer}>
            <Select
              placeholder={t(`days.${selectedMonth.label.toLowerCase()}`)}
              className='month-selector col pl-0 pr-1'
              classNamePrefix={'month-selector'}
              isSearchable={false}
              options={months}
              formatOptionLabel={(o) => (
                <div className='option-inner d-flex py-3'>
                  <span>{o.label}</span>
                </div>
              )}
              onChange={updateDatesForMonth}
              value={selectedMonth}
            />
            <Button
              className='col pr-0 text-secondary semi-bold pl-1 font-small'
              size='large'
              hasBorder
              text='Todo hasta hoy (histórico)'
              backgroundColor='#000'
            />
          </div>
          <Select
            className='col p-0 mt-1'
            styles={selectStyles2}
            options={cities}
            placeholder='Todas las ciudades'
            isSearchable={false}
            formatOptionLabel={(o) => (
              <div className='option-inner d-flex py-3'>
                <span>{o.name}</span>
              </div>
            )}
          />
          <Select
            className='col p-0 mt-1'
            styles={selectStyles2}
            options={socialNetworks}
            placeholder='Todos los canales (redes sociales)'
            formatOptionLabel={(o) => (
              <div className='option-inner d-flex py-3'>
                <span>{o.name}</span>
              </div>
            )}
          />
          <div className='card mt-2 p-3'>
            <div className='font-normal bold mb-2'>Ganancias</div>
            <div className='row'>
              <div className='col-6 pr-1'>
                <div className='font-small text-secondary'>{t('byCommissions')}</div>
                <div className='font-extra-large bold mt-1'>
                  <Money value={campaignStatistics?.totalCommission} symbolSize={16} />
                </div>
              </div>
              <div className='col-6' style={{ borderLeft: '1px solid #212121' }}>
                <div className='font-1rem text-secondary semi-bold'>Prom. por reserva</div>
                <div className='font-extra-large bold mt-1'>
                  <Money value={campaignStatistics?.avgCommission} symbolSize={16} />
                </div>
              </div>
            </div>
          </div>
          <div className='card mt-2 p-3'>
            <div className='font-normal bold mb-2'>{t('options.reserves')}</div>
            <div className='row semi-bold'>
              <div className='col-4 pr-1' style={{ borderRight: '1px solid #212121' }}>
                <div className='font-small text-secondary'>{t('completed')}</div>
                <div className='font-extra-large bold'>{campaignStatistics?.totalCompleted}</div>
              </div>
              <div className='col-4' style={{ borderRight: '1px solid #212121' }}>
                <div className='font-small text-secondary'>{t('options.cancelled')}</div>
                <div className='font-extra-large bold'>{campaignStatistics?.totalCancelled}</div>
              </div>
              <div className='col-4'>
                <div className='font-small text-secondary'>{t('total')}</div>
                <div className='font-extra-large bold'>{campaignStatistics?.totalReservation}</div>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <TimeLine
              data={timeLineData?.map((c) => {
                const date = parse(c?.group, 'MM/dd/yyyy', new Date());

                return {
                  label: c.group,
                  value: c.reservation,
                  toolText: c.group,
                };
              })}
              xAxis={`${t('completedReservesFrom', {
                date: selectedMonth.label,
              })}`}
            />
          </div>
          <div className='px-3 bold'>Resumen de tus campañas</div>
          {networkIncomes?.length > 0 && (
            <div className='mt-2'>
              {networkIncomes.map((n, index) => socialNetworkCard(n, index))}
            </div>
          )}
          <div className='card p-3 mt-2'>
            <div className='font-normal bold '>Tipos de reserva</div>
            <Column2d data={getDataForColumn()} />
            <div className='col font-smaller text-secondary'>
              {t('analyticsTexts.thePercentageOfReserves', {
                percentage: 60,
                type: t(`reservationType.RESTAURANT`),
              })}
            </div>
          </div>
          <div className='card mt-2 p-3 flex-row bold'>
            <div className='col-auto p-0'>{t('totalPeople')}</div>
            <div className='col p-0 text-right'>{campaignStatistics?.attendendPaxs}</div>
          </div>
          <div className='card p-3 mt-2 bold'>
            <div>{t('analyticsTexts.reservesOccassions')}</div>{' '}
            <Pie2D
              data={campaignOccassion?.occassions
                ?.sort((a, b) => b?.percentage - a?.percentage)
                ?.map((o) => ({
                  label: t(`typeOccasions.${o.occassion}`),
                  value: `${o.percentage}`,
                }))}
            />
            {campaignOccassion?.maxOccassionPercentage && (
              <>
                <Line color='#212121' />
                <div className='mt-2 row align-items-center'>
                  <div className='col-auto pr-0'>
                    <StarIcon size={16} styles={{ marginBottom: '3px' }} />
                  </div>
                  <div className='col font-smaller'>
                    {t('analyticsTexts.thePercentageOfReserves', {
                      percentage: campaignOccassion.maxPercentage,
                      type: t(`typeOccasions.${campaignOccassion.maxOccassionPercentage}`),
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='card p-3 mt-2'>
            <div className='bold d-flex align-items-center justify-content-between'>
              <div className='mr-2'>
                {t('analyticsTexts.linkAccess')}{' '}
                <CursorIcon styles={{ marginBottom: '4px', marginLeft: '4px' }} size={20} />
              </div>
              <span>{campaignStatistics?.attendendPaxs}</span>
            </div>
            <Line color='#212121' className='my-2' />
            <div className='mt-2 row'>
              <div className='col-auto pr-0'>
                <WarningTriangleIcon color={'var(--color-primary)'} size={15} />
              </div>
              <div className='col font-smaller text-secondary'>
                {t('analyticsTexts.accessCompleted', {
                  percentage: campaignOccassion?.percentajeVisit,
                })}
                <br />
                <u className='text-primary'>{t('analyticsTexts.increaseNumber')}</u>
              </div>
            </div>
          </div>
        </ContentApp>
      )}
    </>
  );
};

export default Analytics;
