import React, { useContext, useEffect, useState } from 'react';
import { WelletContext } from '../../../context/WelletContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getPaymentMethod } from '../../../actions/associatesActions';
import useForm from '../../../helpers/useForm';
import InputFormNew from '../../../components/Common/InputFormNew/InputFormNew';
import ProcessingScreen from '../../Common/ProcessingScreen';
import SuccessScreen from '../../Common/SuccessScreen';
import Header from '../../../components/Common/Header/Header';
import ContentApp from '../../../components/ContentApp';
import ErrorBox from '../../../components/Common/ErrorBox/ErrorBox';
import FixedButton from '../../../components/Common/FixedButton/FixedButton';
import ButtonProccessing from '../../../components/Common/ButtonProccessing/ButtonProccessing';

const LinkMercadoPagoAccount = ({ history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };

  const mpVerificationStatus = {
    OK: 'OK',
    ERROR: 'ERROR',
    NOT_INITIALIZED: 'NOT_INITIALIZED',
    VERIFYING: 'VERIFYING',
  };
  const welletContext = useContext(WelletContext);
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);
  const [mpStatus, setMpStatus] = useState(mpVerificationStatus.NOT_INITIALIZED);
  const [errorMessage, setErrorMessage] = useState(null);

  function validate(values) {
    let errors = {};

    if (!values.mpaccount) {
      errors.mpaccount = t('emailError.EMPTY_EMAIL');
    }
    return errors;
  }

  const sendForm = async () => {
    setMpStatus(mpVerificationStatus.VERIFYING);
    try {
      const result = await welletContext.apis.tickets.post('/withdrawal/AddWithdrawal', {
        MercadoPago: {
          Email: values.mpaccount,
        },
        Transfer: null,
        NameId: 'Mercado_pago',
      });

      if (result.data.verified) {
        setMpStatus(mpVerificationStatus.OK);
        const paymentMethod = await getPaymentMethod(welletContext);
        dispatch({
          type: 'USER_WITHDRAWAL',
          payload: paymentMethod.data,
        });
      } else {
        setMpStatus(mpVerificationStatus.ERROR);
        setBtnStatus(ButtonStatus.Error);
        switch (result.data.error) {
          case 'KYC_NOT_COMPLETED':
            setErrorMessage(t('mercadoPagoAccountError.KYC_NOT_COMPLETED'));
            break;
          case 'ACCOUNT_NOT_FOUND':
            setErrorMessage(t('mercadoPagoAccountError.ACCOUNT_NOT_FOUND'));
            break;
          case 'CHECK_LATER':
            setErrorMessage(t('mercadoPagoAccountError.CHECK_LATER'));
            break;
          default:
            setErrorMessage(t('mercadoPagoAccountError.DEFAULT'));
        }
      }
    } catch (error) {
      setMpStatus(mpVerificationStatus.ERROR);
      setBtnStatus(ButtonStatus.Error);
      setErrorMessage(t('mercadoPagoAccountError.DEFAULT'));
      console.error(error);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  const change = (e) => {
    handleChange(e);
  };

  const inputMp = (
    <InputFormNew
      name='mpaccount'
      type='text'
      placeholder={t('mercadoPago.mpEmail')}
      onChange={change}
      value={values.mpaccount}
      error={errors.mpaccount}
      forceLowecase={true}
    />
  );

  if (mpStatus === mpVerificationStatus.VERIFYING) {
    return <ProcessingScreen title={t('wait')} subtitle={t('mercadoPago.linkingAccount')} />;
  }

  if (mpStatus === mpVerificationStatus.OK) {
    return (
      <SuccessScreen
        title={`${t('done')}!`}
        subtitle={t('mercadoPago.successLinkMp')}
        path={'/my-money'}
      />
    );
  }

  return (
    <>
      <Header title='Mercado Pago' />
      <ContentApp>
        <div className='font-normal bold text-white mt-2'>{t('mercadoPago.linkAccountTitle')}</div>
        <p className='mt-2 text-secondary font-tiny'>{t('mercadoPago.linkAccountDescription')}:</p>
        <div className='link-mp-account-form mt-5'>
          <form>{inputMp}</form>
        </div>
        {mpStatus === mpVerificationStatus.ERROR ? (
          <ErrorBox title={mpVerificationStatus.ERROR} description={errorMessage} />
        ) : null}

        <div className='mt-5'>
          <div className='text-secondary mb-1'>{t('mercadoPago.noAccount')}</div>
          <a
            href='https://www.mercadopago.com.mx/'
            alt='mercado-pago-crear-cuenta'
            target='_blank'
            style={{
              textDecoration: 'none',
              color: 'var(--color-primary)',
            }}
            rel='noreferrer'
          >
            {t('mercadoPago.signUp')}
          </a>
        </div>
        <FixedButton>
          <ButtonProccessing
            status={btnStatus}
            size='large'
            text={t('mercadoPago.linkAccount')}
            className='border-none mx-auto'
            type='submit'
            onClick={handleSubmit}
            backgroundColor='var(--color-primary)'
            arrow
            color='#000'
            iconColor='#000'
          />
        </FixedButton>
      </ContentApp>
    </>
  );
};

export default LinkMercadoPagoAccount;
