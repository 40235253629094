import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import Select from 'react-select';
import { WelletContext } from '../../../../context/WelletContext';
import Slider from '../../../../components/Common/Slider/Slider';
import styles from './CreateCampaign.module.css';
import Button from '../../../../components/Common/Button/Button';
import { getQueryString } from '../../../../helpers/url';
import EmptyActivity from '../../../../components/Common/EmptyActivity/EmptyActivity';
import { fetchShows, fetchMediaById } from '../../../../services/campaigns/campaignsService';

import {
  SkeletonCreateCampaign,
  SkeletonCreateCampaignMedia,
} from '../../../../components/Skeletons/SkeletonCreateCampaign';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../../../components/icons/CheckIcon';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { fetchCities } from '../../../../services/reservations/reservationsService';

const CreateCampaign2 = ({ history }) => {
  const { t } = useTranslation();
  const welletContext = useContext(WelletContext);
  const language = useSelector((state) => state.app.language);
  const type = getQueryString('type');
  const social = getQueryString('social');
  const id = getQueryString('id');
  const networkId = getQueryString('networkId');
  const app = useSelector((state) => state.app);
  const [restaurants, setRestaurants] = useState(null);
  const [media, setMedia] = useState(null);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [loadingCityAndRestaurants, setLoadingCityAndRestaurants] = useState(true);
  const [loadingSlider, setLoadingSlider] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [cities, setCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const sliderRef = useRef();

  const getCities = async () => {
    try {
      const response = await fetchCities(welletContext);
      setCities(response);
      setSelectedCity(response[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const getRestaurants = async () => {
    setLoadingSlider(true);

    try {
      const response = await fetchShows(welletContext, selectedCity.id);
      setRestaurants(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingCityAndRestaurants(false);
    setLoadingSlider(false);
  };

  const getMedia = async (showId) => {
    setLoadingContent(true);

    try {
      const response = await fetchMediaById(welletContext, showId, language);
      setMedia(response.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingContent(false);
  };

  const handleSelectedRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setMedia(null);
  };

  const handleSelectCity = (city) => {
    setSelectedCity(city);
    setSelectedRestaurant(null);
    setMedia(null);
  };

  const handleSelectedMedia = (media) => {
    setSelectedMedia((prevSelectedMedia) => {
      if (prevSelectedMedia.some((item) => item.mediaUrl === media.mediaUrl)) {
        return prevSelectedMedia.filter((item) => item.mediaUrl !== media.mediaUrl);
      }
      return [...prevSelectedMedia, media];
    });
  };

  useEffect(() => {
    if (selectedCity) getRestaurants();
  }, [selectedCity]);

  useEffect(() => {
    if (selectedRestaurant) {
      getMedia(selectedRestaurant.id);
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    getCities();
  }, []);

  return (
    <div>
      <Header title={t('campaign.createCampaign')} />
      <ContentApp>
        <div className='font-medium fw-semibold'>
          {t(type)} {app.language !== 'es' || social === 'Código QR ' ? undefined : ' de '}
          {social !== 'Código QR ' ? social : null}
          <div className='font-1rem  mt-3'>{t('selectCityAndRestaurant')}</div>
        </div>
        {loadingCityAndRestaurants ? (
          SkeletonCreateCampaign()
        ) : (
          <>
            <Slider
              ref={sliderRef}
              slideSpacing={10}
              initialSlide={0}
              touchScroll={true}
              showBtns={false}
              visibleSlides={'all'}
            >
              {cities?.map((i, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectCity(i)}
                  className={`p-2 text-center mt-3`}
                  style={{
                    border: selectedCity?.id === i.id ? '1px solid #29A71A' : '',
                    backgroundColor: selectedCity?.id === i.id ? '#000' : '#131313',
                    borderRadius: '10px',
                    width: '160px',
                    color: selectedCity?.id === i.id ? '#fff' : '#7E7E7E',
                  }}
                >
                  {i.name}
                </div>
              ))}
            </Slider>
            {loadingSlider ? (
              <div className='mt-2'>
                <Skeleton
                  variant='rectangular'
                  sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '10px', height: 120 }}
                />
              </div>
            ) : (
              <Slider
                ref={sliderRef}
                slideSpacing={10}
                initialSlide={0}
                touchScroll={true}
                showBtns={false}
                visibleSlides={'all'}
              >
                {restaurants?.map((r, index) => (
                  <div
                    className='text-center font-smaller px-1 mt-3'
                    onClick={() => handleSelectedRestaurant(r)}
                    key={index}
                  >
                    <div
                      className={styles.containerImg}
                      style={{
                        border:
                          selectedRestaurant && selectedRestaurant.id === r.id
                            ? '2px solid #29A71A'
                            : '',
                      }}
                    >
                      <img className={styles.restaurantImg} src={r.logoUrl} alt='logo' />
                    </div>
                    <div className='mt-2'>{r.name}</div>
                  </div>
                ))}
              </Slider>
            )}
          </>
        )}
        <div className='text-secondary mx-2 my-4 semibold'>{t('campaign.addMediaText')}</div>
        {!selectedRestaurant ? (
          <div
            style={{ height: '256px' }}
            className='d-flex align-items-center justify-content-center text-secondary'
          >
            {t('selectCityAndRestaurant')}
          </div>
        ) : selectedRestaurant && media && media.length > 0 ? (
          <Slider
            ref={sliderRef}
            slideSpacing={10}
            initialSlide={0}
            touchScroll={true}
            showBtns={false}
            visibleSlides={'all'}
          >
            {media?.map((i, index) => (
              <div key={index}>
                <div
                  onClick={() => handleSelectedMedia(i)}
                  className={`${styles.imageContainer} position-relative`}
                  style={{
                    border: selectedMedia.some((item) => item.mediaUrl === i.mediaUrl)
                      ? '3px solid #29A71A'
                      : '',
                  }}
                >
                  {i.mediaUrl.includes('.mp4') ? (
                    <div className={`${styles.imageContainer} position-relative`}>
                      <div className={styles.imageContainerVideo}>
                        <video preload='metadata' autoPlay muted loop playsInline>
                          <source src={i.mediaUrl} type='video/mp4' />
                        </video>
                      </div>
                    </div>
                  ) : (
                    <img src={i.mediaUrl} alt='Media' />
                  )}
                  {i.mediaUrl.includes('.mp4')}
                  <div
                    className={`${styles.seeMore} ${styles.circleButton}
                             ${
                               selectedMedia.some((item) => item.mediaUrl === i.mediaUrl)
                                 ? styles.seeMoreAfter
                                 : ''
                             }`}
                  >
                    {selectedMedia.some((item) => item.mediaUrl === i.mediaUrl) && (
                      <div className={styles.checkIconContainer}>
                        <CheckIcon
                          size={'25px'}
                          checkColor='#fff'
                          circleColor='#29A71A'
                        ></CheckIcon>
                      </div>
                    )}
                  </div>
                </div>
                <div className='text-center font-small mt-2'>{i.title}</div>
              </div>
            ))}
          </Slider>
        ) : loadingContent ? (
          SkeletonCreateCampaignMedia()
        ) : (
          <EmptyActivity title={t('noContent')} />
        )}
        <Button
          size='large'
          text={t('continue')}
          className={`${styles.btn} mt-5`}
          disabled={!selectedRestaurant}
          onClick={() =>
            history.push({
              pathname: `/create-campaign-confirmation`,
              state: {
                media: selectedMedia,
                restaurant: selectedRestaurant,
                campaignType: {
                  ...selectedCity,
                  type,
                  social,
                  id,
                  networkId,
                },
              },
            })
          }
        />
      </ContentApp>
    </div>
  );
};

export default CreateCampaign2;
