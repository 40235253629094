export default (
  state = {
    status: 'LOADING',
    searchParams: '',
    language: null,
    tenantId: '',
    name: '',
    logoURL: '',
    imageURL: '',
    webURL: '',
    scopeOrganization: { organizationId: 83 },
    canRefresh: true,
    biometricAutomatic: '',
    biometricRequired: '',
    backGroundImageUrl: '',
    logoType: '',
    favIconUrl: '',
    titleWeb: '',
    countryCode: '',
    videoBackground: '',
    appType: '',
  },
  action,
) => {
  switch (action.type) {
    case 'APP_READY':
      return { ...state, status: 'READY' };
    case 'SET_TENANT_ID':
      return { ...state, tenantId: action.payload };
    case 'SET_APP_DATA':
      return {
        ...state,
        name: action.payload.name,
        logoURL: action.payload.logoURL,
        imageURL: action.payload.imageURL,
        webURL: action.payload.webURL,
        biometricAutomatic: action.payload.biometricAutomatic,
        biometricRequired: action.payload.biometricRequired,
        backGroundImageUrl: action.payload.backGroundImageUrl,
        videoBackground: action.payload.videoBackground,
        logoType: action.payload.logoType,
        favIconUrl: action.payload.favIconUrl,
        titleWeb: action.payload.titleWeb,
        countryCode: action.payload.countryCode,
        appType: action.payload.appType,
      };
    case 'ASSOCIATES_LOGOUT':
      return {
        ...state,
      };
    case 'SET_CAN_REFRESH':
      return { ...state, canRefresh: action.payload };
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload };
    default:
      return state;
  }
};
