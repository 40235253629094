import React, { useState, useRef, useEffect } from 'react';
import styles from './CampaignMediaCard.module.css';
import Header from '../Header/Header';
import ChevronIcon from '../../icons/chevronIcon';
import { useTranslation } from 'react-i18next';
import { CircleSpinner } from 'react-spinners-kit';
import { getLocalStorage, setLocalStorage } from '../../../helpers/verificationLocalStorage';
import { getDevice } from '../../../helpers/device';
import QuestionMark from '../../icons/QuestionMark';
import ProductModal from '../ProductModal/ProductModal';
import ContentApp from '../../ContentApp';
import InstagramIcon from '../../icons/InstagramIcon';
import StickerIcon from '../../icons/StickerIcon';
import LinkInstagramIcon from '../../icons/LinkInstagramIcon';
import step1Android from '../../../images/step1-android.png';
import step2Android from '../../../images/step2-android.png';
import step1 from '../../../images/step1.png';
import step2 from '../../../images/step2.png';
import step3 from '../../../images/step3.png';
import step4 from '../../../images/step4.png';
import Button from '../Button/Button';
import { Toast } from '../Toast/Toast';
import { SkeletonCampaignFeed } from '../../Skeletons/SkeletonCreateCampaign';

const CampaignMediaCard = ({ campaign, index }) => {
  const { t } = useTranslation();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showSteps, setShowSteps] = useState(false);
  const containerShareRef = useRef(null);
  const [downloading, setDownloading] = useState(false);
  const [copied, setCopied] = useState(false);
  const stepsCompletedCampaign = getLocalStorage('stepsCompletedCampaign');
  const [openShareModal, setOpenShareModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [isVideoTall, setIsVideoTall] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const videoRef = useRef(null);
  const isIOS = getDevice() === 'iOS';

  const toggleFullScreen = () => {
    if (isFullScreen) return;
    setIsFullScreen(true);
    if (videoRef.current) {
      videoRef.current.muted = isFullScreen;
    }
  };

  const normalizeFileName = (name) => {
    return name.toLowerCase().replace(/ /g, '-');
  };

  const handleVideoLoadedMetadata = () => {
    if (videoRef.current) {
      const { videoWidth, videoHeight } = videoRef.current;
      setIsVideoTall(videoHeight > videoWidth);
    }
  };

  const onCopy = (link) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleClickShare = (mediaUrl, type, link) => {
    setDownloading(true);
    if (window.webkit) {
      const request = { mediaUrl: mediaUrl, mediaType: type, link: link };

      try {
        window.webkit.messageHandlers.welletShare.postMessage(request);
      } catch (e) {
        setDownloading(false);
        console.error(e);
      }
    }
  };

  const handleShare = async (mediaUrl, mediaType, link) => {
    setDownloading(true);
    try {
      if (isIOS) {
        handleClickShare(mediaUrl, mediaType, link);
      } else {
        const response = await fetch(mediaUrl);
        if (!response.ok) throw new Error('Network response was not ok');

        const blob = await response.blob();
        const fileExtension = mediaUrl.includes('.mp4')
          ? '.mp4'
          : mediaUrl.includes('.webm')
          ? '.webm'
          : '.png';
        const filesArray = [
          new File([blob], `${normalizeFileName(campaign.campaignName)}${fileExtension}`, {
            type: blob.type,
          }),
        ];

        await navigator.share({
          title: campaign.campaignName,
          url: link,
          files: filesArray,
        });
      }
      setLocalStorage('stepsCompletedCampaign', 'true');
    } catch (error) {
      console.error('Error sharing campaign:', error);
    } finally {
      setDownloading(false);
    }
  };

  const handleOpenModal = () => {
    if (stepsCompletedCampaign) {
      setDownloading(true);
      handleShare(campaign?.media[0]?.mediaUrl, campaign?.media[0]?.mediaType, campaign.link);
      return;
    }

    setOpenShareModal(true);
  };

  const handleCloseShare = () => {
    setOpenShareModal(false);
  };

  const steps = [
    !isIOS && {
      img: step1Android,
      title: t('shareCampaigns.clickMore'),
    },
    !isIOS && {
      img: step2Android,
      title: t('shareCampaigns.selectStories'),
    },
    {
      img: step1,
      title: (
        <div>
          {t('shareCampaigns.clickOnThe')} <StickerIcon size={20} />
        </div>
      ),
    },
    {
      img: step2,
      title: (
        <div>
          {t('shareCampaigns.clickOnTheStiker')} <LinkInstagramIcon />
        </div>
      ),
    },
    {
      img: step3,
      title: t('shareCampaigns.pasteTheLink'),
    },
    {
      img: step4,
      title: t('shareCampaigns.frameYourLink'),
    },
  ].filter(Boolean);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerShareRef.current &&
        !containerShareRef.current.contains(event.target) &&
        !openShareModal
      ) {
        setShowSteps(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handlePlayPause = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.play();
        } else {
          entry.target.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handlePlayPause, {
      threshold: 0.9,
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      {!videoLoaded && SkeletonCampaignFeed()}
      <div
        key={index}
        className={`${isFullScreen ? styles.fullScreenContainer : 'mt-3'}`}
        style={{ display: videoLoaded ? 'block' : 'none' }}
      >
        {!isFullScreen && (
          <div className='d-flex align-items-center my-2'>
            <div className={`col-auto pl-0`}>
              <div className={styles.containerImg}>
                <img src={campaign.logo} className={styles.restaurantImg} alt='logo' />
              </div>
            </div>
            <div className='col pl-0 font-1rem'>
              <div className='bold'>{campaign.campaignName}</div>
              <div className='text-secondary'>{campaign.mktEntityName}</div>
            </div>
          </div>
        )}
        {isFullScreen && (
          <Header zIndex={9999999999} absolute onClick={() => setIsFullScreen(false)} />
        )}
        <>
          {campaign?.media[0]?.mediaUrl?.includes('mp4') ? (
            <video
              ref={videoRef}
              src={campaign?.media[0]?.mediaUrl + '#t=0.1'}
              loop
              autoPlay
              className={`${isFullScreen ? styles.fullScreenVideo : styles.feedVideo}`}
              webkit-playsinline='true'
              x5-playsinline='true'
              playsInline
              onClick={toggleFullScreen}
              onLoadedMetadata={handleVideoLoadedMetadata}
              onLoadedData={() => setVideoLoaded(true)}
              style={isFullScreen && isVideoTall ? { objectFit: 'cover' } : {}}
              muted={!isFullScreen}
            />
          ) : (
            <img
              src={campaign?.media[0]?.mediaUrl}
              className={`${isFullScreen ? styles.fullScreenImg : styles.feedImg}`}
              alt='media'
              onClick={toggleFullScreen}
              onLoad={() => setVideoLoaded(true)}
            />
          )}

          <div
            style={{
              display: isFullScreen ? 'block' : 'none',
            }}
            onClick={() => setShowSteps(true)}
            className={styles.shareText}
          >
            <ChevronIcon size={25} rotation={270} />
            <br />
            {t('shareOnYourStory')} <br /> {t('of')} <span className='fw-semibold'>Instagram</span>
          </div>

          <div
            className={`${styles.containerSteps} ${showSteps ? styles.show : ''}`}
            style={{ display: !showSteps && 'none' }}
            ref={containerShareRef}
          >
            <div className='text-center text-white mt-3'>{campaign?.campaignName}</div>
            <div className={`m-3 px-3 py-4 ${styles.stepCard}`}>
              <div className='mb-3 px-3 bold'>{t('shareCampaigns.step1')}</div>
              <Button
                className='btn-primary'
                onClick={() => onCopy(campaign?.link)}
                text={t('shareCampaigns.copyLink')}
                size='medium'
                width={'50%'}
              />
            </div>
            <div className={`mx-3 p-3 ${styles.stepCard}`}>
              <span className='mb-3 px-3 bold'>{t('shareCampaigns.step2')}</span>
              <Button
                className='btn-primary'
                width={'50%'}
                size='medium'
                onClick={handleOpenModal}
                text={
                  downloading ? (
                    <div className='d-flex justify-content-center'>
                      <CircleSpinner
                        className=''
                        style={{ margin: 'auto' }}
                        size={23}
                        color='var(--color-font-primary)'
                      />
                    </div>
                  ) : (
                    t('shareCampaigns.share')
                  )
                }
              />
            </div>
            {copied && (
              <Toast
                bottom={'15px'}
                zIndex={100000}
                type={'success'}
                message={t('linkCopied')}
                delay={3}
              />
            )}
            <div className='text-center text-secondary' onClick={() => setOpenShareModal(true)}>
              <QuestionMark size={20} color='#7e7e7e' />
              <span className='ml-2 '>{t('howToShare')}</span>
            </div>
          </div>
        </>
        <ProductModal
          animationClass='fade-up-down'
          open={openShareModal}
          hasBackground
          onClose={() => handleCloseShare()}
          showBack={false}
          showClose
          backIcon
          customStyle={{
            backgroundColor: 'transparent',
            border: 'none',
          }}
        >
          <ContentApp classes='pt-0'>
            <div className='d-flex justify-content-center'>
              <div className={styles.iconButton}>
                <InstagramIcon size={20} color='#000' />
              </div>
            </div>
            <div className={styles.cardShareCampaigns}>
              <div className='mt-2 px-3 pt-3'>{t('shareCampaigns.howToAdd')}</div>
              <div className={styles.stepsButtons}>
                {steps.map((step, idx) => (
                  <button
                    key={idx}
                    style={{
                      backgroundColor: activeStep === idx + 1 ? '#000' : '',
                      color: activeStep === idx + 1 ? '#fff' : '#000',
                    }}
                    onClick={() => setActiveStep(idx + 1)}
                  >
                    {idx + 1}
                  </button>
                ))}
              </div>
              {steps.map((step, idx) => (
                <div key={idx} style={{ display: idx + 1 === activeStep ? 'block' : 'none' }}>
                  <div className='my-3'>{step.title}</div>
                  <img className={styles.imgStep} src={step.img} alt={`Step ${idx + 1}`} />
                </div>
              ))}
              {(activeStep === 4 && isIOS) || (activeStep === 6 && !isIOS) ? (
                <>
                  <button
                    className={styles.nextStepButton}
                    onClick={() =>
                      handleShare(
                        campaign?.media[0]?.mediaUrl,
                        campaign?.media[0]?.mediaType,
                        campaign.link,
                      )
                    }
                  >
                    {downloading ? (
                      <div className='d-flex justify-content-center'>
                        <CircleSpinner
                          className=''
                          style={{ margin: 'auto' }}
                          size={23}
                          color='var(--color-font-primary)'
                        />
                      </div>
                    ) : (
                      t('shareCampaigns.shareInstagram')
                    )}
                  </button>
                </>
              ) : (
                <button
                  className={styles.nextStepButton}
                  onClick={() => setActiveStep(activeStep + 1)}
                >
                  {t('shareCampaigns.nextStep')}
                </button>
              )}
            </div>
          </ContentApp>
        </ProductModal>
      </div>
    </>
  );
};

export default CampaignMediaCard;
