import React from 'react';

const BroadcastMessageIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 52 52'
      fill='none'
    >
      <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
      <g clipPath='url(#clip0_397_1772)'>
        <path
          d='M16.4363 18.5675H20.9467V31.5587H16.4363C14.0385 31.5587 12.093 29.6576 12.093 27.3144V22.8117C12.093 20.4686 14.0385 18.5675 16.4363 18.5675Z'
          fill='white'
        />
        <path d='M32.7447 34.9595L22.2205 31.5587V18.5675L32.7447 15.1667V34.9595Z' fill='white' />
        <path
          d='M18.6637 32.6299H21.5001L24.3365 33.5549V36.5307C24.3365 38.061 23.0662 39.3023 21.5001 39.3023C19.934 39.3023 18.6637 38.061 18.6637 36.5307V32.6299Z'
          fill='white'
        />
        <path
          d='M39.907 15.5748C39.907 13.9858 38.5888 12.6977 36.9627 12.6977C35.3366 12.6977 34.0184 13.9858 34.0184 15.5748V34.5514C34.0184 36.1404 35.3366 37.4285 36.9627 37.4285C38.5888 37.4285 39.907 36.1404 39.907 34.5514V15.5748Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_397_1772'>
          <rect
            width='27.814'
            height='26.6047'
            fill='white'
            transform='translate(12.093 12.6977)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BroadcastMessageIcon;
