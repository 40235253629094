import React, { useContext, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import ContentApp from '../../../../components/ContentApp';
import styles from './CreateCampaign.module.css';
import InputFormBorder from '../../../../components/Common/InputFormBorder/InputFormBorder';
import { ExclamationIcon } from '../../../../components/icons/ExclamationIcon';
import { WelletContext } from '../../../../context/WelletContext';
import Button from '../../../../components/Common/Button/Button';
import { DateRange } from 'react-date-range';
import { useSelector } from 'react-redux';
import { calendarDate, getLocale } from '../../../../helpers/dates';
import CalendarFilterIcon from '../../../../components/icons/CalendarFilterIcon';
import ProductModal from '../../../../components/Common/ProductModal/ProductModal';
import { useTranslation } from 'react-i18next';
import ButtonProccessing from '../../../../components/Common/ButtonProccessing/ButtonProccessing';
import PlayIcon from '../../../../components/icons/PlayIcon';

const CreateCampaignConfirmation = ({ history }) => {
  const ButtonStatus = {
    Normal: 'NORMAL',
    InProgress: 'INPROGRESS',
    ProgressFinished: 'FINISHED',
    Error: 'ERROR',
  };
  const { t } = useTranslation();
  const [btnStatus, setBtnStatus] = useState(ButtonStatus.Normal);

  const welletContext = useContext(WelletContext);
  const app = useSelector((state) => state.app);
  const [showCalendar, setShowCalendar] = useState(false);
  const jwt = useSelector((state) => state.associates.jwt);
  const locale = getLocale(app.language);
  const selectedMedia = history?.location?.state?.media;
  const selectedRestaurant = history?.location?.state?.restaurant;
  const selectedCampaignType = history?.location?.state?.campaignType;
  const [inputError, setInputError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInfo({
      ...info,
      [name]: value,
    });

    if (name === 'campaignName' && !value) {
      setInputError(t('campaignError.name'));
    } else {
      setInputError(null);
    }

    if (name === 'campaignDescription' && !value) {
      setDescriptionError(t('campaignError.description'));
    } else {
      setDescriptionError(null);
    }
  };

  const handleApplyDateRange = () => {
    const [selectedDate] = initialDates;
    setInfo({
      ...info,
      campaignDate: {
        from: selectedDate.startDate,
        to: selectedDate.endDate,
      },
    });
    setShowCalendar(false);
  };

  const [initialDates, setInitialDates] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const [info, setInfo] = useState({
    campaignName: '',
    campaignDescription: '',
    campaignDate: {
      from: null,
      to: null,
    },
  });

  const sendForm = async (e) => {
    e.preventDefault();
    if (!info.campaignName) {
      setInputError(t('campaignError.name'));
      return;
    }

    if (!info.campaignDescription) {
      setDescriptionError(t('campaignError.description'));
      return;
    }
    setBtnStatus(ButtonStatus.InProgress);

    try {
      const response = await welletContext.apis.tickets.post(
        '/campaign/create',
        {
          ShowId: selectedRestaurant.id,
          NetworkCampaignId: selectedCampaignType.networkId,
          CampaignTypeId: selectedCampaignType.id,
          Name: info.campaignName,
          Description: info.campaignDescription,
          MediaId: 1,
          MediaList: selectedMedia.map((selected) => ({
            MediaId: selected.id,
            MediaType: selected.mediaUrl.includes('.mp4') ? 'VIDEO' : 'PHOTO',
          })),
          ToDate: info.campaignDate.to ?? '',
          FromDate: info.campaignDate.from ?? '',
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        },
      );

      setBtnStatus(ButtonStatus.ProgressFinished);
      setTimeout(() => {
        history.push(
          `/success-campaign?id=${response.data}&name=${selectedCampaignType.name}&type=${selectedCampaignType.type}`,
        );
      }, 1500);
    } catch (error) {
      setBtnStatus(ButtonStatus.Error);

      console.error(error);
    }
  };

  const calendarButton = () => {
    const { from, to } = info.campaignDate;
    const formattedStartDate = calendarDate(from, locale);
    const formattedEndDate = calendarDate(to, locale);
    if (from && to) {
      return (
        <div
          className={`position-relative  row align-items-center flex-nowrap font-small ${styles.calendarButton}`}
        >
          <div className='col-auto '>
            <CalendarFilterIcon size={20} />
          </div>
          <div className='col pl-2 text-truncate fw-semibold'>{`${formattedStartDate} - ${formattedEndDate}`}</div>
        </div>
      );
    } else {
      return (
        <div
          className={`position-relative  row align-items-center flex-nowrap font-small ${styles.calendarButton}`}
        >
          <div className='col-auto px-1'>
            <CalendarFilterIcon size={20} />
          </div>
          <div className='col pl-2 text-truncate fw-semibold'>{t('campaign.selectDateRange')}</div>
        </div>
      );
    }
  };

  return (
    <div>
      <Header title={t('campaign.createCampaignOnly')} />
      <ContentApp>
        <form onSubmit={sendForm}>
          <div className='text-secondary font-large bold'>{selectedRestaurant.name}</div>
          <div className='bold font-normal mt-2'>
            {t(selectedCampaignType.type)} {app.language === 'es' ? 'de' : null}{' '}
            {selectedCampaignType.social}
          </div>
          <div className='bold font-normal mt-2'>{t('campaign.ventas')}</div>
          {selectedMedia.length > 0 ? (
            <>
              {selectedMedia.map((selected, index) => {
                return (
                  <div className='d-flex align-items-center' key={index}>
                    {selected.mediaUrl.includes('.mp4') ? (
                      <div
                        className={`video-item ${styles.selectedVideo} position-relative`}
                        controls='controls'
                      >
                        <video preload='metadata'>
                          <source src={selected.mediaUrl + '#t=0.1'} type='video/mp4' />
                        </video>
                        {selected.mediaUrl.includes('.mp4') && (
                          <div className={styles.playButton}>
                            <PlayIcon size='45px' color='#fff' />
                          </div>
                        )}
                      </div>
                    ) : (
                      <img className={styles.selectedMedia} src={selected.mediaUrl} alt='Media' />
                    )}
                    <div className='ml-3 font-medium bold'>{selected.title}</div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className='semi-bold mt-2'>Sin contenido multimedia adicional</div>
          )}

          <InputFormBorder
            name='campaignName'
            type='text'
            label={t('campaign.campaignName')}
            placeholder={t('campaign.name')}
            forceLowecase={true}
            border={'none'}
            customClass='mt-5 px-2 text-secondary input-create-campaign'
            onChange={handleInputChange}
          />

          {inputError && <div className='errorLabelCampaign mt-2 '>{inputError}</div>}

          <div className='text-secondary font-smaller p-2 row'>
            <div className='col-auto pr-0'>
              <ExclamationIcon color='var(--color-deep-gray)' styles={{ marginRight: '5px' }} />
            </div>
            <div className='col pl-2'>{t('campaign.findCampaignDetail')}</div>
          </div>
          <div className='position-relative'>
            <div className={`${styles.descriptionLabel} bold text-secondary `}>
              {t('description')}
            </div>
            <textarea
              placeholder={t('description')}
              className={styles.textarea}
              name='campaignDescription'
              value={info.campaignDescription}
              onChange={handleInputChange}
            ></textarea>
            {descriptionError && <div className='errorLabelCampaign mt-2'>{descriptionError}</div>}
          </div>
          <div className='font-1rem mt-3 bold'>{t('campaign.campaignLength')}</div>
          <div className='text-secondary font-tiny mt-2'>{t('campaign.campaignLengthText')}</div>
          <div
            className='text-truncate my-2'
            style={{ borderRadius: '.5rem' }}
            onClick={() => setShowCalendar(true)}
          >
            {calendarButton()}
          </div>
          <ButtonProccessing
            status={btnStatus}
            type='submit'
            size='large'
            onClick={sendForm}
            text={t('campaign.createCampaignOnly')}
            className={`mt-3 ${styles.btn}`}
            backgroundColor='var(--color-primary)'
          />
          <ProductModal
            open={showCalendar}
            animationClass='fade-up-horizontal'
            showCloseButton={false}
            customStyle={{ position: 'absolute', top: '15%' }}
          >
            <DateRange
              onChange={(item) => setInitialDates([item.selection])}
              ranges={initialDates}
              months={1}
              direction='horizontal'
              locale={locale}
              showDateDisplay={false}
            />

            <div
              className='d-flex justify-content-end mb-2 mr-3'
              style={{ backgroundColor: '#fff' }}
            >
              <Button
                size={'small'}
                backgroundColor='var(--color-light-gray)'
                color='#000'
                text={<div className='light text-white'>{t('cancel')}</div>}
                className='mr-3'
                onClick={() => setShowCalendar(false)}
              />
              <Button
                onClick={handleApplyDateRange}
                size={'small'}
                className='btn-secondary light text-white'
                text={t('apply')}
                type='button'
              />
            </div>
          </ProductModal>
        </form>
      </ContentApp>
    </div>
  );
};

export default CreateCampaignConfirmation;
