import React from 'react';

const LinkSocialIcon = ({ size, color }) => {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M320 941 c-5 -11 -10 -34 -10 -51 0 -44 17 -70 45 -70 28 0 45 26 45
67 0 59 -56 97 -80 54z'
        />
        <path
          d='M626 905 c-24 -8 -67 -41 -109 -83 -60 -61 -68 -72 -58 -89 21 -40
40 -35 104 26 85 81 138 93 217 51 33 -18 61 -88 53 -135 -5 -33 -24 -57 -102
-138 -108 -112 -132 -127 -194 -127 -34 0 -53 7 -82 30 -43 34 -57 37 -79 14
-21 -20 -20 -26 4 -57 49 -62 177 -89 250 -52 48 23 237 211 263 260 28 54 28
136 -1 192 -52 100 -163 145 -266 108z'
        />
        <path
          d='M107 852 c-10 -10 -17 -23 -17 -28 0 -5 29 -38 64 -73 50 -50 68 -62
84 -57 12 4 24 16 28 28 5 16 -7 34 -57 84 -35 35 -68 64 -75 64 -6 0 -18 -8
-27 -18z'
        />
        <path
          d='M15 637 c-30 -23 -13 -65 30 -73 68 -12 119 30 85 72 -16 18 -90 19
-115 1z'
        />
        <path
          d='M361 628 c-38 -8 -63 -28 -162 -125 -118 -115 -146 -154 -156 -218
-14 -89 57 -200 148 -230 95 -32 165 -7 259 90 51 54 59 67 51 84 -21 38 -40
32 -111 -33 -74 -68 -107 -81 -168 -64 -69 19 -108 90 -92 165 7 30 32 60 119
146 l109 107 56 0 c47 0 60 -4 89 -30 37 -32 56 -37 75 -18 31 31 1 78 -73
116 -33 16 -97 21 -144 10z'
        />
        <path
          d='M831 381 c-35 -35 -4 -71 63 -71 34 0 66 21 66 43 0 26 -20 39 -63
44 -36 4 -49 1 -66 -16z'
        />
        <path
          d='M702 258 c-23 -23 -12 -45 53 -107 54 -52 67 -60 84 -52 39 21 33 41
-31 107 -61 64 -83 75 -106 52z'
        />
        <path
          d='M584 136 c-24 -18 -28 -111 -4 -126 41 -26 72 5 68 68 -4 55 -32 81
-64 58z'
        />
      </g>
    </svg>
  );
};

export default LinkSocialIcon;
