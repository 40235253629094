import React, { useState, useRef, useEffect } from 'react';
import styles from './CreditCard.module.css';
import chipTarjeta from './../../../images/chip-tarjeta.png';
import fondoTarjeta from './../../../images/tarjeta_fondo.png';
import Slider from '../../Common/Slider/Slider';
import VisaIcon from '../../icons/ConsumerIcons/VisaIcon';
import AddCard from './AddCreditCard';
import AmexIcon from '../../icons/ConsumerIcons/AmexIcon';
import MasterCardIcon from '../../icons/ConsumerIcons/MasterCardIcon';


const CreditCard = ({ onCardIdChange, cards, onCardVisibilityChange }) => {
  const [currentCardId, setCurrentCardId] = useState(null);
  const [observedIndex, setObservedIndex] = useState(null); 
  const sliderRef = useRef(null);
  const slideRefs = useRef([]);
  const addCardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === addCardRef.current) {
            onCardVisibilityChange(true);
            onCardIdChange(null);
            setObservedIndex(null); 
          } else {
            const slideIndex = slideRefs.current.indexOf(entry.target);
            if (cards[slideIndex]) {
              const cardId = cards[slideIndex].id;
              setCurrentCardId(cardId);
              onCardIdChange(cardId);
              onCardVisibilityChange(false);
              setObservedIndex(slideIndex); 
            }
          }
        }
      });
    }, { threshold: 0.8 });

    slideRefs.current.forEach((slide) => observer.observe(slide));
    if (addCardRef.current) {
      observer.observe(addCardRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [cards, onCardIdChange, onCardVisibilityChange]);


  const tarjetaStyle = (index) => ({
    width: '300px',
    height: '180px',
    backgroundImage: `url(${fondoTarjeta})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '10px',
    position: 'relative',
    overflow: 'hidden',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    paddingTop: '10px',
    marginLeft: '40px',
    border: index === observedIndex ? '2px solid #1D9BF0' : '1px solid transparent',
  });

  return (
    <div className={styles.sliderContainer} ref={sliderRef}>
      <Slider
        slideSpacing={10}
        initialSlide={0}
        touchScroll={true}
        showBtns={false}
        onCardChange={(currentSlide) => {
          if (cards[currentSlide]) {
            const cardId = cards[currentSlide].id;
            setCurrentCardId(cardId);
            onCardIdChange(cardId);
            onCardVisibilityChange(false);
            setObservedIndex(currentSlide); 
          }
        }}
      >
        {cards.map(({ id, last4, brand, country, expirationMonth, expirationYear, cardholder }, index) => (
          <div
            className={styles.trajeta}
            style={tarjetaStyle(index)} 
            key={id}
            ref={(el) => (slideRefs.current[index] = el)}
          >
            <div className={styles.delantera}>
              <div className={styles.logoYChip}>
                <img src={chipTarjeta} className={styles.chip} alt='Chip de la tarjeta' />
                {brand === 'visa' && <div className={styles.logoMarca}><VisaIcon /></div>}
                {brand === 'amex' && <div className={styles.logoMarcaAmex}><AmexIcon /></div>}
                {brand === 'mastercard' && <div className={styles.logoMarcaAmex}><MasterCardIcon /></div>}
              </div>

              <div>
                <div className={styles.grupo}>
                  <p className={styles.numero}>**** **** **** **** {last4}</p>
                </div>
                <div className={styles.flexbox}>
                  <div className={styles.grupo}>
                    <p className={styles.label}>Card Holder Name</p>
                    <p className={styles.nombre}>{cardholder}</p>
                  </div>
                  <div className={styles.grupo}>
                    <p className={styles.label}>Expiry Date</p>
                    <p className={styles.expiracion}>
                      <span className={styles.mes}>{expirationMonth.toString().padStart(2, '0')}</span> / <span className={styles.year}>{expirationYear}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <AddCard ref={addCardRef} />
      </Slider>
    </div>
  );
};

export default CreditCard;
