import React, { useEffect, useState, useContext } from 'react';
import Header from '../../../components/Common/Header/Header';
import { useParams } from 'react-router-dom';
import { WelletContext } from '../../../context/WelletContext';

import ContentApp from '../../../components/ContentApp';
import Button from '../../../components/Common/Button/Button';

const CreateCard = () => {
  const welletContext = useContext(WelletContext);
  const { pos } = useParams();
  const [saveCardIntent, setSaveCardIntent] = useState(null);

  const getSaveCardIntent = async () => {
    try {
      const response = await welletContext.apis.tickets.post(`/pos/${pos}/saveCardIntent`);
      //window.wellet = window.Wellet(response.data.gateway.publicKey);
      setSaveCardIntent(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSaveCardIntent();
  }, []);

  useEffect(() => {
    if (!saveCardIntent) return;

    window.wellet = window.Wellet(saveCardIntent.publicKey);
    window.wellet.mountSaveCardForm('wellet-gateway-form', {
      lang: 'es',
      clientSecret: saveCardIntent.intentClientSecret,
      appearance: {
        theme: 'dark',
        variables: {
          colorText: "#ffffff",
          colorBackground: "#000000",
          colorDanger: "#f80005",
        },
      }
    });
  }, [saveCardIntent]);

  return (
    <div>
      <ContentApp>
        <Header title='Agregar Tarjeta'></Header>
        <div id='wellet-gateway-form'></div>
        <Button
          onClick={() => window.wellet.confirmSaveCard()}
          className={` btn mt-2`}
          text={
            <div className='bold' style={{ fontSize: '15px' }}>
              Agregar Tarjeta
            </div>
          }
          size='large'
        ></Button>
      </ContentApp>
    </div>
  );
};

export default CreateCard;
