import React, { useState, useRef, useEffect } from 'react';
import styles from './accordionStatus.module.css';
import SolidChevron from '../../icons/SolidChevron';
import { formatMoney } from '../../../helpers/format';
const AccordionStatus = ({
  children,
  status,
  category,
  points,
  index,
  categoryName,
  isCurrentOrFuture,
}) => {
  const [selected, setSelected] = useState(
    isCurrentOrFuture && categoryName === category.name ? index : null
  );
  const [height, setHeight] = useState(null);
  const accordionRef = useRef(null);
  const inprogressPoint =
    points === 0 ? (points * 100) / category.maxPoints : (points * 100) / category.targetPoints;
  const inprogressHeight = height * (inprogressPoint / 100);

  const toggle = () => {
    if (isCurrentOrFuture) {
      setSelected(selected === null ? index : null);
    }
  };

  useEffect(() => {
    const updateComponentHeight = () => {
      if (accordionRef.current) {
        const height = accordionRef.current.clientHeight;
        setHeight(height);
      }
    };
    updateComponentHeight();
    window.addEventListener('resize', updateComponentHeight);
    return () => {
      window.removeEventListener('resize', updateComponentHeight);
    };
  }, []);

  const shouldDisplayDollarSign = category.level !== 1;

  return (
    <div className={`${styles.accordionContainer}`}>
      <div className={styles.wrapperAccordion}>
        <div className={styles.accordion}>
          <div className={styles.item}>
            <div className={`${styles.accordionContainer}`}>
              <div
                className='my-auto row mt-2 px-2'
                onClick={toggle}
                style={{ cursor: isCurrentOrFuture ? 'pointer' : 'not-allowed' }}
              >
                <div className={`${styles.points} col-auto py-3 font-tiny text-secondary`}>
                  <span>{`${formatMoney(
                    category.targetPoints,
                    { decimals: 0, currencySymbol: '' },
                    true,
                  )}`}</span>
                </div>
                <div
  className={`${
    category.level === 1 || !isCurrentOrFuture
      ? styles.completed
      : status === 'completed'
      ? styles.completed
      : status === 'incompleted'
      ? styles.incompleted
      : status === 'inprogress'
      ? styles.inprogress
      : ''
  } py-3 position-relative`}
  ref={accordionRef}
>
  {(category.level === 1 || !isCurrentOrFuture) && (
    <div className={`${styles.dot} mt-2`} />
  )}
  
  {status === 'inprogress' && category.level !== 1 && isCurrentOrFuture && (
    <>
      <div className={`${styles.emptyDot} mt-2`} />
      {shouldDisplayDollarSign && (
        <div
          className={
            selected === index
              ? `${styles.inprogressDot} font-tiny bold light`
              : 'd-none'
          }
          style={{
            bottom: `${
              points > category.targetPoints && status === 'inprogress'
                ? accordionRef?.current?.clientHeight
                : inprogressHeight
            }px`,
            visibility: selected === index ? 'visible' : 'hidden',
            opacity: selected === index ? '1' : '0',
            color: '#fff',
          }}
        >
          $
        </div>
      )}
      <div
        className={selected === index ? styles.inprogressBorder : 'd-none'}
        style={{
          height: `${
            points > category.targetPoints && status === 'inprogress'
              ? accordionRef?.current?.clientHeight
              : inprogressHeight
          }px`,
          visibility: selected === index ? 'visible' : 'hidden',
          opacity: selected === index ? '1' : '0',
        }}
      ></div>
    </>
  )}
</div>

                <div className='col py-3 pr-0'>
                  <div
                    className={`d-flex align-items-center ${
                      categoryName === category.name ? 'bold' : 'text-secondary bold'
                    }`}
                  >
                    {category.name}
                    <span className={categoryName === category.name ? 'mb-1 ml-2' : 'd-none'}>
                      <div className={`${styles.currentDot} mt-1`} />
                    </span>
                  </div>
                  <div
                    className={
                      selected === index ? `${styles.content} ${styles.show}` : `${styles.content}`
                    }
                  >
                    {children}
                  </div>
                </div>

                {isCurrentOrFuture && (
                  <div className='col-auto py-3 pl-1'>
                    <SolidChevron
                      color='var(--color-primary)'
                      size='18px'
                      rotation={selected === index ? -180 : 0}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionStatus;

