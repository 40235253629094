import React, { useState, useEffect } from 'react';
import { Camera } from 'react-camera-pro';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ScanIdScreen = ({ position, onCaptureDocument }) => {
  const REAR_CAMERA = 'environment';
  const { t } = useTranslation();
  const [showCamera, setShowCamera] = useState(true);
  const camera = useRef(null);
  const isAndroid = /Android/.test(navigator.userAgent);

  const errorMessages = {
    noCameraAccessible: isAndroid
      ? t('cameraPermissionError.NO_CAMERA_ACCESSIBLE')
      : t('cameraPermissionError.ENABLE_PERMISSIONS'),
    permissionDenied: isAndroid
      ? t('cameraPermissionError.NO_CAMERA_ACCESSIBLE')
      : t('cameraPermissionError.ENABLE_PERMISSIONS'),
    switchCamera: undefined,
    canvas: undefined,
  };

  const onClickCapture = (img) => {
    onCaptureDocument(img);
  };

  useEffect(() => {
    return () => {
      setShowCamera(false);
    };
  }, []);

  return (
    <div className='text-center'>
      {position === 'front' ? (
        <div className='font-normal bold' style={{ color: '#fff' }}>
          {t('kyc.validateFrontTitle')}
        </div>
      ) : position === 'back' ? (
        <div className='font-normal bold' style={{ color: '#fff' }}>
          {t('kyc.validateBackTitle')}
        </div>
      ) : null}
      <div className=' text-secondary mt-2'>{t('kyc.validateDescription')}</div>
      <div
        className='mt-4 position-relative '
        style={{ backgroundColor: '#fff', padding: '1rem', borderRadius: '10px' }}
      >
        {position === 'front' ? (
          <span
            className='position-absolute'
            style={{
              color: '#000',
              top: '2%',
              left: '50%',
              fontSize: '11px',
              transform: 'translateX(-50%)',
              width: '100%',
              zIndex: 20,
            }}
          >
            {t('kyc.frontId').toUpperCase()}
          </span>
        ) : position === 'back' ? (
          <span
            className='position-absolute'
            style={{
              color: '#000',
              top: '2%',
              left: '50%',
              fontSize: '11px',
              transform: 'translateX(-50%)',
              width: '100%',
              zIndex: 20,
            }}
          >
            {t('kyc.backId').toUpperCase()}
          </span>
        ) : null}
        <div className='camera-container ' style={{ height: '250px', width: '100%' }}>
          <div className={`mask-rectangle `}>
            {showCamera && (
              <Camera errorMessages={errorMessages} ref={camera} facingMode={REAR_CAMERA} />
            )}
          </div>
        </div>
        <div className='font-small mt-3'>{t('kyc.validateText')}</div>
        <div className='position-fixed w-100 col-12  pt-2 circle-button'>
          <button
            onClick={() => camera.current && onClickCapture(camera.current.takePhoto())}
            className='picture-button'
          ></button>
        </div>
      </div>
    </div>
  );
};
export default ScanIdScreen;
