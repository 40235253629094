export const getReservations = async (welletContext, body) => {
  try {
    const result = await welletContext.apis.tickets.post(`/order/all`, body);
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchCities = async (welletContext) => {
  try {
    const result = await welletContext.apis.tickets.get('/mktcampaign/cities');
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const putReference = async (welletContext, mktCampaign, body) => {
  try {
    let url = '/mktcampaign/reference';
    switch (mktCampaign.mktCampaignType) {
      case 'RESERVATION':
        url += `/put-reservation`;
        break;
      case 'AREA':
        url += `/put-area`;
        break;
      case 'RESERVATIONGROUP':
        url += `/put-reservationgroup`;
        break;
      default:
        break;
    }
    const result = await welletContext.apis.tickets.put(url, body);
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
