import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validateDate } from '../../../helpers/dates';
import { WelletContext } from '../../../context/WelletContext';
import { skeletonNotifications } from '../../../components/Skeletons/SkeletonNotifications';
import { useTranslation } from 'react-i18next';
import ContentApp from '../../../components/ContentApp';
import styles from './notifications.module.css';
import CustomerNav from '../../../components/CustomerNav/CustomerNav';
import AccountHeader from '../../../components/AccountHeader/AccountHeader';
import Notification from '../../../components/Notifications/Notification/Notification';
import EmptyActivity from '../../../components/Common/EmptyActivity/EmptyActivity';
import NavSelector from '../../../components/NavSelector/NavSelector';

const Notifications = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const welletContext = useContext(WelletContext);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState(null);
  const language = useSelector((state) => state.app.language);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const result = await welletContext.apis.tickets.get('/api/Notification/get');
      setNotifications(result.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!notifications) {
      getNotifications();
    }
  }, []);
  return (
    <>
      <AccountHeader title={t('notifications')} icon='config' pathName='/configuration' />
      <ContentApp header menu>
        {!loading ? (
          notifications.length === 0 ? (
            <EmptyActivity title={t('noNotifications')} />
          ) : (
            <div className={`${styles.container} fade-up`}>
              {notifications?.map((n, index) => (
                <Notification
                  key={index}
                  id={n.id}
                  title={n.tittleMessage}
                  description={n.bodyMessage}
                  date={validateDate(n.createDate, language, 'HH:mm', 'dd MMM')}
                  isViewed={n.isViewed}
                />
              ))}
            </div>
          )
        ) : (
          skeletonNotifications()
        )}
        <NavSelector history={history} />
      </ContentApp>
    </>
  );
};

export default Notifications;
