import React from 'react';

const StatusIcon = ({ size = '24px' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 52 52'
    fill='none'
  >
    <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
    <path
      d='M19.7666 12.8434C22.0383 11.5583 24.604 10.8832 27.214 10.8837C29.5105 10.8847 31.7765 11.4087 33.8401 12.4161C34.6168 12.7952 35.3572 13.2391 36.0539 13.7418C36.6065 14.1405 36.6349 14.9343 36.153 15.4161C35.7253 15.8438 35.0452 15.8699 34.5498 15.5229C34.244 15.3088 33.9281 15.1076 33.6026 14.9199C31.163 13.5131 28.3272 12.9506 25.5352 13.3197C23.51 13.5874 21.5899 14.3345 19.9284 15.4851C19.4323 15.8287 18.7544 15.8009 18.3277 15.3742C17.8434 14.8899 17.8739 14.0915 18.4315 13.6938C18.8602 13.388 19.3058 13.1041 19.7666 12.8434Z'
      fill='white'
    />
    <path
      d='M12.098 25.6103C12.1653 23.0013 12.907 20.4543 14.2508 18.217C14.3722 18.015 14.498 17.8162 14.6283 17.6206C15.0166 17.0376 15.8362 16.9938 16.3316 17.4892C16.7485 17.9062 16.7868 18.5645 16.4668 19.0597C15.4429 20.6447 14.7762 22.4465 14.5283 24.3395C14.1627 27.1317 14.7288 29.9664 16.1389 32.4041C16.2213 32.5467 16.2724 32.7052 16.289 32.8691C16.3055 33.0329 16.287 33.1984 16.2348 33.3547L14.4221 38.7907L19.8587 36.9768C20.015 36.9247 20.1806 36.9065 20.3445 36.9233C20.5084 36.9401 20.6668 36.9915 20.8094 37.0741C22.7559 38.2001 24.9652 38.7922 27.214 38.7907C29.6527 38.7903 32.0321 38.0931 34.0768 36.7928C34.5734 36.477 35.2301 36.5198 35.6435 36.9387C36.135 37.4368 36.0857 38.2552 35.5005 38.6391C34.8198 39.0856 34.1022 39.4778 33.354 39.8107C31.2559 40.7442 28.9727 41.1875 26.6777 41.107C24.3827 41.0265 22.1362 40.4242 20.1087 39.3459L15.1591 40.9956C14.7493 41.1323 14.3096 41.1521 13.8891 41.0529C13.4687 40.9537 13.0842 40.7394 12.7787 40.4339C12.4733 40.1285 12.2589 39.7441 12.1597 39.3237C12.0605 38.9033 12.0803 38.4635 12.217 38.0538L13.8668 33.1047C12.6401 30.8011 12.0308 28.2192 12.098 25.6103Z'
      fill='white'
    />
    <path
      d='M41.8755 29.661C41.4188 31.4903 40.6248 33.2145 39.5379 34.7465C39.1511 35.2916 38.3761 35.3364 37.8875 34.8804C37.4368 34.4599 37.3961 33.7643 37.7458 33.2568C38.5693 32.0617 39.1888 30.7273 39.5691 29.3065C40.2971 26.5862 40.107 23.7017 39.0282 21.1005C38.7425 20.4116 38.3986 19.7527 38.0022 19.1301C37.681 18.6257 37.7784 17.9233 38.2199 17.52C38.7333 17.0509 39.4925 17.1625 39.8723 17.7451C40.8874 19.3021 41.6087 21.0363 41.9962 22.8617C42.4729 25.1079 42.4317 27.4331 41.8755 29.661Z'
      fill='white'
    />
    <path
      d='M37.6744 26C37.6744 31.7797 32.989 36.4651 27.2093 36.4651C21.4296 36.4651 16.7442 31.7797 16.7442 26C16.7442 20.2203 21.4296 15.5349 27.2093 15.5349C32.989 15.5349 37.6744 20.2203 37.6744 26Z'
      fill='white'
    />
  </svg>
);

export default StatusIcon;
