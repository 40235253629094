import React from 'react';

const PlayIcon = ({ size, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.0'
      width={size}
      height={size}
      viewBox='0 0 64.000000 50.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,50.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path d='M212 474 c-189 -94 -189 -354 0 -448 209 -105 437 123 332 332 -63 127 -208 178 -332 116z m136 -174 c34 -22 62 -44 62 -50 0 -12 -119 -90 -137 -90 -10 0 -13 25 -13 90 0 65 3 90 13 90 6 0 40 -18 75 -40z' />
      </g>
    </svg>
  );
};

export default PlayIcon;
