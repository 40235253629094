import React from 'react';
import ChevronIcon from '../icons/chevronIcon';
import styles from './restaurantCard.module.css';

const RestaurantCard = ({ lastElementRef, index, restaurant, onRestaurantClick }) => {
  return (
    <div
      ref={lastElementRef}
      key={index}
      className={`${styles.restaurantCard} mt-2`}
      onClick={() => onRestaurantClick(restaurant.id)}
    >
      <div className='col-auto p-0 ml-3'>
        <span className={styles.containerImg}>
          <img className={styles.imgRestaurant} src={restaurant.logoUrl} alt='logo-restaurant' />
        </span>
      </div>
      <div className='col p-0 ml-3'>
        <div className='bold font-normal text-white'>{restaurant.name}</div>
        <div className='text-secondary font-small'>{restaurant.city.name}</div>
      </div>
      <div className='col-auto pl-0'>
        <ChevronIcon fillColor={'var(--color-primary)'} size={35} />
      </div>
    </div>
  );
};

export default RestaurantCard;
