import React from 'react';

const GroupMessageIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 52 52'
      fill='none'
    >
      <circle cx='26' cy='26' r='26' fill='#1D9BF0' />
      <path
        d='M14.9953 20.3258C14.9953 18.6235 15.6579 16.9908 16.8372 15.787C18.0165 14.5833 19.6159 13.907 21.2837 13.907C22.9515 13.907 24.551 14.5833 25.7303 15.787C26.9096 16.9908 27.5721 18.6235 27.5721 20.3258C27.5721 22.0282 26.9096 23.6609 25.7303 24.8647C24.551 26.0684 22.9515 26.7447 21.2837 26.7447C19.6159 26.7447 18.0165 26.0684 16.8372 24.8647C15.6579 23.6609 14.9953 22.0282 14.9953 20.3258ZM10.2791 38.093C10.2791 33.1535 14.1995 29.1518 19.0386 29.1518H23.5289C28.3679 29.1518 32.2884 33.1535 32.2884 38.093H10.2791ZM33.8604 37.9777V37.5765C33.8604 34.5326 32.5291 31.7995 30.4313 29.9642C30.5492 29.9591 30.6622 29.9541 30.7801 29.9541H33.7966C38.1739 29.9541 41.7209 33.5748 41.7209 38.0429L33.8604 37.9777ZM31.5023 26.7447C29.9793 26.7447 28.6038 26.1128 27.6065 25.0948C28.5743 23.7609 29.1442 22.1111 29.1442 20.3258C29.1442 18.9819 28.8199 17.7132 28.2451 16.5999C29.1589 15.9179 30.2839 15.5117 31.5023 15.5117C34.5433 15.5117 37.0046 18.0241 37.0046 21.1282C37.0046 24.2323 34.5433 26.7447 31.5023 26.7447Z'
        fill='white'
      />
    </svg>
  );
};

export default GroupMessageIcon;
