import { Skeleton } from '@mui/material';
import React from 'react';

export const SkeletonCreateCampaign = () => {
  return (
    <div>
      <div className='mt-3'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '10px', height: 38 }}
        />
      </div>
      <div className='mt-3'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '10px', height: 120 }}
        />
      </div>
    </div>
  );
};

export const SkeletonCreateCampaignMedia = () => {
  return (
    <div className='d-flex'>
      <div>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '182px', borderRadius: '10px', height: 256 }}
        />
      </div>
      <div className=' ml-2'>
        <Skeleton
          variant='rectangular'
          sx={{ bgcolor: 'grey.900', width: '182px', borderRadius: '10px', height: 256 }}
        />
      </div>
    </div>
  );
};
export const SkeletonCampaignFeed = () => {
  return (
    <>
      <div className='mt-3'>
        <div className='d-flex'>
          <div className='d-flex justify-content-center align-items-center'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '50%' }}
              variant='circular'
              width='36px'
              height='36px'
            />
          </div>
          <div className='col ml-0 row flex-column justify-content-center'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'30%'}
            />
            <div className='mt-1'>
              <Skeleton
                sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
                variant='rectangular'
                width={'40%'}
              />
            </div>
          </div>
        </div>
        <div className='mt-1'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '10px', height: '55vh' }}
          />
        </div>
      </div>
      <div className='mt-3'>
        <div className='d-flex'>
          <div className='d-flex justify-content-center align-items-center'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '50%' }}
              variant='circular'
              width='36px'
              height='36px'
            />
          </div>
          <div className='col ml-0 row flex-column justify-content-center'>
            <Skeleton
              sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
              variant='rectangular'
              width={'30%'}
            />
            <div className='mt-1'>
              <Skeleton
                sx={{ bgcolor: 'grey.900', borderRadius: '5px' }}
                variant='rectangular'
                width={'40%'}
              />
            </div>
          </div>
        </div>
        <div className='mt-1'>
          <Skeleton
            variant='rectangular'
            sx={{ bgcolor: 'grey.900', width: '100%', borderRadius: '10px', height: '55vh' }}
          />
        </div>
      </div>
    </>
  );
};
