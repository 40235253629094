import React, { useEffect, useState } from 'react';
import Header from '../../../../components/Common/Header/Header';
import { useHistory } from 'react-router-dom';
import ContentApp from '../../../../components/ContentApp';
import Button from '../../../../components/Common/Button/Button';
import ScanIdScreen from '../ScanScreen/ScanIdScreen';
import { useTranslation } from 'react-i18next';
import FixedButton from '../../../../components/Common/FixedButton/FixedButton';

const FrontValidateKyc = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [frontImage, setFrontImage] = useState(null);

  const handleCaptureFace = (img) => {
    setFrontImage(img);
  };

  const handleConfirmFace = () => {
    if (frontImage) {
      history.push({
        pathname: '/kyc-validate-back',
        state: { frontImage: frontImage },
      });
    }
  };

  const handleReset = () => {
    setFrontImage(null);
  };

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight - window.innerHeight);
  }, []);

  return (
    <>
      <Header hasBackground={true} />
      <ContentApp background={true}>
        <div>
          {frontImage ? (
            <div className='container p-0 text-center'>
              <div className='font-medium bold' style={{ color: '#fff' }}>
                {t('kyc.confirmPicture')}
              </div>
              <div className='m-2 text-secondary'>{t('kyc.confirmText')}</div>
              <div className='pt-5'>
                <img
                  src={frontImage}
                  style={{ height: '250px', width: '100%' }}
                  alt='Captured face'
                />
                <div className='face'></div>
              </div>

              <div className='text-center'>
                <div
                  onClick={handleReset}
                  className='mt-4'
                  style={{ color: 'var(--color-primary)' }}
                >
                  {t('kyc.tryAgain')}
                </div>
                <FixedButton>
                  <Button
                    text={t('confirm')}
                    size='large'
                    onClick={handleConfirmFace}
                    fontSize={'17px'}
                    icon={true}
                    typeIcon='arrow'
                    iconColor='#000'
                    className='btn-primary'
                  />
                </FixedButton>
              </div>
            </div>
          ) : (
            <ScanIdScreen position='front' onCaptureDocument={handleCaptureFace} />
          )}
        </div>
      </ContentApp>
    </>
  );
};

export default FrontValidateKyc;
