import React from 'react';

const CursorIcon = ({ size, styles }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      style={styles}
    >
      <path
        d='M4.91984 4.956L3.4499 3.473M12.7585 4.956L14.2285 3.473M3.4489 14.353L4.91884 12.869M8.83868 2.979V1M2.95892 8.913H1M14.8744 14.836L18.6883 13.33C18.7803 13.2932 18.8592 13.2296 18.9147 13.1475C18.9703 13.0655 19 12.9686 19 12.8695C19 12.7704 18.9703 12.6735 18.9147 12.5915C18.8592 12.5094 18.7803 12.4458 18.6883 12.409L9.52366 8.794C9.43444 8.75947 9.33708 8.7517 9.24352 8.77165C9.14995 8.7916 9.06423 8.8384 8.99685 8.90631C8.92947 8.97423 8.88335 9.06031 8.86414 9.15404C8.84493 9.24776 8.85345 9.34505 8.88868 9.434L12.4705 18.685C12.6325 19.105 13.2205 19.105 13.3825 18.685L14.8744 14.836Z'
        stroke='#D9D9D9'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CursorIcon;
